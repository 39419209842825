import {Injectable} from '@angular/core';
import {ApmService} from "@elastic/apm-rum-angular";
import {ConfigService} from "@app/shared/services/config/config.service";
import {ApmMessage} from "@app/shared/services/apm/apm-message.model";
import {CalculationStoreService} from "@app/calculate/calculate/calculation-store.service";

@Injectable({
  providedIn: 'root'
})
export class CustomApmService {

  constructor(private apmService: ApmService,
              private configService: ConfigService,
              private calculationStoreService: CalculationStoreService) {
  }

  public init() {
    this.apmService.init({
      serviceName: 'calculator-web',
      serverUrl: this.configService.apmRumUrl
    });
  }

  public sendLogVehicleBrand(message: ApmMessage) {
    this.apmService.apm.addLabels({
      'calculation_id': this.calculationStoreService.getCalculationId()
    });

    this.apmService.apm.captureError({
      name: 'Front-End Log',
      message: message
    });
  }

  public sendLogVehicleModel(message: ApmMessage, dictionaryModel: string) {
    this.apmService.apm.addLabels({
      'dictionary_model': dictionaryModel,
      'calculation_id': this.calculationStoreService.getCalculationId()
    });

    this.apmService.apm.captureError({
      name: 'Front-End Log',
      message: message
    });
  }

  public sendLogFormValidations(eventType: string) {
    this.apmService.apm.addLabels({
      'calculation_id': this.calculationStoreService.getCalculationId(),
      'event_type': eventType
    });

    this.apmService.apm.captureError({
      name: 'Front-End Log',
      message: ApmMessage.FORM_ERROR
    });
  }

  public sendLogPaymentUrlIsNull() {
    this.apmService.apm.addLabels({
      'calculation_id': this.calculationStoreService.getCalculationId(),
    });

    this.apmService.apm.captureError({
      name: 'Front-End Log',
      message: ApmMessage.PAYMENT_ERROR
    });
  }

}
