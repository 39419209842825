import {Pipe, PipeTransform} from '@angular/core';
import {CoverModel} from "@app/calculate/calculate/models/insurance-variant.model";
import {GREEN_CARD} from "@app/shared/models/cover/cover.model";

@Pipe({
  name: 'filterGreenCardCover'
})
export class FilterGreenCardCoverPipe implements PipeTransform {

  transform(value?: CoverModel[]): CoverModel[] {
     return value.filter(cover => GREEN_CARD !== cover.code);
  }

}
