import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {CalculationStoreService} from "@app/calculate/calculate/calculation-store.service";
import {GetPartnerConsentResult} from "@app/shared/services/consent/consent-list.model";
import {tap} from "rxjs/operators";
import {ConsentCode} from "@app/shared/services/consent/consent-code.model";
import {Consent} from "@app/shared/services/consent/consent.model";
import {API_URL} from "@app/shared/constants/settings.constant";
import {InputMode} from "@app/landing-page/landing-page.model";
import {firstValueFrom, Observable, of} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ConsentService {
  constructor(private readonly http: HttpClient,
              private storeService: CalculationStoreService,) {
  }

  private urlPrefix = `${API_URL}/partner`;

  getConsents(): Observable<GetPartnerConsentResult> {
    const storedConsents = this.storeService.getConsents();

    if (storedConsents) {
      return of(storedConsents);
    } else {
      return this.http.get<GetPartnerConsentResult>(this.urlPrefix + '/consents').pipe(
        tap(result => this.storeService.storeConsents(result))
      );
    }
  }

  async getConsent(code: ConsentCode): Promise<Consent> {
    const storedConsents = await firstValueFrom(this.getConsents());
    const consentList: Consent[] = storedConsents.calculateConsents.concat(storedConsents.sellConsents);

    return consentList.filter(consent => consent.code === code)[0];
  }

  async getCalculateConsents(): Promise<Consent[]> {
    const storedConsents = await firstValueFrom(this.getConsents());
    return storedConsents?.calculateConsents;
  }

  async getSellConsents(): Promise<Consent[]> {
    const storedConsents = await firstValueFrom(this.getConsents());
    const inputMode = this.storeService.getLandingPage().inputMode;

    return inputMode === InputMode.OMNI ? storedConsents.omniSellConsents : storedConsents.sellConsents;
  }

}
