import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {PHONE_NUMBER_VALIDATION} from '@app/shared/constants/settings.constant';
import {NgModel} from '@angular/forms';
import {PhoneModel} from '@app/shared/components/phone/phone.model';

@Component({
  selector: 'app-phone',
  templateUrl: './phone.component.html',
  styleUrls: ['phone.component.scss']
})
export class PhoneComponent {
  @Input() modelRef: PhoneModel;
  @Input() requiredField: boolean;
  @Input() redStar: boolean;
  @Input() id: string;
  @Output() focusEvent = new EventEmitter<NgModel>();
  @Output() focusoutEvent = new EventEmitter<NgModel>();
  @ViewChild('phoneNumber', { static: true }) phoneNumber: NgModel;

  phoneNumberValidation = PHONE_NUMBER_VALIDATION;

  public onFocus() {
    this.focusEvent.next(this.phoneNumber);
  }

  public onFocusOut() {
    this.focusoutEvent.next(this.phoneNumber);
  }
}
