import { CalculationFormModel } from "@app/calculate/calculate/models/calculation-form-model";
import { CoverModel } from "@app/calculate/calculate/models/insurance-variant.model";

export class ModifyCalculationRequestModel {

  packageName: string;
  formData: CalculationFormModel;
  covers: CoverModel[];

  constructor(packageName: string,
              formData: CalculationFormModel,
              covers: CoverModel[]) {
    this.packageName = packageName;
    this.formData = formData;
    this.covers = covers;
  }

}
