import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {PrivilegeModel} from '@app/shared/services/privilege/privilege.model';
import {API_URL} from '@app/shared/constants/settings.constant';
import {UrlParamsStoreService} from "@app/shared/interceptors/partner/url-params-store.service";

@Injectable({
  providedIn: 'root'
})
export class PrivilegeService {
  privilegesToCheck = [
    '11180017', // Vitay Card Number Privilege ID
  ];
  privileges: Array<PrivilegeModel> = [];

  constructor(private httpClient: HttpClient,
              private urlParamsStoreService: UrlParamsStoreService) {
  }

  init(): Promise<void> {
    const promise: Promise<void> = new Promise((resolve, reject) => {
      this.urlParamsStoreService.urlParamsOnStoreSubject.subscribe(() => this.load().then(function() {
        resolve();
      }));
    });

    return promise;
  }

  load(): Promise<void> {
    const params = new HttpParams({
      fromObject: {'privilegesToCheck': this.privilegesToCheck}
    });

    return this.httpClient.get(`${API_URL}/privileges/`, {params: params}).pipe(
      map((response: Array<PrivilegeModel>) => {
        this.privileges = response;
      })
    ).toPromise();
  }

  hasAccess(privilegeId: number): boolean {
    const privilege: PrivilegeModel = this.privileges.find(p => p.privilegeId === privilegeId);

    return privilege ? privilege.hasAccess : false;
  }

}
