import {Injectable} from "@angular/core";
import * as dayjs from "dayjs";

@Injectable()
export class DateUtilService {

  public stringToUtc(date: string): Date {
    const localTimeZoneDate = dayjs(date).toDate();

    return new Date(Date.UTC(localTimeZoneDate.getFullYear(), localTimeZoneDate.getMonth(), localTimeZoneDate.getDate(), 0, 0, 0));
  }

}
