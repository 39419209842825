import { Injectable } from '@angular/core';
import { StoreService } from '@app/shared/services/store/store.service';
import { RequestStoreKey } from "@app/shared/services/request-store/request-store-key.model";
import { RequestStore } from "@app/shared/services/request-store/request-store.model";
import {Step} from "@app/shared/models/step/step.model";
import {Observable} from "rxjs";

@Injectable()
export class RequestStoreService extends StoreService {

  constructor() {
    super();
  }

  storeRequest(key: RequestStoreKey, requestStore: RequestStore) {
    this.storeItem(key, requestStore);
  }

  getRequest(key: RequestStoreKey):RequestStore {
    return this.getItem(key);
  }

  clear(): void {
    Object.values(RequestStoreKey).forEach(key => this.clearItem(key));
  }

  afterStoreStep(step: Step): Observable<void> {
    return undefined;
  }

}
