import {Pipe, PipeTransform} from '@angular/core';
import {CoverService} from "@app/shared/services/cover/cover.service";
import {CoverModel} from "@app/calculate/calculate/models/insurance-variant.model";

@Pipe({
  name: 'filterDiscountCovers'
})
export class FilterDiscountCoversPipe implements PipeTransform {

  constructor(
    private readonly coverService: CoverService
  ) {}

  transform(value?: CoverModel[]): CoverModel[] {
     return this.coverService.filterDiscounts(value || []);
  }

}
