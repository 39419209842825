import { Directive, Input } from '@angular/core';
import { UntypedFormControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { DictionaryItem } from '@app/shared/services/dictionary/dictionary-item.model';

@Directive({
  selector: '[eligibleOptions]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: EligibleOptionsValidator,
    multi: true
  }]
})
export class EligibleOptionsValidator implements Validator {

  @Input()
  eligibleOptions: string[];

  validate(control: UntypedFormControl): ValidationErrors | null {
    const value = control.value as DictionaryItem;
    if (value === undefined || value === null) return null;

    const isEligible = this.eligibleOptions.includes(value.key);
    return isEligible ? null : { 'notEligibleOption': true };
  }

}
