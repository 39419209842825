import { CallbackTagModel } from "@app/shared/services/gtm/tag/callback-tag.model";
import { CallbackCategory } from "@app/shared/services/gtm/model/callback-category.model";
import { CallbackAction } from "@app/shared/services/gtm/model/callback-action.model";

export class CallbackTagBuilder {

  private readonly tag: CallbackTagModel;

  constructor() {
    this.tag = new CallbackTagModel();
    this.tag.event = 'callbackEvent';
  }

  public static builder(): CallbackTagBuilder {
    return new CallbackTagBuilder();
  }

  public calculationID(calculationID: string): CallbackTagBuilder {
    this.tag.calculationID = calculationID;
    return this;
  }

  public partnerID(partnerID: string): CallbackTagBuilder {
    this.tag.partnerID = partnerID ? partnerID : '695';
    return this;
  }

  public category(callbackCategory: CallbackCategory): CallbackTagBuilder {
    this.tag.category = callbackCategory;
    return this;
  }

  public action(callbackAction: CallbackAction): CallbackTagBuilder {
    this.tag.action = callbackAction;
    return this;
  }

  public label(label: string): CallbackTagBuilder {
    this.tag.label = label;
    return this;
  }

  public value(value: number): CallbackTagBuilder {
    this.tag.value = value;
    return this;
  }

  public nointeract(nointeract: number): CallbackTagBuilder {
    this.tag.nointeract = nointeract;
    return this;
  }

  public build(): CallbackTagModel {
    return this.tag;
  }


}
