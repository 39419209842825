import { Injectable } from '@angular/core';
import {MAX_OC_VEHICLE_AGE, MAX_USER_AGE} from "@app/shared/constants/settings.constant";

@Injectable()
export class RangeUtil {

  public getVehicleProductionYears(): number[] {
    const currentYear = new Date().getFullYear();
    return this.getRange(currentYear - MAX_OC_VEHICLE_AGE, currentYear);
  }

  public getStdYears(): number[] {
    const currentYear = new Date().getFullYear();
    return this.getRange(currentYear - MAX_USER_AGE, currentYear);
  }

  private getRange(from: number, to: number): number[] {
    const yearList = [...Array((to - from) + 1).keys()].map(v => from + v);
    return yearList.reverse();
  }

}
