import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FeatureFlagService} from "@app/shared/services/feature-flag/feature-flag.service";
import {MatDrawer} from "@angular/material/sidenav";
import {Flags} from "@app/shared/models/flag/flag.model";
import {WidgetsModel} from "@app/shared/components/widgets/widgets.model";
import {MatDialog, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {VideoComponent} from "@app/shared/components/video/video.component";
import {CallbackFormComponent} from "@app/shared/components/callback-form/callback-form.component";

@Component({
  selector: 'app-widgets',
  templateUrl: './widgets.component.html',
  styleUrls: ['widgets.component.scss']
})
export class WidgetsComponent implements OnInit{

  @Input() slideNavEnd: MatDrawer;
  @Output() widgetClick: EventEmitter<WidgetsModel> = new EventEmitter<WidgetsModel>();

  FLAG_2022_WIDGET_LIVECHAT_ALFAVOX_CALLBACK: boolean;
  FLAG_2039_VIDEO: boolean;
  WIDGETS = WidgetsModel;
  dialogRef: MatDialogRef<any>;

  constructor(private featureFlagService: FeatureFlagService,
              private dialog: MatDialog,) {
  }

  ngOnInit() {
    this.FLAG_2022_WIDGET_LIVECHAT_ALFAVOX_CALLBACK = this.featureFlagService.isActive(Flags.FLAG_2022_WIDGET_LIVECHAT_ALFAVOX_CALLBACK);
    this.FLAG_2039_VIDEO = this.featureFlagService.isActive(Flags.FLAG_2039_VIDEO);
  }

  openSlideNav(widgetName: WidgetsModel) {
    this.widgetClick.emit(widgetName);
    this.slideNavEnd.toggle();
  }

  openMobileVideoDialog($event) {
    $event.stopPropagation();
    this.dialog.closeAll();

    this.dialogRef = this.dialog.open(VideoComponent, {
      panelClass: ['animate__animated', 'animate__slideInRight', 'video-mobile-dialog'],
      position: {
        bottom: '120px',
        right: '100px'
      },
      hasBackdrop: false,
      autoFocus: false,
    });
  }

  openMobileCallbackDialog($event) {
    $event.stopPropagation();
    this.dialog.closeAll();

    this.dialogRef = this.dialog.open(CallbackFormComponent, {
      panelClass: ['animate__animated', 'animate__slideInRight', 'callback-mobile-dialog'],
      position: {
        bottom: '120px',
        right: '100px'
      },
      hasBackdrop: false,
      autoFocus: false,
    });
  }

  onCloseMatMenu() {
    this.dialogRef.close();
  }

}
