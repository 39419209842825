import {Injectable} from '@angular/core';
import {DictionaryItem} from '@app/shared/services/dictionary/dictionary-item.model';
import {PersonalDataStoreModel} from '@app/shared/services/personal-data/personal-data-store.model';

@Injectable()
export class ComparatorService {

  constructor() {
  }

  compareByDict(dictA: DictionaryItem, dictB: DictionaryItem): boolean {
    if (dictA === undefined || dictA === null || dictB === undefined || dictB === null) { return false; }
    return dictA.key === dictB.key;
  }

  compareByValue(valueA: any, valueB: any): boolean {
    return valueA === valueB;
  }

  compareByVehicleOwnerDetails(personalDataA: PersonalDataStoreModel,
                               personalDataB: PersonalDataStoreModel): boolean {
    return personalDataA.uid === personalDataB.uid;
  }

}
