import { VirtualPageViewTagModel } from "@app/shared/services/gtm/tag/virtual-page-view-tag.model";
import { LandingPageModel } from "@app/landing-page/landing-page.model";
import { ECommFunnelTypeMapper } from "@app/shared/services/gtm/mapper/ecomm-funnel-type.mapper";
import { NavigationEnd } from "@angular/router";
import { ECommPageTypeMapper } from "@app/shared/services/gtm/mapper/ecomm-page-type.mapper";
import { PageTitleMapper } from "@app/shared/services/gtm/mapper/page-title.mapper";
import { VehicleModel } from "@app/calculate/vehicle/vehicle.model";
import { FuelTypeMapper } from "@app/shared/services/gtm/mapper/fuel-type.mapper";
import { VehicleAdditionalInfoModel } from "@app/calculate/vehicle-additional-info/vehicle-additional-info.model";
import { VehicleOwnerModel } from "@app/calculate/vehicle-owner/vehicle-owner.model";
import { InsuranceStartDateModel } from "@app/calculate/insurance-start-date/insurance-start-date.model";
import * as dayjs from 'dayjs';
import { ConsentMapper } from "@app/shared/services/gtm/mapper/consent.mapper";
import { DATE_FORMAT } from "@app/shared/constants/settings.constant";
import { ContactAndConsentModel } from "@app/offer/contact-and-consent/contact-and-consent.model";
import { StepOrderResolver } from "@app/shared/services/gtm/mapper/step-order.resolver";
import { CalculationStoreService } from "@app/calculate/calculate/calculation-store.service";
import {PersonalDataStoreModel} from "@app/shared/services/personal-data/personal-data-store.model";

export class VirtualPageViewTagBuilder {

  private readonly tag: VirtualPageViewTagModel;

  private constructor() {
    this.tag = new VirtualPageViewTagModel();
    this.tag.event = 'virtualPageView';
  }

  public static builder(): VirtualPageViewTagBuilder {
    return new VirtualPageViewTagBuilder();
  }

  public page(location: Location): VirtualPageViewTagBuilder {
    if (location) {
      const pathName = location.pathname;
      const pathWithParams = location.hash;
      const pathWithoutParams = pathWithParams.split('?')[0];

      this.tag.virtualPageURL = location.href;
      this.tag.virtualPagePath = `${pathName}${pathWithoutParams}`;
      this.tag.virtualPageFullPath = `${pathName}${pathWithParams}`;
    }

    return this;
  }

  public eCommPageType(navigation: NavigationEnd): VirtualPageViewTagBuilder {
    if (navigation) {
      this.tag.ecommPageType = ECommPageTypeMapper.map(navigation);
    }

    return this;
  }

  public eCommFunnelType(landingPage: LandingPageModel): VirtualPageViewTagBuilder {
    const inputMode = landingPage ? landingPage.inputMode : undefined;
    this.tag.ecommFunnelType = ECommFunnelTypeMapper.map(inputMode);
    return this;
  }

  public calculationID(calculationID: string): VirtualPageViewTagBuilder {
    if (calculationID) {
      this.tag.calculationID = calculationID;
    }
    return this;
  }

  public partnerID(partnerID: string): VirtualPageViewTagBuilder {
    this.tag.partnerID = partnerID ? partnerID : '695';
    return this;
  }

  public stepDisplayNo(stepDisplayNo: number): VirtualPageViewTagBuilder {
    if (stepDisplayNo) {
      this.tag.stepDisplayNo = stepDisplayNo.toString();
    }

    return this;
  }

  public stepOrder(navigation: NavigationEnd, calculationStoreService: CalculationStoreService): VirtualPageViewTagBuilder {
    const stepOrder = StepOrderResolver.resolveFromNavigation(navigation, calculationStoreService);
    this.tag.stepOrder = stepOrder?.toString();
    return this;
  }

  public pageTitle(navigation: NavigationEnd): VirtualPageViewTagBuilder {
    if (navigation) {
      this.tag.pageTitle = PageTitleMapper.map(navigation);
    }

    return this;
  }

  public vehicle(vehicle: VehicleModel): VirtualPageViewTagBuilder {
    if (vehicle) {
      this.tag.carYear = vehicle.productionYear.toString();
      this.tag.carBrand = vehicle.brand.translation;
      this.tag.carModel = vehicle.model.name;
      this.tag.carBody = vehicle.version?.bodyType;
      this.tag.carEngineVol = vehicle.version?.cc ? vehicle.version.cc.toString() : undefined;
      this.tag.carFuel = FuelTypeMapper.map(vehicle.version?.fuelType);
      this.tag.insuranceFunnelType = vehicle.insuranceType ? vehicle.insuranceType.toString() : undefined;
    }

    return this;
  }

  public vehicleAdditionalInfo(vehicleAdditionalInfo: VehicleAdditionalInfoModel): VirtualPageViewTagBuilder {
    if (vehicleAdditionalInfo) {
      this.tag.wheelRightSide = vehicleAdditionalInfo.steeringWheelOnRightSide ? '1' : '0';
      this.tag.naturalGas = vehicleAdditionalInfo.gasInstallation ? '1' : '0';
      this.tag.usage = vehicleAdditionalInfo.usage.translation;
      this.tag.insurancePolandYear = vehicleAdditionalInfo.sinceWhenVehicleInsuredInPoland ? vehicleAdditionalInfo.sinceWhenVehicleInsuredInPoland.translation : undefined;
      this.tag.insuranceOwnerYear = vehicleAdditionalInfo.sinceWhenCurrentOwnerInsuresVehicle ? vehicleAdditionalInfo.sinceWhenCurrentOwnerInsuresVehicle.toString() : undefined;
      this.tag.mileage = vehicleAdditionalInfo.currentMileage?.toString();
    }

    return this;
  }

  public vehicleOwner(vehicleOwner: VehicleOwnerModel): VirtualPageViewTagBuilder {
    if (vehicleOwner) {
      this.tag.ownerNumber = vehicleOwner.ownersCount.toString();
      this.tag.under24 = (vehicleOwner.youngOwner ? '1' : '0');
      this.tag.mainUser = vehicleOwner.mainUser.toString();
      this.tag.under24Age = vehicleOwner.youngestOwnerAge?.translation;
    }

    return this;
  }

  public basicData(personalData: PersonalDataStoreModel): VirtualPageViewTagBuilder {
    if (personalData) {
      this.tag.ownerFirstName = personalData.firstName ? 'filled' : 'empty';
      this.tag.ownerLastName = personalData.lastName ? 'filled' : 'empty';
      this.tag.ownerPhoneNumber = personalData.phoneNumber ? 'filled' : 'empty';
    }

    return this;
  }

  public vehicleMainUserDetails(personalData: PersonalDataStoreModel): VirtualPageViewTagBuilder {
    if (personalData) {
      this.tag.ownerFirstName = personalData.firstName ? 'filled' : 'empty';
      this.tag.ownerLastName = personalData.lastName ? 'filled' : 'empty';
      this.tag.ownerEmail = personalData.email ? 'filled' : 'empty';
      this.tag.ownerPhoneNumber = personalData.phoneNumber ? 'filled' : 'empty';
      this.tag.ownerYear = dayjs(personalData.birthDate).format(DATE_FORMAT);
      this.tag.ownerGender = personalData.gender ? personalData.gender.toString() : undefined;
    }

    return this;
  }

  public vehicleOwnerDetails(personalData: PersonalDataStoreModel): VirtualPageViewTagBuilder {
    this.fillVehicleOwnerDetails(personalData, '');
    return this;
  }

  public vehicleFirstCoOwnerDetails(personalData: PersonalDataStoreModel): VirtualPageViewTagBuilder {
    this.fillVehicleOwnerDetails(personalData, 'firstCoOwner');
    return this;
  }

  public vehicleSecondCoOwnerDetails(personalData: PersonalDataStoreModel): VirtualPageViewTagBuilder {
    this.fillVehicleOwnerDetails(personalData, 'secondCoOwner');
    return this;
  }

  public insuranceStartDate(insuranceStartDate: InsuranceStartDateModel): VirtualPageViewTagBuilder {
    if (insuranceStartDate) {
      const currentDate = dayjs().startOf('day');
      const startDate = dayjs(insuranceStartDate.startDate).startOf('day');

      this.tag.insuranceStart = startDate.format(DATE_FORMAT);
      this.tag.insuranceStartDiff = startDate.diff(currentDate, 'days').toString();
      this.tag.coupon = insuranceStartDate.promotionalCode ? 'filled' : 'empty';
      this.tag.link4consents = ConsentMapper.map(insuranceStartDate.selectedConsents || []);
    }

    return this;
  }

  public contactAndConsent(contactAndConsent: ContactAndConsentModel): VirtualPageViewTagBuilder {
    if (contactAndConsent) {
      this.tag.link4consents2 = ConsentMapper.map(contactAndConsent.consents || []);
    }

    return this;
  }

  private fillVehicleOwnerDetails(personalData: PersonalDataStoreModel, ownerType: string) {
    if (personalData) {
      const isOwner = ownerType === '';

      if (isOwner && personalData.driverLicenseObtainYear) {
        this.tag.drivingLicenceYear = personalData.driverLicenseObtainYear;
      }

      this.tag[ownerType + 'OCYears'] = personalData.damageHistory && personalData.damageHistory.ocInsuranceYears ? personalData.damageHistory.ocInsuranceYears.translation : undefined;
      this.tag[ownerType + 'ACYears'] = personalData.damageHistory && personalData.damageHistory.acInsuranceYears ? personalData.damageHistory.acInsuranceYears.translation : undefined;
      this.tag[ownerType + 'OC6YearsDamages'] = personalData.damageHistory && personalData.damageHistory.ocDamagesInLastSixYears ? personalData.damageHistory.ocDamagesInLastSixYears.translation : undefined;
      this.tag[ownerType + 'AC6YearsDamages'] = personalData.damageHistory && personalData.damageHistory.acDamagesInLastSixYears ? personalData.damageHistory.acDamagesInLastSixYears.translation : undefined;
      this.tag[ownerType + 'OC3YearsDamages'] = personalData.damageHistory && personalData.damageHistory.ocDamagesInLastThreeYears ? personalData.damageHistory.ocDamagesInLastThreeYears.translation : undefined;
      this.tag[ownerType + 'AC3YearsDamages'] = personalData.damageHistory && personalData.damageHistory.acDamagesInLastThreeYears ? personalData.damageHistory.acDamagesInLastThreeYears.translation : undefined;
      this.tag[ownerType + 'OC1YearsDamages'] = personalData.damageHistory && personalData.damageHistory.ocDamagesInLastYear ? personalData.damageHistory.ocDamagesInLastYear.translation : undefined;
      this.tag[ownerType + 'AC1YearsDamages'] = personalData.damageHistory && personalData.damageHistory.acDamagesInLastYear ? personalData.damageHistory.acDamagesInLastYear.translation : undefined;
    }
  }

  public build(): VirtualPageViewTagModel {
    return this.tag;
  }
}
