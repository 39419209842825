import {Inject, Injectable} from '@angular/core';
import {FeatureFlagService} from "@app/shared/services/feature-flag/feature-flag.service";
import {DOCUMENT} from "@angular/common";
import {Flags} from "@app/shared/models/flag/flag.model";

@Injectable({
  providedIn: 'root'
})
export class OneTrustService {
  constructor(private featureFlagService: FeatureFlagService,
              @Inject(DOCUMENT) private document: Document) {
  }

  loadJsScript(src: string, domainScript: string) {
    const head = window.document.getElementsByTagName('head')[0];
    const oneTrustScript = window.document.createElement('script');
          oneTrustScript.id = 'one-trust-script';
          oneTrustScript.src = src;
          oneTrustScript.setAttribute('data-document-language', 'true');
          oneTrustScript.setAttribute('type', 'text/javascript');
          oneTrustScript.setAttribute('charset', 'UTF-8');
          oneTrustScript.setAttribute('data-domain-script', domainScript);
    const oneTrustFuncScript = window.document.createElement('script');
          oneTrustFuncScript.type = 'text/javascript';
          oneTrustFuncScript.innerHTML = `function OptanonWrapper() { }`;

    head.insertBefore(oneTrustScript, head.firstChild);
    head.appendChild(oneTrustFuncScript);
    this.addFuncToHead(this.addGetCookie());
    this.addFuncToHead(this.addReloadOTBanner());
    this.addFuncToHead(this.addClearDup());

    return oneTrustScript;
  }

  async init() {
    if (this.featureFlagService.isActive(Flags.FLAG_1774_ONETRUST)) {
      return this.loadJsScript('https://cdn.cookielaw.org/scripttemplates/otSDKStub.js', 'ac63a439-3e6f-4243-bfea-db68d9ebc6b6');
    }
  }

  addFuncToHead(func: string) {
    const head = window.document.getElementsByTagName('head')[0];
    const oneTrustFuncScript = window.document.createElement('script');
          oneTrustFuncScript.type = 'text/javascript';
          oneTrustFuncScript.innerHTML = func;

    head.appendChild(oneTrustFuncScript);
  }

  addGetCookie() {
    return 'function getCookie(name) {' +
           '        var value = "; " + document.cookie;' +
           '        var parts = value.split("; " + name + "=");' +
           '        if (parts.length == 2) {' +
           '            return true;' +
           '        }' +
           '    }';
  }

  addReloadOTBanner() {
    return 'function reloadOTBanner() {' +
           '        var otConsentSdk = document.getElementById("onetrust-consent-sdk");' +
           '        if (otConsentSdk) {' +
           '            otConsentSdk.remove();' +
           '        }' +
           '        if (window.OneTrust != null) {' +
           '            OneTrust.Init();' +
           '            setTimeout(function() {' +
           '                OneTrust.LoadBanner();' +
           '                var toggleDisplay = document.getElementsByClassName(' +
           '                    "ot-sdk-show-settings"' +
           '                );' +
           '                for (var i = 0; i < toggleDisplay.length; i++) {' +
           '                    toggleDisplay[i].onclick = function(event) {' +
           '                        event.stopImmediatePropagation();' +
           '                        window.OneTrust.ToggleInfoDisplay();' +
           '                    };' +
           '                }' +
           '            }, 1000);' +
           '        }' +
           '    }';
  }

  addClearDup() {
    return 'function clearDup() {' +
           '        var sec = document.getElementById("ot-sdk-cookie-policy");' +
           '        var tally = [];' +
           '        for (var i = sec.length - 1; i >= 0; i--) {' +
           '            if (tally[sec[i].firstChild.innerText] === undefined) {' +
           '                tally[sec[i].firstChild.innerText] = 1;' +
           '            } else {' +
           '                sec[i].remove();' +
           '            }' +
           '        }' +
           '    }';
  }

}
