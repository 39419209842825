import {Injectable} from '@angular/core';
import {Step} from '@app/shared/models/step/step.model';
import {StoreService} from '@app/shared/services/store/store.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {Params} from "@angular/router";

enum UrlStoreKey {
  PARTNER_ID = 'partner_id',
  ORLEN_UID = 'y',
  SOURCE_ID = 'source_id',
  INIT_SHORT_PATH_SID = 'sid',
  OMNI_ID = 'oid',
  OMNI_TRAFFIC_SOURCE_ID = 'traffic_id',
}

@Injectable()
export class UrlParamsStoreService extends StoreService {
  urlParamsOnStoreSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor() {
    super();
  }

  getPartnerId(): string {
    return this.getItem(UrlStoreKey.PARTNER_ID);
  }

  storePartnerId(id: string) {
    this.storeItem(UrlStoreKey.PARTNER_ID, id);
  }

  getOmniTrafficSourceId(): string {
    return this.getItem(UrlStoreKey.OMNI_TRAFFIC_SOURCE_ID);
  }

  getSourceId(): string {
    return this.getItem(UrlStoreKey.SOURCE_ID);
  }

  getOrlenUID(): string {
    return this.getItem(UrlStoreKey.ORLEN_UID);
  }

  getInitShortPathSID(): string {
    return this.getItem(UrlStoreKey.INIT_SHORT_PATH_SID);
  }

  getOmniId(): string {
    return this.getItem(UrlStoreKey.OMNI_ID);
  }

  afterStoreStep(step: Step): Observable<void> {
    return undefined;
  }

  clear(): void {
    Object.values(UrlStoreKey).forEach(key => this.clearItem(key));
  }

  store(params: Params) {
    Object.values(UrlStoreKey).filter(value => {
      return params.hasOwnProperty(value)
    }).forEach(value => {
      this.storeItem(value, params[value]);
    });

    if (this.getPartnerId()) {
      this.urlParamsOnStoreSubject.next(null);
    }
  }

  asParams(): Params {
    let params: Params = {};
    Object.values(UrlStoreKey).forEach(key => {
      if (this.getItem(key)) {
        params[key] = this.getItem(key);
      }
    });
    return params;
  }
}
