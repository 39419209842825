import {Injectable} from '@angular/core';
import * as dayjs from "dayjs";

@Injectable({
  providedIn: 'root'
})
export class CalendarService {

  public isBusinessDay(date: Date): boolean {
    const workingWeekdays = [1, 2, 3, 4, 5];
    return workingWeekdays.includes(dayjs(date).day()) ? true : false;
  }

  public isHoliday(date: Date): boolean {
    const currentDate = dayjs(date);
    const currentDay = currentDate.date();
    const currentMonth = currentDate.month();
    const currentYear = currentDate.year();
    const ester = dayjs(this.calculateEasterDate(currentYear));
    const corpusChristi = ester.add(60, 'day');

    if (currentMonth === 0 && currentDay === 1) return true; // Nowy Rok
    if (currentMonth === 0 && currentDay === 6) return true; // Święto Trzech Króli
    if (currentMonth === ester.month() && currentDay === ester.date() + 1) return true; // Wielkanoc (poniedziałek)
    if (currentMonth === 4 && currentDay === 1) return true; // 1 maja Święto Pracy
    if (currentMonth === 4 && currentDay === 3) return true; // Święto Konstytucji 3 Maja
    if (currentMonth === corpusChristi.month() && currentDay === corpusChristi.date()) return true; // Boże Ciało
    if (currentMonth === 7 && currentDay === 15) return true; // Wniebowzięcie Najświętszej Marii Panny
    if (currentMonth === 10 && currentDay === 1) return true; // Dzień Wszystkich Świętych
    if (currentMonth === 10 && currentDay === 11) return true; // Dzień Niepodległości
    if (currentMonth === 11 && currentDay === 25) return true; // Boże Narodzenie
    if (currentMonth === 11 && currentDay === 26) return true; // Boże Narodzenie

    return false;
  }

  private calculateEasterDate(year: number): Date {
    let a, b, c, d, e, f, g, h, i, k, l, m, p;

    a = year % 19;
    b = Math.floor (year / 100);
    c = year % 100;
    d = Math.floor (b / 4);
    e = b % 4;
    f = Math.floor  ((b + 8) / 25);
    g = Math.floor  ((b - f + 1) / 3);
    h = (19 * a + b - d - g + 15) % 30;
    i = Math.floor (c / 4);
    k = c % 4;
    l = (32 + 2 * e + 2 * i - h - k) % 7;
    m = Math.floor  ((a + 11 * h + 22 * l) / 451);
    p = (h + l - 7 * m + 114) % 31;

    let day = p + 1;
    let month = Math.floor ((h + l - 7 * m + 114) / 31);

    return new Date(year, month - 1, day);
  }

}
