import {Component, OnInit} from '@angular/core';
import {StepResolver} from "@app/shared/services/url/step-resolver";
import {Router} from "@angular/router";
import {FeatureFlagService} from "@app/shared/services/feature-flag/feature-flag.service";
import {Flags} from "@app/shared/models/flag/flag.model";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit{

  FLAG_2022_WIDGET_LIVECHAT_ALFAVOX_CALLBACK: boolean;

  constructor(private router: Router,
              private featureFlagService: FeatureFlagService) {
  }

  ngOnInit() {
    this.FLAG_2022_WIDGET_LIVECHAT_ALFAVOX_CALLBACK = this.featureFlagService.isActive(Flags.FLAG_2022_WIDGET_LIVECHAT_ALFAVOX_CALLBACK);
  }

  isLandingPage() {
    return StepResolver.isLandingPage(this.router.url);
  }

}

