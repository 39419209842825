<div class="help-page">
    <mat-dialog-content class="container">
        <div class="col-md-12 m-t-30">
            <div *ngIf="data.title">
                <div class="w-100 text text-center text-big" [innerHTML]="data.title"></div>
            </div>
            <div class="w-100 text-center text text-medium m-b-30"
                 [innerHTML]="data.message">
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions class="justify-content-center m-0">
        <div class="help-next-button">
            <button class="next-button" (click)="close()" mat-button cdkFocusInitial>
              {{isSystemError() ? 'Zamknij' : 'OK' }}
            </button>
        </div>
    </mat-dialog-actions>
</div>
