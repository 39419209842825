import { Inject, Injectable, Renderer2 } from '@angular/core';
import { DOCUMENT } from "@angular/common";

@Injectable()
export class BodyStylerService {

  constructor(@Inject(DOCUMENT) private document: any) {}

  public setBodyClass(className: string, renderer: Renderer2) {
    this.removeBodyClass(renderer);
    renderer.setAttribute(document.body, 'class', className);
  }

  public removeBodyClass(renderer: Renderer2) {
    renderer.setAttribute(document.body, 'class', '');
  }

  public requestFullscreen() {
    const documentElement = this.document.documentElement;
    (documentElement.requestFullscreen ||
      documentElement.webkitRequestFullScreen ||
      documentElement.mozRequestFullScreen ||
      documentElement.msRequestFullscreen ||
      (() => {})).call(documentElement);
  }

}
