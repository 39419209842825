import {Component, Inject, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import InfoDialogOptions from '@app/shared/components/info-dialog/info-dialog.options';
import {ErrorType, SellBlacklistErrorCode} from "@app/shared/interceptors/server-error/error-info.model";
import {Router} from "@angular/router";

@Component({
  selector: 'app-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['../../../../scss/shared/dialog.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InfoDialogComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: InfoDialogOptions,
              private dialogRef: MatDialogRef<any>,
              private router: Router) {
  }

  isSystemError() {
    return this.data.type && this.data.type == ErrorType.SYSTEM;
  }

  close(): void {
    this.dialogRef.close();

    if (this.data.errorCode === SellBlacklistErrorCode.SELL_BLACKLIST_BLACKLISTED) {
      this.router.navigate(['']);
    }
  }
}
