import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {CallbackModel} from '@app/shared/components/callback/callback.model';
import {CalculationStoreService} from "@app/calculate/calculate/calculation-store.service";
import {CallbackBusinessType} from "@app/shared/components/callback/callback-business-type.model";
import {ErrorCode} from "@app/shared/interceptors/server-error/error-info.model";

@Injectable()
export class CallbackService {

  constructor(private httpClient: HttpClient,
              private storeService: CalculationStoreService) {
  }

  createCallback(callback: CallbackModel): Observable<void> {
    callback.calculationId = this.storeService.getCalculationId();
    return this.httpClient.post<void>(`api/callback`, callback);
  }

  getBusinessType(data: any): CallbackBusinessType {
    const renewalCodes = [
      ErrorCode.VEHICLE_POLICY_EXIST,
      ErrorCode.VEHICLE_PLATE_NUMBER_EXIST,
      ErrorCode.VEHICLE_VIN_EXIST
    ];
    const hasRenewalCode = data?.errorCode && renewalCodes.includes(data.errorCode);

    return hasRenewalCode ? CallbackBusinessType.RENEWAL : CallbackBusinessType.NEW_BUSINESS;
  }

}
