import {Directive, ElementRef, HostListener} from '@angular/core';
import {UntypedFormGroup, NgForm, ValidationErrors} from "@angular/forms";
import {GtmService} from "@app/shared/services/gtm/gtm.service";
import {GTM_FIELDS_NAME} from "@app/shared/directives/gtm-form-event/gtm-field.model";

@Directive({
    selector: 'form'
})
export class GtmSubmitDirective {

  constructor(private ngForm: NgForm,
              private el: ElementRef,
              private gtmService: GtmService,) {
  }

  @HostListener('submit', ['$event'])
  onSubmit(event) {
    const fieldNameWithErrors = this.getFieldWithErrors(this.ngForm.form);

    if (fieldNameWithErrors) {
      this.gtmService.emitFormFieldError(this.getGtmFieldName(fieldNameWithErrors));
    } else {
      this.emitFormSuccessClick();
    }
  }

  getFieldWithErrors(form: UntypedFormGroup): string {
    const elFirstInvalid = this.el.nativeElement.querySelector("input.ng-invalid, mat-select.ng-invalid, mat-checkbox.ng-invalid");
    const vehicleSelectorComponent = this.el.nativeElement.querySelector(".mat-form-field-invalid .mat-chip-level-label")

    if (vehicleSelectorComponent) {
      return vehicleSelectorComponent.innerHTML.toLowerCase().replace(' ', '-');
    }

    return elFirstInvalid?.getAttribute('name') || elFirstInvalid?.getAttribute('id');
  }

  getGtmFieldName(controlName: string): string {
    const name = GTM_FIELDS_NAME[controlName];
    return name ? name : controlName;
  }

  emitFormSuccessClick() {
    const formName = this.el.nativeElement.getAttribute('name');

    switch(formName) {
      case 'contactAndConsentForm': {
        this.gtmService.emitFormFieldClick('klik-kup-polise');
        break;
      }
      case 'thankYouForm': {
        this.gtmService.emitFormFieldClick('klik-przejdz-do-platnosci');
        break;
      }
    }
  }

}
