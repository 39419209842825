import {Consent} from "@app/shared/services/consent/consent.model";

export class LandingPageModel {
  inputMode: InputMode;
  consent: Consent;
  licensePlate: string;
}

export enum InputMode {
  DECLARATION = 'DECLARATION',
  CEPIK = 'CEPIK',
  OMNI = 'OMNI',
  UFG = 'UFG',
}
