import {Injectable} from '@angular/core';
import {
  CepikErrorCode,
  CoverErrorCode,
  ErrorInfo,
  SellBlacklistErrorCode
} from "@app/shared/interceptors/server-error/error-info.model";

@Injectable({
  providedIn: 'root'
})
export class ExceptionMessageReplaceService {

  private readonly MESSAGE_REPLACE: Record<string, string> = {
    [SellBlacklistErrorCode.SELL_BLACKLIST_BLACKLISTED]: "Niestety nie udało się potwierdzić zakupu polisy za pomocą autoryzacji SMS. Polisa nie została zawarta. Skontaktuj się z naszą infolinią pod numerem 22 444 44 44",
    [SellBlacklistErrorCode.REGISTER_BLACKLISTED]: "Niestety nie udało się potwierdzić zakupu polisy za pomocą autoryzacji SMS. Polisa nie została zawarta. Skontaktuj się z naszą infolinią pod numerem 22 444 44 44",
    [SellBlacklistErrorCode.ACCEPT_BLACKLISTED]: "Niestety nie udało się potwierdzić zakupu polisy za pomocą autoryzacji SMS. Polisa nie została zawarta. Skontaktuj się z naszą infolinią pod numerem 22 444 44 44",
    [CoverErrorCode.COVER_UNAVAILABLE]: "Przy podanych parametrach nie jest możliwy zakup polisy online. Aby zakończyć proces akceptacji polisy konieczny jest kontakt z infolinią LINK4.",
    [CepikErrorCode.CEPIK_SESSION_EXPIRED]: "Twoja sesja wygasła"
  }

  getMessageForCode(code: string): string | undefined {
    return this.MESSAGE_REPLACE[code];
  }

  getMessage(errorInfo: ErrorInfo): string {
    return this.getMessageForCode(errorInfo.code) ?? errorInfo.message;
  }
}
