<div *ngIf="livechatFlag">
    <livechat-widget
        #liveChatWidget
        license="{{ license }}"
        visibility="{{ visibility }}"
        (onVisibilityChanged)="onVisibilityChanged($event)"
        (onAvailabilityChanged)="onAvailabilityChanged($event)"
        (onReady)="onReady($event)">
    </livechat-widget>

    <button *ngIf="showLivechat && type === 'header'"
            class="livechat-button-desktop"
            type="button"
            (click)="open()">
        <mat-icon svgIcon="chat"></mat-icon>
        Chat
    </button>
    <button *ngIf="showLivechat && type === 'header'"
            class="livechat-button-mobile"
            type="button"
            (click)="open()">
        <mat-icon svgIcon="chat"></mat-icon>
    </button>
    <button *ngIf="showLivechat && type === 'widget-desktop'"
            class="livechat-button-desktop-widget"
            type="button"
            (click)="open()">
        <mat-icon svgIcon="widget-chat"></mat-icon><br />
        Chat
    </button>
    <button *ngIf="showLivechat && type === 'widget-mobile'"
            mat-menu-item
            type="button"
            (click)="open()">
        <mat-icon svgIcon="widget-chat"></mat-icon>
    </button>
</div>
