import {ChangeDetectorRef, Component, Inject, Input, OnInit, ViewChild} from '@angular/core';
import {FeatureFlagService} from "@app/shared/services/feature-flag/feature-flag.service";
import {LiveChatWidgetComponent} from "@livechat/widget-angular";
import {Flags} from "@app/shared/models/flag/flag.model";
import {
  LIVECHAT_AVAILABILITY,
  LIVECHAT_STATUS,
  LIVECHAT_VISIBILITY
} from "@app/shared/components/livechat/livechat.model";
import {DOCUMENT} from "@angular/common";

@Component({
  selector: 'app-livechat',
  templateUrl: './livechat.component.html',
  styleUrls: ['livechat.component.scss']
})
export class LiveChatComponent implements OnInit{

  @Input() type: string;
  @ViewChild('liveChatWidget', {static: false}) public liveChatWidget: LiveChatWidgetComponent;
  livechatFlag: boolean;
  showLivechat: boolean = false;
  visibility: LIVECHAT_VISIBILITY = LIVECHAT_VISIBILITY.HIDDEN;
  license: number = 14848062;

  constructor(private featureFlagService: FeatureFlagService,
              private ref: ChangeDetectorRef,
              @Inject(DOCUMENT) document: Document) {
  }

  ngOnInit() {
    this.livechatFlag = this.featureFlagService.isActive(Flags.FLAG_1749_LIVECHAT);
  }

  open() {
    this.liveChatWidget.widget.updateVisibility(LIVECHAT_VISIBILITY.MAXIMIZED);
  }

  onReady(event) {
    const date = new Date();
    const timezone = 'Europe/Warsaw';
    const cbday = date.toLocaleDateString('pl-PL', { weekday: "long", timeZone: timezone });
    const cbhour = date.toLocaleString('pl-PL', { hour: 'numeric', timeZone: timezone });

    if (event.customerData.status === LIVECHAT_STATUS.CHATTING) {
      this.liveChatWidget.widget.updateVisibility(LIVECHAT_VISIBILITY.MAXIMIZED);
    }

    this.hideNotSupportIcons(LIVECHAT_VISIBILITY.MINIMIZED);
    this.showLivechat = event.state.availability === LIVECHAT_AVAILABILITY.ONLINE;
    this.liveChatWidget.widget.updateSessionVariables({
      Day: cbday,
      Hour: cbhour,
    });

    this.ref.detectChanges();
  }

  onVisibilityChanged(event) {
    this.hideNotSupportIcons(event.visibility);
  }

  onAvailabilityChanged(event) {
    this.showLivechat = event.availability === LIVECHAT_AVAILABILITY.ONLINE;
  }

  private hideNotSupportIcons(visibility: LIVECHAT_VISIBILITY) {
    const chatWidgetMinimized = document.getElementById('chat-widget-minimized');
    const chatWidgetContainer = document.getElementById('chat-widget-container');

    if (visibility === LIVECHAT_VISIBILITY.MINIMIZED) {
      chatWidgetContainer.style.zIndex = '-1';
      chatWidgetMinimized.style.visibility = 'hidden';
    } else {
      chatWidgetContainer.style.zIndex = '99999999';
    }
  }

}
