import {Directive, HostListener} from '@angular/core';
import {GtmService} from "@app/shared/services/gtm/gtm.service";
import {GTM_FIELDS_NAME} from "@app/shared/directives/gtm-form-event/gtm-field.model";

@Directive({
  selector: 'app-datepicker-materials'
})
export class GtmDateChangeDirective {

  constructor(private gtmService: GtmService,) {
  }

  @HostListener('emitDateChange', ['$event'])
  onDateChange(event) {
    const controlName = event.nativeElement.getAttribute('id');
    const gtmFieldName = this.getGtmFieldName(controlName);

    this.gtmService.emitFormFieldClick(gtmFieldName);
  }

  getGtmFieldName(controlName: string): string {
    const name = GTM_FIELDS_NAME[controlName];
    return name ? name : controlName;
  }

}
