import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {LoadingSpinnerComponent} from '@app/shared/components/loading-spinner/loading-spinner.component';
import {filter} from 'rxjs/operators';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {GtmService} from '@app/shared/services/gtm/gtm.service';
import * as dayjs from "dayjs";
import 'dayjs/locale/pl';
import * as localeData from 'dayjs/plugin/localeData';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
import * as LocalizedFormat from 'dayjs/plugin/localizedFormat';
import * as utc from 'dayjs/plugin/utc';
import {ConfigService} from "@app/shared/services/config/config.service";
import {GoogleTagManagerService} from "angular-google-tag-manager";
import {ExitPopupService} from "@app/shared/services/exit-popup/exit-popup.service";
import {CustomApmService} from "@app/shared/services/apm/custom-apm.service";
import {StepResolver} from "@app/shared/services/url/step-resolver";
import {CalculationStoreService} from "@app/calculate/calculate/calculation-store.service";
import {RequestStoreService} from "@app/shared/services/request-store/request-store.service";
import {UrlParamsStoreService} from "@app/shared/interceptors/partner/url-params-store.service";
import {PersonalDataStoreService} from "@app/shared/services/personal-data/personal-data-store.service";
import {IconLoaderService} from '@app/shared/services/icon/icon-loader.service';
import {registerLocaleData} from "@angular/common";
import pl from '@angular/common/locales/pl';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit{
  public loadingSpinnerComponent = LoadingSpinnerComponent;

  public spinnerFilteredUrlPatterns = [
    '.*/rum/events',
    '/api/cepik/shortPathInit'
  ];

  constructor(private gtmService: GtmService,
              private router: Router,
              private configService: ConfigService,
              private googleTagManagerService: GoogleTagManagerService,
              private exitPopupService: ExitPopupService,
              private customApmService: CustomApmService,
              private calculationStoreService: CalculationStoreService,
              private requestStoreService: RequestStoreService,
              private urlParamsStoreService: UrlParamsStoreService,
              private activatedRoute: ActivatedRoute,
              private personalDataStoreService: PersonalDataStoreService,
              iconLoaderService: IconLoaderService) {

    this.googleTagManagerService.config.id = configService.gtmId;

    customApmService.init();
    exitPopupService.init();
    iconLoaderService.registerCustomIcons();

    dayjs.extend(utc);
    dayjs.extend(localeData);
    dayjs.extend(LocalizedFormat);
    dayjs.extend(customParseFormat);
    dayjs.locale('pl');

    this.gtmService.emitEntryLocationEvent();
  }

  ngOnInit() {
    registerLocaleData(pl);

    this.router.events.pipe(
      filter(event => event instanceof NavigationStart)
    ).subscribe((event: NavigationStart) => {
      if (StepResolver.isLandingPage(event.url)) {
        const search = location.search.substring(1);
        const params = search ? JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}') : {};

        this.calculationStoreService.clear();
        this.urlParamsStoreService.clear();
        this.requestStoreService.clear();
        this.personalDataStoreService.clear();

        if (event.navigationTrigger !== 'popstate') {
          this.urlParamsStoreService.store(params);
        }
      }
    });

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.gtmService.emitVirtualPageViewEvent(event);
    });
  }

}
