import {ALPHANUMERIC_REGEXP, FIRST_CHARACTER_LETTER} from "@app/shared/constants/settings.constant";

export class StringUtil {

  private static readonly alphanumericRegexp: RegExp = new RegExp(ALPHANUMERIC_REGEXP);
  private static readonly firstCharLetterRegexp: RegExp = FIRST_CHARACTER_LETTER;

  public static isAlphanumeric(value: string): boolean {
    return StringUtil.alphanumericRegexp.test(value);
  }

  public static isFirstCharLetter(value: string): boolean {
    return StringUtil.firstCharLetterRegexp.test(value);
  }

}
