import { Directive } from "@angular/core";
import { UntypedFormControl, NG_VALIDATORS, ValidationErrors, Validator } from "@angular/forms";
import {EMAIL_REGEXP} from "@app/shared/constants/settings.constant";

@Directive({
  selector: '[mail]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: MailValidator, multi: true }
  ]
})
export class MailValidator implements Validator {

  mailRegExp = EMAIL_REGEXP;

  validate(control: UntypedFormControl): ValidationErrors | null {
    const mail: string = control.value;
    const validError = { 'mail': true };

    if (!mail) return null;

    const emailParts = mail.split('@');

    if (emailParts.length !== 2) return validError;

    const account = emailParts[0];
    const address = emailParts[1];

    if (account.length > 64) return validError;
    if (address.length > 255) return validError;

    const domainParts = address.split('.');

    if (domainParts.some(function (part) {
      return part.length > 63;
    })) return validError;

    if (!!mail) return this.mailRegExp.test(mail) ? null : validError;
  }

}
