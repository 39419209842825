<div provideParentForm>
  <mat-form-field [class]="customClass"
                  (click)="picker.open()"
                  hideRequiredMarker="true"
                  appearance="fill">
        <mat-label>{{ matLabelValue }} <span class="required-star" *ngIf="redStar">*</span></mat-label>
        <div class="custom-display-format">
            {{ modelRef[modelRefField] | date : 'dd-MM-YYYY' }} godz. {{ modelRef[modelRefField] | date : 'HH:mm' }}
        </div>
        <div class="input-hide">
          <input id="pickerTimeInputDate"
                 name="pickerTimeInputDate"
                 #pickerInputDate="ngModel"
                 required
                 matInput
                 [disabled]="disabled"
                 [readonly]="true"
                 [ngModel]="modelRef[modelRefField]"
                 (ngModelChange)="pickerDateChange($event)"
                 [matDatepicker]="picker"
                 [max]="maxDate"
                 [min]="minDate">
        </div>
        <mat-datepicker-toggle matSuffix [for]="picker">
            <mat-icon matDatepickerToggleIcon svgIcon="pictogram-calendar"></mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #picker
                        (opened)="opened()"
                        [startAt]="startAt"
                        [disabled]="disabled"
                        [calendarHeaderComponent]="headerComponent">
            <mat-datepicker-actions>
                <div class="time-container">
                    <div class="close-container">
                        <mat-icon svgIcon="close-purple" class="btn-close" matDatepickerCancel></mat-icon>
                    </div>
                    <div class="slot-title">
                        {{ dateTimePickerService.currentSelectDate | date:'EEEE, d MMMM':undefined:'pl' | titlecase }}
                    </div>
                    <div class="slot-container">
                        <button type="button"
                                class="slot-item"
                                *ngFor="let slot of videoService.freeSlots"
                                [ngClass]="{ 'selected': slot.selected}"
                                (click)="setTime($event, slot)">
                            {{ slot.start.slice(11, 16) }}
                        </button>
                        <div *ngIf="!videoService.freeSlots?.length" class="text pt-4 pb-4">
                            Brak wolnych godzin w podanym dniu. Wybierz inny dzień.
                        </div>
                    </div>
                    <div class="actions-container">
                        <div class="row">
                            <div class="col-12 d-flex justify-content-center">
                                <button class="btn-apply" mat-button matDatepickerApply>Wybierz</button>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-datepicker-actions>
        </mat-datepicker>
  </mat-form-field>
</div>
