import { Component, ContentChildren, Input, OnDestroy, OnInit, QueryList } from '@angular/core';
import { UntypedFormControl, NgModel } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ValidationMessageComponent } from './validation-message.component';

@Component({
  selector: 'validation-messages',
  templateUrl: './validation-messages.html'
})
export class ValidationMessagesComponent implements OnInit, OnDestroy {

  @Input() for: NgModel;
  @ContentChildren(ValidationMessageComponent) messageComponents: QueryList<ValidationMessageComponent>;

  private statusChangesSubscription: Subscription;

  ngOnInit() {
    const form: UntypedFormControl = this.for.control;
    const input: NgModel = this.for;

    this.statusChangesSubscription = form.statusChanges
      .subscribe(() => this.updateState(input));
  }

  ngOnDestroy() {
    this.statusChangesSubscription.unsubscribe();
  }

  private updateState(input: NgModel) {
    this.messageComponents.forEach(messageComponent => messageComponent.show = false);

    if (input.invalid) {
      let firstErrorMessageComponent = this.messageComponents.find(messageComponent => {
        return messageComponent.showsErrorIncludedIn(Object.keys(this.for.errors));
      });

      if(firstErrorMessageComponent) firstErrorMessageComponent.show = true;
    }
  }
}

