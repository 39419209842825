import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {tap} from "rxjs/operators";
import {FeatureFlagModel} from "@app/shared/services/feature-flag/feature-flag.model";
import {Flags} from "@app/shared/models/flag/flag.model";

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagService {

  private flags: FeatureFlagModel[];

  constructor(private httpClient: HttpClient) {
  }

  isActive(flag: Flags): boolean {
    return this.flags.filter(f => f.name === flag)[0]?.active;
  }

  async load(): Promise<FeatureFlagModel[]> {
    return this.httpClient.post(`./features/state`, (<any>Object).values(Flags)).pipe(
      tap((response: FeatureFlagModel[]) => {
        this.flags = response;
      })
    ).toPromise();
  }

}
