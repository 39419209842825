import {
  ChangeDetectionStrategy,
  Component, ElementRef, ViewChild, ViewEncapsulation
} from '@angular/core';
import { MatCalendar } from '@angular/material/datepicker';
import {DateAdapter} from '@angular/material/core';
import * as dayjs from 'dayjs';
import {MonthModel} from '@app/shared/components/datepicker/datepicker-header/month.model';
import {tap} from "rxjs/operators";
import {VideoService} from "@app/shared/components/video/video.service";
import {DateTimePickerService} from "@app/shared/components/date-time-picker/date-time-picker.service";

@Component({
  selector: 'app-datepicker-header',
  templateUrl: 'date-time-picker-header.component.html',
  styleUrls: ['date-time-picker-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class DateTimePickerHeaderComponent<Dayjs> {
  activeMonth: string;
  activeYear: string;
  listMonth: Array<MonthModel>;
  listYear: string[];
  nextButtonLabel: string;
  prevButtonLabel: string;

  @ViewChild('datepickerSelectMonth', { static: true }) datepickerSelectMonth: ElementRef;
  @ViewChild('datepickerSelectYear', { static: true }) datepickerSelectYear: ElementRef;

  constructor(private calendar: MatCalendar<Date>,
              private dateAdapter: DateAdapter<Date>,
              private videoService: VideoService,
              private dateTimePickerService: DateTimePickerService) {
    this.activeMonth = dayjs(this.calendar.activeDate).month().toString();
    this.activeYear = dayjs(this.calendar.activeDate).year().toString();
    this._updateListMonth();
    this._updateListYear();

    if (!this.calendar.selected) {
      this.dateTimePickerService.currentSelectDate = this.calendar.activeDate;
      this.calendar.selected = this.calendar.activeDate;
    }

    this.calendar.selectedChange.pipe(
      tap(date => this.videoService.getFreeSlots(date, this.dateTimePickerService.selectedCampaignId).subscribe()),
      tap(date => this.dateTimePickerService.currentSelectDate = date)
    ).subscribe();
  }

  changeMonth($event) {
    const activeMonth = dayjs(this.calendar.activeDate).month();
    const expectedMonth = parseInt($event.value, 10);
    const diff = expectedMonth - activeMonth;

    this.activeMonth = $event.value;
    this.calendar.activeDate = this.dateAdapter.addCalendarMonths(this.calendar.activeDate, diff);
  }

  prev(): void {
    this.calendar.activeDate = this.dateAdapter.addCalendarMonths(this.calendar.activeDate, -1);
    this.activeMonth = dayjs(this.calendar.activeDate).month().toString();
    this.activeYear = dayjs(this.calendar.activeDate).year().toString();
    this._updateListMonth();
  }

  next(): void {
    this.calendar.activeDate = this.dateAdapter.addCalendarMonths(this.calendar.activeDate, 1);
    this.activeMonth = dayjs(this.calendar.activeDate).month().toString();
    this.activeYear = dayjs(this.calendar.activeDate).year().toString();
    this._updateListMonth();
  }

  private _updateListMonth() {
    const months: Set<string> = new Set<string>();
    const activeYear = this.activeYear;
    let dateStart = dayjs('01-01-' + activeYear, 'DD-MM-YYYY');
    let dateEnd = dayjs('31-12-' + activeYear, 'DD-MM-YYYY');

    if (activeYear === dayjs(this.calendar.minDate).year().toString()) {
      dateStart = dayjs(this.calendar.minDate).year(parseInt(activeYear, 10));
    }

    if (activeYear === dayjs(this.calendar.maxDate).year().toString()) {
      dateEnd = dayjs(this.calendar.maxDate).year(parseInt(activeYear, 10));
    }

    while (dateEnd.diff(dateStart, 'months', true) >= 0) {
      const shortName = this.dateAdapter.getMonthNames('short')[dateStart.month()];

      months.add(JSON.stringify({
        'id': dateStart.month().toString(),
        'shortName': shortName.charAt(0).toUpperCase() + shortName.slice(1)
      }));

      dateStart = dateStart.add(1, 'day');
    }

    this.listMonth = Array.from(months).map(el => JSON.parse(el));
  }

  private _updateListYear(): void {
    const years: Set<string> = new Set<string>();
    let dateStart = dayjs(this.calendar.minDate);
    const dateEnd = dayjs(this.calendar.maxDate);

    while (dateEnd.diff(dateStart, 'years', true) >= 0) {
      years.add(dateStart.year().toString());
      dateStart = dateStart.add(1, 'month');
    }

    this.listYear = Array.from(years.values());
  }

  nextEnabled(): boolean {
    if (!this.calendar.maxDate) {
      return true;
    }

    return !this._isSameView(this.calendar.activeDate, this.calendar.maxDate);
  }

  previousEnabled(): boolean {
    if (!this.calendar.minDate) {
      return true;
    }

    return !this._isSameView(this.calendar.activeDate, this.calendar.minDate);
  }

  private _isSameView(activeDate: Date, extremeDate: Date): boolean {
      return this.dateAdapter.getYear(activeDate) === this.dateAdapter.getYear(extremeDate) &&
             this.dateAdapter.getMonth(activeDate) === this.dateAdapter.getMonth(extremeDate);
  }

  // fix is focus on select and user must 2x click to select day
  onClosedSelect($event) {
    const activeElement = (document.activeElement as HTMLElement);
    const activeElementId = activeElement.getAttribute('id');

    if (activeElementId === 'datepickerSelectYear' || activeElementId === 'datepickerSelectMonth') {
      activeElement.blur();
    }
  }

}
