<div provideParentForm>
  <div class="checkbox-group">
    <div class="row mb-3">
      <mat-checkbox class="checkbox-all-check"
                    id="allConsentsSelect"
                    name="allConsentsSelect"
                    #allConsentsSelect="ngModel"
                    [(ngModel)]="allConsentsSelected"
                    (change)="onAllConsentsSelectedChange()">
        <div>
          <strong>Zaznacz/Odznacz wszystkie zgody</strong>
        </div>
      </mat-checkbox>
    </div>
    <div class="row" *ngFor="let consent of consents; let i = index;">
      <mat-checkbox id="{{ id }}_consent_{{ consent.code }}"
                    name="{{ id }}_consent_{{ consent.code }}"
                    [(ngModel)]="consents[i].selected"
                    #consentElementRef="ngModel"
                    (change)="onConsentSelectChange()"
                    [required]="consent.required">
        <app-consent-content [consent]="consent"></app-consent-content>
        <div *ngIf="isVehicleOwnerConsent(consent)">
          <div class="form-field">
            <mat-form-field floatLabel="never">
              <mat-select id="vehicleOwnerDetails"
                          name="vehicleOwnerDetails"
                          #vehicleOwnerDetails="ngModel"
                          [(ngModel)]="declaredVehicleOwner"
                          placeholder="Wybierz"
                          disableOptionCentering
                          required
                          [compareWith]="comparatorService.compareByVehicleOwnerDetails">
                <mat-option *ngFor="let vehicleOwnerDetails of vehicleOwnersDetails"
                            [value]="vehicleOwnerDetails">
                  {{ vehicleOwnerDetails.firstName + ' ' + vehicleOwnerDetails.lastName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </mat-checkbox>
    </div>
  </div>
</div>
