import {DamageHistoryModel} from '@app/calculate/vehicle-owner-details/damage-history/damage-history.model';
import {AddressModel} from '@app/shared/components/address/address.model';
import {v4 as uuid} from 'uuid';
import {Gender} from '@app/shared/models/gender/gender.model';

export enum PersonalDataType {
  OWNER = 'OWNER',
  FIRST_CO_OWNER = 'FIRST_CO_OWNER',
  SECOND_CO_OWNER = 'SECOND_CO_OWNER',
  INSURER = 'INSURER'
}

export class PersonalDataStoreModel {
  constructor() {
    this.uid = uuid();
    this.address = new AddressModel();
  }

  uid: string;
  damageHistory: DamageHistoryModel;
  pesel: string;
  firstName: string;
  lastName: string;
  previousLastName: string;
  birthDate: Date;
  gender: Gender;
  phoneNumber: string;
  email: string;
  address: AddressModel;
  driverLicenseObtainYear: string;
  isInsurer: boolean;
  isRegisteredAddressTheSameAsHomeAddress: boolean;
  isCorrespondenceAddressTheSameAsHomeAddress: boolean;
  isHomeAddressTheSameAsMainUser: boolean;
  largeFamilyCardNumber: number;
}
