<div provideParentForm>
  <mat-form-field hideRequiredMarker="true" appearance="fill">
    <mat-label>Imię <span class="required-star" *ngIf="redStar">*</span></mat-label>
    <input id="firstName-{{ id }}"
           name="firstName-{{ id }}"
           type="text"
           #firstName="ngModel"
           matInput
           [ngModel]="modelRef.firstName | titlecase"
           [maxlength]="firstNameValidation.maxlength"
           [required]="requiredField"
           (ngModelChange)="setFirstName($event)"
           (paste)="onPaste($event)"
           (focus)="onFocus()"
           (focusout)="onFocusOut()"
           [customAutoFocus]="autoFocus"
           [pattern]="firstNameValidation.pattern"
           a-pattern-restrict>
    <mat-error>
      <validation-messages [for]="firstName"></validation-messages>
    </mat-error>
  </mat-form-field>
</div>
