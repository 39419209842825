import {Pipe, PipeTransform} from '@angular/core';
import {CoverModel} from "@app/calculate/calculate/models/insurance-variant.model";

@Pipe({
  name: 'getCover'
})
export class GetCoverPipe implements PipeTransform {

  transform(covers: CoverModel[], code): CoverModel {
     return covers.filter(cover => cover.code === code)[0];
  }

}
