import {Injectable} from '@angular/core';
import {PrepareCalculationResponseModel} from "@app/calculate/calculate/models/prepare-calculation-response.model";
import {CepikPrepareCalculationRequest} from "@app/shared/models/cepik/cepik-prepare-calculation.model";
import {HttpClient} from "@angular/common/http";
import {API_URL} from "@app/shared/constants/settings.constant";
import {firstValueFrom, Observable, of, timeout, TimeoutError} from "rxjs";
import {CepikRequest} from "@app/shared/models/cepik/cepik-request.model";
import {CepikResponse, CepikSession} from "@app/shared/models/cepik/cepik-response.model";
import {CalculationStoreService} from "@app/calculate/calculate/calculation-store.service";
import {catchError, tap} from "rxjs/operators";
import {ShortPathInitRequest} from "@app/shared/services/cepik/short-path-init-request.model";
import {PrepareSellRequestModel} from "@app/offer/policy/prepare-sell-request.model";
import {PrepareSellResponseModel} from "@app/offer/policy/prepare-sell-response.model";
import {RegisterPolicyRequest, RegisterPolicyResponse} from "@app/shared/models/cepik/cepik-register-policy.model";
import {ModifyCalculationResponseModel} from "@app/calculate/calculate/models/modify-calculation-response.model";
import {ModifyCalculationRequestModel} from "@app/calculate/calculate/models/modify-calculation-request.model";
import {CalculationResponseModel} from "@app/calculate/calculate/models/calculation-response.model";
import {CalculationFormModel} from "@app/calculate/calculate/models/calculation-form-model";
import {CepikFormDataService} from "@app/calculate/calculate/cepik-form-data.service";
import {Config} from "@app/shared/config";

@Injectable({
  providedIn: 'root'
})
export class CepikService {
  constructor(private readonly http: HttpClient,
              private storeService: CalculationStoreService,
              private cepikFormDataService: CepikFormDataService) {
  }

  private readonly BASE_URL = `${API_URL}/cepik`;

  prepareCalculation(request: CepikPrepareCalculationRequest): Promise<PrepareCalculationResponseModel> {
    const dictionary = this.storeService.getCalculationDictionary();
    let observable;

    if (!dictionary) {
      observable = this.http.post<PrepareCalculationResponseModel>(`${this.BASE_URL}/prepareCalculation`, request).pipe(
        tap(prepareCalculationResult => {
          this.storeService.storeCalculationDictionary(prepareCalculationResult.dictionary);
        })
      );
    } else {
      observable = new Observable<PrepareCalculationResponseModel>(observer => {
        observer.next({
          dictionary: dictionary
        });
        observer.complete();
      });
    }

    return firstValueFrom(observable);
  }

  getProposalData(request: CepikRequest): Promise<CepikResponse> {
    const observable = this.http.post<CepikResponse>(`${this.BASE_URL}/proposalData`, request);

    return firstValueFrom(observable.pipe(
      tap(data => {
        this.storeService.storeProposalData(data)
        this.cepikFormDataService.storeData(data)
      }),
      timeout({first: Config.PROPOSAL_DATA_TIMEOUT_MS}),
      catchError(e => {
        if (e instanceof TimeoutError) {
          console.error("Proposal data timeout", e);
          return of({} as CepikResponse);
        } else {
          throw e;
        }
      })
    ));
  }

  shortPathInit(request: ShortPathInitRequest): Promise<string> {
    const observable = this.http.post(
      `${this.BASE_URL}/shortPathInit`,
      {
        birthDate: request.birthDate.format('YYYY-MM-DD'),
        licensePlateNumber: request.licensePlateNumber,
        partnerId: request.partnerId
      },
      {
        responseType: "text"
      });

    return firstValueFrom(observable);
  }

  prepareSell(calculationId: string, prepareSellRequest: PrepareSellRequestModel): Observable<PrepareSellResponseModel> {
    return this.http.post<PrepareSellResponseModel>(`${this.BASE_URL}/prepare/${calculationId}`, prepareSellRequest).pipe(
      tap(prepareSellResponse => {
        this.storeService.storePrepareSellResponse(prepareSellResponse);
        this.storeService.storePolicyOfferToken(prepareSellResponse.policyOfferToken);
        this.storeService.storeSellDocumentsInsurerEmail(prepareSellRequest.insurerSendEmail);
        this.storeService.storeSellDocumentsOwnerEmail(prepareSellRequest.ownerSendEmail);
      })
    );
  }

  registerPolicy(request: RegisterPolicyRequest): Observable<RegisterPolicyResponse> {
    return this.http.post<RegisterPolicyResponse>(`${this.BASE_URL}/registerPolicy`, request);
  }

  modifyCalculation(calculationId: string, params: ModifyCalculationRequestModel): Observable<ModifyCalculationResponseModel> {
    return this.http.put<ModifyCalculationResponseModel>(`${this.BASE_URL}/modify/${calculationId}`, params);
  }

  saveCalculation(calculationId: string, calculationForm: CalculationFormModel) {
    return this.http.put<CalculationResponseModel>(`${this.BASE_URL}/${calculationId}/save`, calculationForm);
  }

  getSession(publicId: string): Observable<CepikSession> {
    return this.http.get<CepikSession>(`${this.BASE_URL}/session/${publicId}`);
  }

}
