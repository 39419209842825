import { GtmConsentModel } from "@app/shared/services/gtm/model/consent.model";
import { TagModel } from "@app/shared/services/gtm/model/tag.model";

export class VirtualPageViewTagModel extends TagModel {
  virtualPagePath: string;
  virtualPageFullPath: string;
  virtualPageURL: string;
  pageTitle: string;
  ecommPageType: string;
  ecommFunnelType: string;
  stepOrder: string;
  stepDisplayNo: string;
  carYear: string;
  carBrand: string;
  carModel: string;
  carBody: string;
  carEngineVol: string;
  carFuel: string;
  insuranceFunnelType: string;
  wheelRightSide: string;
  naturalGas: string;
  mileage: string;
  avgYearMileage: string;
  usedAbroad: string;
  usage: string;
  insurancePolandYear: string;
  insuranceOwnerYear: string;
  cosmeticDamages: string;
  nightStanding: string;
  ownerNumber: string;
  under24: string;
  mainUser: string;
  under24Age: string;
  ownerFirstName: string;
  ownerLastName: string;
  ownerEmail: string;
  ownerPhoneNumber: string;
  ownerYear: string;
  ownerGender: string;
  OCYears: string;
  ACYears: string;
  OC6YearsDamages: string;
  AC6YearsDamages: string;
  OC3YearsDamages: string;
  AC3YearsDamages: string;
  OC1YearsDamages: string;
  AC1YearsDamages: string;
  drivingLicenceYear: string;
  firstCoOwnerOCYears: string;
  firstCoOwnerACYears: string;
  firstCoOwnerOC6YearsDamages: string;
  firstCoOwnerAC6YearsDamages: string;
  firstCoOwnerOC3YearsDamages: string;
  firstCoOwnerAC3YearsDamages: string;
  firstCoOwnerOC1YearsDamages: string;
  firstCoOwnerAC1YearsDamages: string;
  secondCoOwnerOCYears: string;
  secondCoOwnerACYears: string;
  secondCoOwnerOC6YearsDamages: string;
  secondCoOwnerAC6YearsDamages: string;
  secondCoOwnerOC3YearsDamages: string;
  secondCoOwnerAC3YearsDamages: string;
  secondCoOwnerOC1YearsDamages: string;
  secondCoOwnerAC1YearsDamages: string;
  insuranceStart: string;
  insuranceStartDiff: string;
  coupon: string;
  link4consents: GtmConsentModel[];
  link4consents2: GtmConsentModel[];
}
