<div class="back-container d-flex"
     [ngClass]="{'custom-width': customWidth}"
     *ngIf="show">
     
    <a class="back-button" (click)="back()">
        <mat-icon svgIcon="arrow-left"></mat-icon>
        Wróć
    </a>
    <offer-number></offer-number>
</div>
