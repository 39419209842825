import {ItCategory} from "@app/shared/services/gtm/model/it-category.model";
import {ItAction} from "@app/shared/services/gtm/model/it-action.model";
import {ItTagModel} from "@app/shared/services/gtm/tag/it-tag.model";

export class ItTagBuilder {

  private readonly tag: ItTagModel;

  constructor() {
    this.tag = new ItTagModel();
    this.tag.event = 'ITEvent';
  }

  public static builder(): ItTagBuilder {
    return new ItTagBuilder();
  }

  public category(category: ItCategory): ItTagBuilder {
    this.tag.category = category;
    return this;
  }

  public action(action: ItAction): ItTagBuilder {
    this.tag.action = action;
    return this;
  }

  public label(label: string): ItTagBuilder {
    this.tag.label = label;
    return this;
  }

  public build(): ItTagModel {
    return this.tag;
  }

}
