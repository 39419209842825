import { OfferStep, Step } from '@app/shared/models/step/step.model';
import { Observable } from 'rxjs';

export abstract class StoreService {

  private sessionStorage: Storage;

  protected constructor() {
    this.sessionStorage = sessionStorage;
  }

  abstract clear(): void;
  abstract afterStoreStep(step: Step | OfferStep): Observable<void>;

  protected storeStep<T>(step: Step | OfferStep, item: T): Observable<void> {
    this.storeItem(step, item);
    return this.afterStoreStep(step);
  }

  protected getStep<T>(view: Step | OfferStep): T {
    return this.getItem(view);
  }

  public clearStep<T>(view: Step | OfferStep): void {
    this.clearItem(view);
  }

  protected storeItem(key: string, item: any) {
    this.sessionStorage.setItem(key, JSON.stringify(item));
  }

  protected getItem(key: string): any {
    const viewJson = this.sessionStorage.getItem(key);
    return (viewJson === null || viewJson === undefined || viewJson === 'undefined') ? null : JSON.parse(viewJson, this.dateParser);
  }

  protected clearItem(key: string) {
    this.sessionStorage.removeItem(key);
  }

  dateParser = function(key, value) {
    const dateFormatRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;
    if (typeof value === "string" && dateFormatRegex.test(value)) {
      return new Date(value);
    }
    return value;
  };
}
