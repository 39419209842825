import { NavigationEnd } from "@angular/router";
import { StepResolver } from "@app/shared/services/url/step-resolver";

export class PageTitleMapper {

  private static readonly METHOD_CHOICE = 'wybor-metody';

  public static map(navigation: NavigationEnd): string {
    let viewName = StepResolver.getCurrentStepName(navigation.url);

    if(viewName.length < 1) {
      return PageTitleMapper.METHOD_CHOICE;
    }

    return viewName;
  }

}
