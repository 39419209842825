import { InputMode } from "@app/landing-page/landing-page.model";

export class ECommFunnelTypeMapper {

  private static readonly MANUAL_FILLING = 'Manual Filling';
  private static readonly PESEL = 'PESEL';
  private static readonly NOT_CHOSEN = '(not_chosen)';

  public static map(inputMode: InputMode): string {
    if (InputMode.DECLARATION === inputMode) {
      return ECommFunnelTypeMapper.MANUAL_FILLING;
    }

    return ECommFunnelTypeMapper.NOT_CHOSEN;
  }

}
