import {UntypedFormControl, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';
import {Directive} from '@angular/core';
import {StringUtil} from '@app/shared/services/string/string-util.service';

@Directive({
  selector: '[licensePlateNumber]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: LicensePlateNumberValidator,
    multi: true
  }]
})
export class LicensePlateNumberValidator implements Validator {

  validate(control: UntypedFormControl): ValidationErrors | null {
    const licensePlate: string = control.value;

    if (licensePlate && !this.isLengthValid(licensePlate)) {
      return {'license-plate-number-length': true};
    } else if (licensePlate && !this.isAlphanumeric(licensePlate)) {
      return {'license-plate-number-alphanumeric': true};
    } else if (licensePlate && !this.isFirstCharLetter(licensePlate)) {
      return {'license-plate-number-first-character': true};
    }

    return null;
  }

  private isAlphanumeric(licensePlate: string): boolean {
    return StringUtil.isAlphanumeric(licensePlate);
  }

  private isLengthValid(licensePlate: string): boolean {
    return licensePlate.length >= 4 && licensePlate.length <= 8;
  }

  private isFirstCharLetter(licensePlate: string): boolean {
    return StringUtil.isFirstCharLetter(licensePlate);
  }
}
