import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import * as dayjs from "dayjs";
import {API_URL} from "@app/shared/constants/settings.constant";
import {Observable} from "rxjs";
import {LoggedAgentsResponse} from "@app/shared/components/video/video.model";
import {SlotModel} from "@app/shared/components/date-time-picker/slot.model";
import {tap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class VideoService {

  constructor(private readonly http: HttpClient) {
  }

  freeSlots: SlotModel[];

  private readonly _urlPrefix = `${API_URL}/videocall`;

  getFreeSlots(startDate: Date, campaignId: number): Observable<SlotModel[]> {
    const currentDate = dayjs(new Date());
    const offsetTime = 30;
    let startTime = "00:00";

    if (dayjs(startDate).date() === currentDate.date()) {
      startTime = currentDate.add(offsetTime, 'minute').format('HH:mm');
    }

    const params = {
      campaignId: campaignId,
      startDate: dayjs(startDate).format('YYYY-MM-DD ' + startTime),
      endDate: dayjs(startDate).format('YYYY-MM-DD 23:59'),
    };

    return this.http.get<any>(this._urlPrefix + '/calendar/free-slots', { params: params }).pipe(
      tap(slots => this.freeSlots = slots)
    );
  }

  loggedAgents(queueName: string): Observable<LoggedAgentsResponse> {
    const params = {
      queueName: queueName
    };

    return this.http.post<any>(this._urlPrefix + '/logged-agents', params);
  }

  meetingBook(subject: string, date: Date, campaignId: number): Observable<any> {
    const params = {
      startDate: dayjs(date).format('YYYY-MM-DD HH:mm:ss'),
      validDate: dayjs(date).format('YYYY-MM-DD HH:mm:ss'),
      subject: subject,
      campaignId: campaignId,
    };

    return this.http.post<any>(this._urlPrefix + '/calendar/meeting/book', params);
  }

  meetingConfirm(meetingId: number, firstName: string, lastName: string, email: string): Observable<any> {
    const params = {
      meetingId: meetingId,
      clientName: firstName,
      clientSurname: lastName,
      clientEmail: email,
      signLanguageTranslatorRequired: false
    };

    return this.http.post<any>(this._urlPrefix + '/calendar/meeting/confirm', params);
  }

}
