import {Injectable} from '@angular/core';
import {CoverModel} from "@app/calculate/calculate/models/insurance-variant.model";
import {
  AC_AMORTIZATION,
  AC_ORIGINAL_STAFF,
  AC_REPAIR_SHOP,
  AC_THEFT,
  AUTO_ASSISTANCE_AC,
  AUTO_ASSISTANCE_OC,
  FHH_DISCOUNT,
  HELP_PROGRAM,
  KDR_DISCOUNT,
  LAST_DISCOUNTS,
  MARKETING_AUTOMATION_DISCOUNT,
  NEW_ONLINE_DISCOUNTS,
  NNW_AC,
  NNW_OC,
  OC,
  ONLINE_DISCOUNT,
  PAYMENT_DISCOUNT,
  PROMOTIONAL_CODE_DISCOUNT,
  SELLER_DISCOUNT,
  SMART_CASCO_OC,
  SUM_DISCOUNTS
} from "@app/shared/models/cover/cover.model";
import {CalculationStoreService} from "@app/calculate/calculate/calculation-store.service";
import {InputMode} from "@app/landing-page/landing-page.model";

@Injectable({
  providedIn: 'root'
})
export class CoverService {

  constructor(private calculationStoreService: CalculationStoreService) {
  }

  public get hiddenInDeclarationCepikProcessCode(): ReadonlyArray<number> {
    const coversMap: Record<string, boolean> = {
      [NEW_ONLINE_DISCOUNTS]: true,
      [ONLINE_DISCOUNT]: true,
      [SUM_DISCOUNTS]: true,
      [LAST_DISCOUNTS]: true,
      [PAYMENT_DISCOUNT]: true,
      [SELLER_DISCOUNT]: true,
      [FHH_DISCOUNT]: true,
      [KDR_DISCOUNT]: true,
      [PROMOTIONAL_CODE_DISCOUNT]: true,
      [MARKETING_AUTOMATION_DISCOUNT]: true,
    }

    return this.mapCoverEntries(coversMap);
  }

  public get hiddenInOmniProcessCode(): ReadonlyArray<number> {
    const coversMap: Record<string, boolean> = {
      [HELP_PROGRAM]: true,
      [ONLINE_DISCOUNT]: true,
      [NEW_ONLINE_DISCOUNTS]: true,
      [SUM_DISCOUNTS]: true,
      [LAST_DISCOUNTS]: true,
      [PROMOTIONAL_CODE_DISCOUNT]: true,
      [KDR_DISCOUNT]: true,
      [PAYMENT_DISCOUNT]: true,
      [SELLER_DISCOUNT]: true,
      [FHH_DISCOUNT]: true,
      [MARKETING_AUTOMATION_DISCOUNT]: true,
    }

    return this.mapCoverEntries(coversMap);
  }

  public get AcCodes(): ReadonlyArray<number> {
    const coversMap: Record<string, boolean> = {
      [AC_ORIGINAL_STAFF]: true,
      [AC_AMORTIZATION]: true,
      [AC_THEFT]: true,
      [AC_REPAIR_SHOP]: true,
    }

    return this.mapCoverEntries(coversMap);
  }

  public get DiscountCodes(): ReadonlyArray<number> {
    const coversMap: Record<string, boolean> = {
      [ONLINE_DISCOUNT]: true,
      [NEW_ONLINE_DISCOUNTS]: true,
      [SUM_DISCOUNTS]: true,
      [LAST_DISCOUNTS]: true,
      [PROMOTIONAL_CODE_DISCOUNT]: true,
      [KDR_DISCOUNT]: true,
      [PAYMENT_DISCOUNT]: true,
      [SELLER_DISCOUNT]: true,
      [FHH_DISCOUNT]: true,
      [MARKETING_AUTOMATION_DISCOUNT]: true,
    }

    return this.mapCoverEntries(coversMap);
  }

  public get OmniOfferPrimaryCodes(): ReadonlyArray<number> {
    const coversMap: Record<string, boolean> = {
      [OC]: true,
      [AC_THEFT]: true,
      [AC_REPAIR_SHOP]: true,
      [AC_AMORTIZATION]: true,
      [AC_ORIGINAL_STAFF]: true,
      [NNW_OC]: true,
      [NNW_AC]: true,
      [AUTO_ASSISTANCE_AC]: true,
      [AUTO_ASSISTANCE_OC]: true,
      [SMART_CASCO_OC]: true,
    }

    return this.mapCoverEntries(coversMap);
  }

  private mapCoverEntries(coversMap: Record<string, boolean>) {
    return Object.entries(coversMap)
                 .filter(e => Boolean(e[1]))
                 .map(e => Number(e[0]));
  }

  public filterInAllProcess(covers: ReadonlyArray<CoverModel>): CoverModel[] {
    const inputMode = this.calculationStoreService.getLandingPage().inputMode;
    let filterCodes = this.hiddenInDeclarationCepikProcessCode;

    if (inputMode === InputMode.OMNI) {
      filterCodes = this.hiddenInOmniProcessCode;
    }

    return covers.filter(cover => !filterCodes.includes(cover.code));
  }

  public filterAc(covers: ReadonlyArray<CoverModel>): CoverModel[] {
    const acCodes = this.AcCodes;
    return covers.filter(cover => !acCodes.includes(cover.code));
  }

  public filterDiscounts(covers: ReadonlyArray<CoverModel>): CoverModel[] {
    const discountCodes = this.DiscountCodes;
    return covers.filter(cover => !discountCodes.some(code => code === cover.code));
  }

  public filterOmniOfferPrimary(covers: ReadonlyArray<CoverModel>): CoverModel[] {
    const primaryCodes = this.OmniOfferPrimaryCodes;
    return covers.filter(cover => primaryCodes.some(code => code === cover.code));
  }

  public filterOmniOfferWithoutPrimary(covers: ReadonlyArray<CoverModel>): CoverModel[] {
    const primaryCodes = this.OmniOfferPrimaryCodes;
    return covers.filter(cover => !primaryCodes.some(code => code === cover.code));
  }

}
