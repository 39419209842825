import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {PersonalDataType} from "@app/shared/services/personal-data/personal-data-store.model";
import * as dayjs from "dayjs";
import {PersonalDataStoreService} from "@app/shared/services/personal-data/personal-data-store.service";
import {CalculationStoreService} from "@app/calculate/calculate/calculation-store.service";
import {OfferRecalculatedResponse} from "@app/shared/services/omni/omni.model";
import {API_URL} from "@app/shared/constants/settings.constant";
import {Observable, of, timeout, TimeoutError} from "rxjs";
import {catchError, tap} from "rxjs/operators";
import {PrepareSellResponseModel} from "@app/offer/policy/prepare-sell-response.model";
import {CoverModel} from "@app/calculate/calculate/models/insurance-variant.model";
import {RegisterPolicyRequest} from "@app/shared/models/cepik/cepik-register-policy.model";
import {PrepareSellRequestModel} from "@app/offer/policy/prepare-sell-request.model";
import {Config} from "@app/shared/config";

@Injectable({
  providedIn: 'root'
})
export class OmniService {

  constructor(private readonly http: HttpClient,
              private personalDataStore: PersonalDataStoreService,
              private storeService: CalculationStoreService,
              private calculationStoreService: CalculationStoreService) {
  }

  private readonly _urlPrefix = `${API_URL}/omni`;

  getOfferByHash(hash: string, calcId: string, omniTrafficSourceId: string): Observable<OfferRecalculatedResponse> {
    return this.http.get<OfferRecalculatedResponse>(this._urlPrefix + '/offer/' + hash + '/' + calcId + '?trafficId=' + omniTrafficSourceId + "&applicationName=calculator");
  }

  getOffer(): Observable<OfferRecalculatedResponse> {
    const ownerData = this.personalDataStore.getPersonalData(PersonalDataType.OWNER)
    const params = {
      calculationId: this.calculationStoreService.getCalculationId(),
      dateOfBirth: dayjs(ownerData.birthDate).format('YYYY-MM-DD'),
      licensePlate: this.calculationStoreService.getVehicleRegistrationData().licensePlateNumber,
      applicationName: "calculator"
    };

    return this.http.get<OfferRecalculatedResponse>(this._urlPrefix + '/offer-recalculated', { params: params }).pipe(
      timeout({first: Config.OMNI_OFFER_TIMEOUT_MS})
    );
  }

  updateOffer(): Observable<OfferRecalculatedResponse> {
    const params = {
      calculationId: this.calculationStoreService.getCalculationId(),
      formData: this.calculationStoreService.getCalculationForm(),
      covers: this.calculationStoreService.getSelectedCovers(),
    };

    return this.http.put<OfferRecalculatedResponse>(this._urlPrefix + '/offer', params);
  }

  calculate(covers?: CoverModel[]): Observable<OfferRecalculatedResponse> {
    const params = {
      calculationId: this.calculationStoreService.getCalculationId(),
      formData: this.calculationStoreService.getCalculationForm(),
      covers: this.calculationStoreService.getSelectedCovers(),
    };

    if (covers?.length) {
      params.covers = covers;
    }

    return this.http.post<OfferRecalculatedResponse>(this._urlPrefix + '/calculation', params);
  }

  prepareSell(prepareSellRequest: PrepareSellRequestModel): Observable<PrepareSellResponseModel> {
    const params = {
      calculationId: this.calculationStoreService.getCalculationId(),
      formData: this.calculationStoreService.getCalculationForm(),
      covers: this.calculationStoreService.getSelectedCovers(),
      packageName: this.calculationStoreService.getInsuranceVariant().packageName,
      sendingSellDocuments: prepareSellRequest.sendingSellDocuments
    };

    return this.http.put<PrepareSellResponseModel>(this._urlPrefix + '/policy-prepared', params).pipe(
        tap(response => {
            this.storeService.storePrepareSellResponse(response);
            this.calculationStoreService.storePolicyOfferToken(response.policyOfferToken);
            this.storeService.storeSellDocumentsInsurerEmail(prepareSellRequest.insurerSendEmail);
            this.storeService.storeSellDocumentsOwnerEmail(prepareSellRequest.ownerSendEmail);
          }
        )
    );
  }

  sell(params: RegisterPolicyRequest): Observable<OfferRecalculatedResponse> {
    return this.http.put<OfferRecalculatedResponse>(this._urlPrefix + '/policy', params);
  }

}
