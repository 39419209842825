import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);
const hideLoader = () => {
  Array.from(document.querySelectorAll(".pre-loader-container")).forEach(function(el: HTMLElement) {
    el.style.display = 'none';
  });
}

bootstrap().catch(err =>  {
  if (err) {
    hideLoader();
  }
});

