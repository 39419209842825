export interface ErrorInfo {
  id: string,
  code: ErrorCode,
  type: ErrorType,
  message: string
}

export enum ErrorType {
  BUSINESS = 'BUSINESS',
  SYSTEM = 'SYSTEM',
  VALIDATION = 'VALIDATION'
}

export enum ErrorCode {
  VEHICLE_POLICY_EXIST = '5190240',
  VEHICLE_VIN_EXIST = '1000776',
  VEHICLE_PLATE_NUMBER_EXIST = '1000777',
  VEHICLE_TYPE_NOT_SUPPORTED = 'VEHICLE_TYPE_NOT_SUPPORTED',
  CONTACT_NAME_OR_PESEL_INCONSISTENT = '5240319',
  CONTACT_PREVIOUS_NAME_INCONSISTENT = '109460010',
  CONTACT_ADDRESS_INCONSISTENT = '5240320',
  EXIT_POPUP_MOUSE_LEAVE_TOP_BORDER = 'exit-popup-mouse-leave-top-border',
  OMNI_PATH = 'OMNI_PATH',
}

export enum SellBlacklistErrorCode {
  SELL_BLACKLIST_BLACKLISTED = 'SELL_BLACKLIST_BLACKLISTED',
  REGISTER_BLACKLISTED = 'REGISTER_BLACKLISTED',
  ACCEPT_BLACKLISTED = 'ACCEPT_BLACKLISTED',
}

export const enum CoverErrorCode {
  COVER_UNAVAILABLE = '5270026'
}

export const enum CepikErrorCode {
  CEPIK_SESSION_EXPIRED = 'CEPIK_SESSION_EXPIRED'
}
