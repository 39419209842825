import {VehicleModel} from '@app/calculate/vehicle/vehicle.model';
import {VehicleAdditionalInfoModel} from '@app/calculate/vehicle-additional-info/vehicle-additional-info.model';
import {VehicleOwnerModel} from '@app/calculate/vehicle-owner/vehicle-owner.model';
import {InsuranceStartDateModel} from '@app/calculate/insurance-start-date/insurance-start-date.model';
import {InsuranceVariantModel} from '@app/calculate/calculate/models/insurance-variant.model';
import {LandingPageModel} from '@app/landing-page/landing-page.model';
import {InsuranceTotalPriceModel} from '@app/shared/models/insurance-total-price/insurance-total-price.model';
import {VehicleRegistrationDataModel} from '@app/offer/vehicle-registration-data/vehicle-registration-data.model';
import {PersonalDataStoreModel} from '@app/shared/services/personal-data/personal-data-store.model';
import {UrlParamsModel} from "@app/shared/models/url-parameters/url-params.model";

export class CalculateVariantsCepikRequestModel {
  landingPage: LandingPageModel;
  vehicle: VehicleModel;
  vehicleAdditionalInfo: VehicleAdditionalInfoModel;
  vehicleOwner: VehicleOwnerModel;
  vehicleOwnerDetails: PersonalDataStoreModel;
  insuranceStartDate: InsuranceStartDateModel;
  insuranceVariant: InsuranceVariantModel;
  insuranceTotalPrice: InsuranceTotalPriceModel;
  vehicleRegistrationData: VehicleRegistrationDataModel;
  urlParams: UrlParamsModel;
  flowId: string;
  offerNumber?: string;

  constructor(landingPageModel: LandingPageModel,
              vehicle: VehicleModel,
              vehicleAdditionalInfo: VehicleAdditionalInfoModel,
              vehicleOwner: VehicleOwnerModel,
              vehicleOwnerDetails: PersonalDataStoreModel,
              insuranceStartDate: InsuranceStartDateModel,
              vehicleRegistrationData: VehicleRegistrationDataModel,
              urlParams: UrlParamsModel,
              flowId: string,
              offerNumber?: string) {
    this.landingPage = landingPageModel;
    this.vehicle = vehicle;
    this.vehicleAdditionalInfo = vehicleAdditionalInfo;
    this.vehicleOwner = vehicleOwner;
    this.vehicleOwnerDetails = vehicleOwnerDetails;
    this.insuranceStartDate = insuranceStartDate;
    this.vehicleRegistrationData = vehicleRegistrationData;
    this.urlParams = urlParams;
    this.flowId = flowId;
    this.offerNumber = offerNumber;
  }

}
