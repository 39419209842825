import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Step } from '@app/shared/models/step/step.model';
import {LandingPageComponent} from '@app/landing-page/landing-page.component';
import {CalculateComponent} from '@app/calculate/calculate/calculate.component';
import {CalculateGuard} from '@app/calculate/calculate.guard';
import {SessionExpiredComponent} from '@app/shared/components/session/session-expired.component';

const appRoutes: Routes = [
  {
    path: '',
    component: CalculateComponent,
    canActivateChild: [CalculateGuard],
    children: [
      {
        path: '',
        component: LandingPageComponent
      },
      {
        path: Step.SESSION_EXPIRED,
        component: SessionExpiredComponent
      },
      {
        path: '',
        loadChildren: () => import('./calculate/calculate.module').then(m => m.CalculateModule)
      },
      {
        path: '',
        loadChildren: () => import('./offer/offer.module').then(m => m.OfferModule)
      },
      {
        path: '',
        loadChildren: () => import('./sell/sell.module').then(m => m.SellModule)
      }
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes,
      {
        enableTracing: false, // <-- debugging purposes only
        useHash: false,
        scrollPositionRestoration: 'top',
      }
    )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
