import {VehicleModel} from '@app/calculate/vehicle/vehicle.model';
import {VehicleAdditionalInfoModel} from '@app/calculate/vehicle-additional-info/vehicle-additional-info.model';
import {VehicleOwnerModel} from '@app/calculate/vehicle-owner/vehicle-owner.model';
import {InsuranceStartDateModel} from '@app/calculate/insurance-start-date/insurance-start-date.model';
import {InsuranceVariantModel} from '@app/calculate/calculate/models/insurance-variant.model';
import {LandingPageModel} from '@app/landing-page/landing-page.model';
import {InsuranceTotalPriceModel} from '@app/shared/models/insurance-total-price/insurance-total-price.model';
import {VehicleRegistrationDataModel} from '@app/offer/vehicle-registration-data/vehicle-registration-data.model';
import {ContactAndConsentModel} from '@app/offer/contact-and-consent/contact-and-consent.model';
import {
  VehicleOwnerAdditionalAddressModel
} from '@app/offer/vehicle-owner-additional-address/vehicle-owner-additional-address.model';
import {PersonalDataStoreModel} from '@app/shared/services/personal-data/personal-data-store.model';
import {UrlParamsModel} from "@app/shared/models/url-parameters/url-params.model";

export class CalculationFormModel {

  landingPage: LandingPageModel;
  vehicle: VehicleModel;
  vehicleAdditionalInfo: VehicleAdditionalInfoModel;
  vehicleOwner: VehicleOwnerModel;
  vehicleOwnerDetails: PersonalDataStoreModel;
  vehicleFirstCoOwnerDetails?: PersonalDataStoreModel;
  vehicleSecondCoOwnerDetails?: PersonalDataStoreModel;
  insurerDetails?: PersonalDataStoreModel;
  insuranceStartDate: InsuranceStartDateModel;
  insuranceVariant?: InsuranceVariantModel;
  insuranceTotalPrice?: InsuranceTotalPriceModel;
  vehicleRegistrationData: VehicleRegistrationDataModel;
  contactAndConsent?: ContactAndConsentModel;
  vehicleOwnerAdditionalAddress?: VehicleOwnerAdditionalAddressModel;
  vehicleFirstCoOwnerAdditionalAddress?: VehicleOwnerAdditionalAddressModel;
  vehicleSecondCoOwnerAdditionalAddress?: VehicleOwnerAdditionalAddressModel;
  urlParams: UrlParamsModel;
  variants?: InsuranceVariantModel[];
  flowId: string;
  offerNumber?: string;

  constructor(landingPageModel: LandingPageModel,
              vehicle: VehicleModel,
              vehicleAdditionalInfo: VehicleAdditionalInfoModel,
              vehicleOwner: VehicleOwnerModel,
              vehicleOwnerDetails: PersonalDataStoreModel,
              vehicleFirstCoOwnerDetails: PersonalDataStoreModel,
              vehicleSecondCoOwnerDetails: PersonalDataStoreModel,
              insurerDetails: PersonalDataStoreModel,
              insuranceStartDate: InsuranceStartDateModel,
              insuranceVariant: InsuranceVariantModel,
              insuranceTotalPrice: InsuranceTotalPriceModel,
              vehicleOwnerAdditionalAddress: VehicleOwnerAdditionalAddressModel,
              vehicleFirstCoAdditionalAddress: VehicleOwnerAdditionalAddressModel,
              vehicleSecondCoAdditionalAddress: VehicleOwnerAdditionalAddressModel,
              vehicleRegistrationData: VehicleRegistrationDataModel,
              contactAndConsent: ContactAndConsentModel,
              urlParams: UrlParamsModel,
              variants: InsuranceVariantModel[],
              flowId: string,
              offerNumber?: string) {
    this.landingPage = landingPageModel;
    this.vehicle = vehicle;
    this.vehicleAdditionalInfo = vehicleAdditionalInfo;
    this.vehicleOwner = vehicleOwner;
    this.vehicleOwnerDetails = vehicleOwnerDetails;
    this.vehicleFirstCoOwnerDetails = vehicleFirstCoOwnerDetails;
    this.vehicleSecondCoOwnerDetails = vehicleSecondCoOwnerDetails;
    this.insurerDetails = insurerDetails;
    this.insuranceStartDate = insuranceStartDate;
    this.insuranceVariant = insuranceVariant;
    this.insuranceTotalPrice = insuranceTotalPrice;
    this.vehicleRegistrationData = vehicleRegistrationData;
    this.contactAndConsent = contactAndConsent;
    this.vehicleOwnerAdditionalAddress = vehicleOwnerAdditionalAddress;
    this.vehicleFirstCoOwnerAdditionalAddress = vehicleFirstCoAdditionalAddress;
    this.vehicleSecondCoOwnerAdditionalAddress = vehicleSecondCoAdditionalAddress;
    this.urlParams = urlParams;
    this.variants = variants;
    this.flowId = flowId;
    this.offerNumber = offerNumber;
  }

}
