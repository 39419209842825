import {Injectable} from '@angular/core';
import * as dayjs from "dayjs";

@Injectable({
  providedIn: 'root'
})
export class DatePickerService {

  maxInsuranceStartDate(): Date {
    const today = dayjs(new Date());
    return today.add(180, 'days').toDate();
  }

}
