import {PersonalDataStoreModel, PersonalDataType} from '@app/shared/services/personal-data/personal-data-store.model';
import {StoreService} from '@app/shared/services/store/store.service';
import {Step} from '@app/shared/models/step/step.model';
import {Observable, of} from 'rxjs';
import {Injectable} from '@angular/core';

@Injectable()
export class PersonalDataStoreService extends StoreService {

  constructor() {
    super();
  }

  getPersonalData(key: PersonalDataType) {
    return this.getItem(key);
  }

  storePersonalData(key: PersonalDataType, data: PersonalDataStoreModel) {
    return of(this.storeItem(key, data));
  }

  clearPersonalData(key: PersonalDataType) {
    this.clearItem(key);
  }

  clear(): void {
    Object.values(PersonalDataType).forEach(key => this.clearItem(key));
  }

  afterStoreStep(step: Step): Observable<void> {
    return undefined;
  }

}
