export class ActionField {
  id: string;
  list: string;
  step: number;
  option: string;
  affiliation: string;
  revenue: string;
  tag: string;
  tax: string;
  shipping: string;
  coupon: string;
}
