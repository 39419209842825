<div provideParentForm>
  <mat-form-field hideRequiredMarker="true" appearance="fill">
    <mat-label>Nazwisko <span class="required-star" *ngIf="redStar">*</span></mat-label>
    <input id="lastName-{{ id }}"
           name="lastName-{{ id }}"
           type="text"
           #lastName="ngModel"
           matInput
           [maxlength]="lastNameValidation.maxlength"
           [minlength]="lastNameValidation.minlength"
           [ngModel]="modelRef.lastName | titleUpperCaseFormatter"
           (ngModelChange)="setLastName($event)"
           (blur)="onBlur($event)"
           (paste)="onPaste($event)"
           (focus)="onFocus()"
           (focusout)="onFocusOut()"
           [required]="requiredField"
           [pattern]="lastNameValidation.pattern"
           a-pattern-restrict>
    <mat-error>
      <validation-messages [for]="lastName">
        <validation-message name="minlength">
          {{ 'Minimalna liczba znaków wynosi: ' + lastNameValidation.minlength }}
        </validation-message>
      </validation-messages>
    </mat-error>
  </mat-form-field>
</div>
