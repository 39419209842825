import {AfterContentInit, Directive, ElementRef, Input} from "@angular/core";

@Directive({
  selector: "[customAutoFocus]"
})
export class CustomAutoFocusDirective implements AfterContentInit {
  @Input() public customAutoFocus: boolean;

  public constructor(private el: ElementRef) {}

  public ngAfterContentInit() {
    if (this.customAutoFocus) {
      this.el.nativeElement.focus();
    }
  }
}
