import {Injectable} from '@angular/core';

@Injectable()
export class ScrollToFirstInvalidService {

  private get containerEl() {
    const dialogContent = document.querySelectorAll(".mat-mdc-dialog-container");
    return dialogContent.length ? dialogContent[0] : window;
  }

  public scrollToElement(element: HTMLElement) {
    if (element) {
      this.containerEl.scroll({
        behavior: 'smooth',
        left: 0,
        top: this.getTopOffset(element)
      });
    }
  }

  private getTopOffset(element: HTMLElement): number {
    const dialogContent = document.querySelectorAll(".mat-mdc-dialog-container");
    const stickyHeaderEl = document.querySelectorAll("app-header");
    const stickyHeaderHeight = stickyHeaderEl.length ? stickyHeaderEl[0].getBoundingClientRect().height : 0;
    const labelOffset = 30;
    const elementTop = element.getBoundingClientRect().top;

    if (dialogContent.length) {
      const containerTop = dialogContent[0].getBoundingClientRect().top;
      return elementTop + dialogContent[0].scrollTop - containerTop - labelOffset;
    } else {
      return elementTop + window.scrollY - labelOffset - stickyHeaderHeight;
    }
  }
}
