import {Directive, OnInit} from "@angular/core";
import {NavigationEnd, Router} from "@angular/router";
import { StepResolver } from "@app/shared/services/url/step-resolver";
import {filter} from 'rxjs/operators';

@Directive()
export abstract class BaseStepVisibleComponent implements OnInit {

  protected readonly visibleForSteps : Set<string>;
  protected readonly router: Router;

  showComponent: boolean;

  protected constructor(visibleForSteps: Set<string>, router: Router) {
    this.visibleForSteps = visibleForSteps;
    this.router = router;
  }

  ngOnInit() {
    this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.refreshView();
    });

    this.refreshView();
  }

  protected abstract updateData(): void;

  protected refreshView() {
    this.showComponent = this.resolveVisibility(this.visibleForSteps);

    if (this.showComponent) {
      this.updateData();
    }
  }

  protected resolveVisibility(steps: Set<string>): boolean {
    const step = StepResolver.getCurrentStepName(this.router.url);
    return steps.has(step);
  }

}
