import {Pipe, PipeTransform} from '@angular/core';
import {CoverModel} from "@app/calculate/calculate/models/insurance-variant.model";

@Pipe({
  name: 'existCover'
})
export class ExistCoverPipe implements PipeTransform {

  transform(covers: CoverModel[], code): boolean {
     return covers.some(cover => cover.code === code);
  }

}
