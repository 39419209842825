import {Component, Input} from '@angular/core';
import {VehicleOwnerDetailsModel} from "@app/calculate/vehicle-owner-details/vehicle-owner-details.model";
import {ComparatorService} from "@app/shared/services/comparator/comparator.service";
import {PersonalDataStoreModel} from "@app/shared/services/personal-data/personal-data-store.model";
import {Consent} from "@app/shared/services/consent/consent.model";

@Component({
  selector: 'app-consent-list',
  templateUrl: './consent-list.component.html',
  styleUrls: ['./consent-list.component.scss']
})
export class ConsentListComponent {

  @Input() consents: Consent[];
  @Input() id: string;
  @Input() allConsentsSelected: boolean;
  @Input() declaredVehicleOwner: VehicleOwnerDetailsModel;
  @Input() vehicleOwnersDetails: PersonalDataStoreModel[];

  constructor(public comparatorService: ComparatorService) {
  }

  onAllConsentsSelectedChange() {
    this.consents.forEach(consent => consent.selected = this.allConsentsSelected);
  }

  onConsentSelectChange() {
    this.allConsentsSelected = this.consents.every(consent => consent.selected === true);
  }

  isVehicleOwnerConsent(consent: Consent): boolean {
    return consent.code === 'CAR_OWNER_STATEMENT';
  }

}
