import { EntryLocationTagModel } from "@app/shared/services/gtm/tag/entry-location-tag";

export class EntryLocationTagBuilder {

  private readonly tag: EntryLocationTagModel;

  private constructor() {
    this.tag = new EntryLocationTagModel();
    this.tag.event = 'entryLocationEstablished';
  }

  public static builder(): EntryLocationTagBuilder {
    return new EntryLocationTagBuilder();
  }

  public entryLocation(entryLocation: string): EntryLocationTagBuilder {
    this.tag.entryLocation = entryLocation;
    return this;
  }

  public build(): EntryLocationTagModel {
    return this.tag;
  }

}
