import {FormFieldTagModel} from "@app/shared/services/gtm/tag/form-field-tag.model";

export class FormFieldClickTagBuilder {

  private readonly tag: FormFieldTagModel;

  constructor() {
    this.tag = new FormFieldTagModel();
    this.tag.event = 'formFieldClick';
  }

  public static builder(): FormFieldClickTagBuilder {
    return new FormFieldClickTagBuilder();
  }

  public formFieldName(formFieldName: string): FormFieldClickTagBuilder {
    this.tag.formFieldName = formFieldName;
    return this;
  }

  public build(): FormFieldTagModel {
    return this.tag;
  }

}
