import {Component, Input, ViewChild} from '@angular/core';
import {NgModel} from '@angular/forms';
import {EmailModel} from '@app/shared/components/email/email.model';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html'
})
export class EmailComponent {
  @Input() modelRef: EmailModel;
  @Input() requiredField: boolean;
  @Input() redStar: boolean;
  @Input() id: string;
  @ViewChild('email', { static: true }) email: NgModel;
}
