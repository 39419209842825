import {CallbackBusinessType} from "@app/shared/components/callback/callback-business-type.model";

export class CallbackModel {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  agreement: string;
  calculationId: string;
  businessType: CallbackBusinessType;
}
