<div class="dialog-close-container"
     *ngIf="!!dialogRef?.componentInstance">
    <mat-icon (click)="onDialogClose()"
              svgIcon="close-purple"
              class="dialog-video-close-icon">
    </mat-icon>
</div>
<div class="video-container" *ngIf="!showCalendarForm && !showConsultantForm">
    <div *ngIf="!isConsultantWorkTime" class="row pt-lg-5">
        <div class="col-12 d-flex justify-content-center">
            <mat-icon class="video-consultant-icon" svgIcon="widget-consultant-not-available"></mat-icon>
        </div>
        <div class="col-12">
            <p class="text video-desc">
                Jesteśmy poza godzinami pracy.<br />
                Wracamy:<br />
                <strong>Pon - Pt</strong><br />
                <strong>9:00 - 20:00</strong>
            </p>
        </div>
    </div>
    <div class="row pt-lg-5" *ngIf="isConsultantWorkTime && isConsultantAvailable">
        <div class="col-12 d-flex justify-content-center">
            <mat-icon class="video-consultant-icon" svgIcon="video-consultant"></mat-icon>
        </div>
        <div class="col-12">
            <p class="text video-desc">
                <strong>Połącz się</strong> bezpośrednio<br /> <strong>z naszym Doradcą</strong>
            </p>
        </div>
        <div class="col-12 d-flex justify-content-center">
            <button type="button"
                    class="w-100 mobile-button"
                    (click)="onShowConsultantForm()"
                    mat-button>
                Połącz wideo
            </button>
        </div>
    </div>
    <div class="row pb-lg-5 pt-5">
        <div class="col-12 d-flex justify-content-center">
            <mat-icon class="video-calendar-icon" svgIcon="video-calendar"></mat-icon>
        </div>
        <div class="col-12">
            <p class="text video-desc">
                <strong>Umów się</strong> z naszym Doradcą<br /> <strong>w dogodnym dla Ciebie terminie</strong>
            </p>
        </div>
        <div class="col-12 d-flex justify-content-center">
            <button type="button"
                    class="w-100 mobile-button"
                    (click)="onShowCalendarForm()"
                    mat-button>
                Zamów wideo rozmowę
            </button>
        </div>
    </div>
</div>
<app-video-consultant-form *ngIf="showConsultantForm"
                           (close)="onClose()"
                           (back)="onBackConsultantForm()">
</app-video-consultant-form>
<app-video-calendar-form *ngIf="showCalendarForm"
                         (back)="onBackCalendarForm()">
</app-video-calendar-form>

