import {DictionaryItem} from '@app/shared/services/dictionary/dictionary-item.model';
import {OfferStep, Step} from '@app/shared/models/step/step.model';
import {PersonalDataType} from "@app/shared/services/personal-data/personal-data-store.model";

export class VehicleOwnerModel {
  ownersCount: number;
  youngOwner: boolean;
  youngestOwnerAge: DictionaryItem;
  mainUser: PersonalDataType;
  insurer: PersonalDataType = PersonalDataType.OWNER;
  vehicleOwnerDetailsSteps: Step[];
  vehicleOwnerAdditionalDetailsSteps: OfferStep[];
}
