import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateTimePickerService {

  currentSelectDate: Date;
  selectedCampaignId: number;

}
