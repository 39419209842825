import {Directive, ElementRef, HostListener} from '@angular/core';
import {ScrollToFirstInvalidService} from "@app/shared/directives/scroll-to-invalid/scroll-to-invalid.service";

@Directive({
    selector: '[scrollToFirstInvalid]'
})
export class ScrollToFirstInvalidDirective {

  constructor(private el: ElementRef,
              private scrollToFirstInvalidService: ScrollToFirstInvalidService) {
  }

  @HostListener('submit', ['$event'])
  onSubmit(event) {
    event.preventDefault();

    const firstInvalidElement  = this.el.nativeElement.querySelector(".ng-invalid, .mat-form-field-invalid");
    const firstInputInvalid = firstInvalidElement?.querySelector('input[type="text"]');

    if (firstInputInvalid) {
      firstInputInvalid.focus();
    }

    return this.scrollToFirstInvalidService.scrollToElement(firstInvalidElement);
  }
}
