import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'titleUpperCaseFormatter'
})
export class TitleUpperCaseFormatterPipe implements PipeTransform {

  transform(value: any): any {
    try {
      if (!value) {
        return '';
      } else {
        return this.format(value.toLowerCase(), ['-', ' ']);
      }
    } catch (e) {
      return null;
    }
  }

  format(value: string, separators: string[]): string {
    separators.forEach(separator => {
      value = value
        .split(separator)
        .map((value: string) => this.upperFirst(value))
        .join(separator);
    });

    return value.trim();
  }

  upperFirst(value: string): string {
    return value.slice(0, 1).toUpperCase() + value.slice(1);
  }

}
