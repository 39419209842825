<div provideParentForm>
  <mat-form-field hideRequiredMarker="true" appearance="fill">
    <mat-label>Numer telefonu <span class="required-star" *ngIf="redStar">*</span></mat-label>
    <div role="group" class="d-flex">
      <span class="phone-input-prefix">{{ phoneNumberValidation.prefix }}</span>
      <input id="phoneNumber-{{ id }}"
             name="phoneNumber-{{ id }}"
             #phoneNumber="ngModel"
             matInput
             pattern="[0-9]*"
             inputmode="numeric"
             type="tel"
             [ngModel]="modelRef.phoneNumber"
             [minlength]="phoneNumberValidation.minlength"
             [maxlength]="phoneNumberValidation.maxlength"
             [mask]="phoneNumberValidation.mask"
             [patterns]="phoneNumberValidation.patterns"
             [required]="requiredField"
             (ngModelChange)="modelRef.phoneNumber = $event"
             (focus)="onFocus()"
             (focusout)="onFocusOut()">
    </div>
    <mat-error>
      <validation-messages [for]="phoneNumber">
        <validation-message name="minlength">
          Minimalna liczba znaków wynosi: 9
        </validation-message>
      </validation-messages>
    </mat-error>
  </mat-form-field>
</div>
