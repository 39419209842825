import { DictionaryItem } from '@app/shared/services/dictionary/dictionary-item.model';
import { VehicleModelVersionModel } from '@app/calculate/vehicle/vehicle-model-version.model';
import { VehicleModelModel } from '@app/calculate/vehicle/vehicle-model.model';

export class VehicleModel {
  type: VehicleType;
  productionYear: number;
  brand: DictionaryItem;
  model: VehicleModelModel;
  fuelType: DictionaryItem;
  bodyType: DictionaryItem;
  engineCapacity: DictionaryItem;
  version: VehicleModelVersionModel;
  insuranceType: InsuranceType;
  licensePlate: string;
}

export enum InsuranceType {
  OC = 'OC',
  OC_AND_AC = 'OC_AND_AC'
}

export enum VehicleType {
  PASSENGER_OR_OFFROAD_VEHICLE = 'PASSENGER_OR_OFFROAD_VEHICLE',
  DELIVERY_VEHICLE = 'DELIVERY_VEHICLE'
}

export enum FuelType {
  "FuelType-1" = "Benzyna",
  "FuelType-2" = "Diesel",
  "FuelType-3" = "Gaz",
  "FuelType-4" = "Prąd elektryczny",
  "FuelType-5" = "Benzyna-gaz",
  "FuelType-6" = "Diesel-gaz",
  "FuelType-7" = "Brak",
  "FuelType-8" = "Hybryda-gaz",
  "FuelType-999" = "Hybryda",
  "FuelType-1000" = "Hybryda-diesel",
  "FuelType-1001" = "Wodór",
}
