<div role="group"
     class="date-input-container"
     [formGroup]="parts"
     [attr.aria-labelledby]="_formField.getLabelId()"
     (focusin)="onFocusIn($event)"
     (focusout)="onFocusOut($event)">
  <input class="date-input-element"
         formControlName="day"
         name="day"
         id="day"
         size="2"
         placeholder="DD"
         pattern="[0-9]{2}"
         inputmode="numeric"
         [maxlength]="DAY_VALIDATION.maxlength"
         [mask]="DAY_VALIDATION.mask"
         [patterns]="DAY_VALIDATION.patterns"
         (input)="_handleInput()"
         (keyup)="autoFocusNext($event, parts.controls.day, month)"
         (focusout)="autoComplete(parts.controls.day)"
         #day>
  <span class="date-input-spacer">&ndash;</span>
  <input class="date-input-element"
         formControlName="month"
         name="month"
         id="month"
         size="2"
         placeholder="MM"
         pattern="[0-9]{2}"
         inputmode="numeric"
         [maxlength]="MONTH_VALIDATION.maxlength"
         [mask]="MONTH_VALIDATION.mask"
         [patterns]="MONTH_VALIDATION.patterns"
         (input)="_handleInput()"
         (keyup)="autoFocusNext($event, parts.controls.month, year)"
         (keyup.backspace)="autoFocusPrev(parts.controls.month, day)"
         (focusout)="autoComplete(parts.controls.month)"
         #month>
  <span class="date-input-spacer">&ndash;</span>
  <input class="date-input-element"
         formControlName="year"
         name="year"
         id="year"
         size="4"
         placeholder="RRRR"
         pattern="[0-9]{4}"
         inputmode="numeric"
         [maxlength]="YEAR_VALIDATION.maxlength"
         [mask]="YEAR_VALIDATION.mask"
         [patterns]="YEAR_VALIDATION.patterns"
         (input)="_handleInput()"
         (keyup.backspace)="autoFocusPrev(parts.controls.year, month)"
         #year>
</div>