import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { API_URL } from "@app/shared/constants/settings.constant";
import { PreparePaymentRequestModel } from "@app/sell/payment/prepare-payment-request.model";
import { PreparePaymentResponseModel } from "@app/sell/payment/prepare-payment-response.model";
import { PaymentStatusModel } from "@app/sell/payment/payment-status.model";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class PaymentService {

  constructor(private httpClient: HttpClient) {
  }

  preparePayment(preparePaymentRequest: PreparePaymentRequestModel): Observable<PreparePaymentResponseModel> {
    return this.httpClient.post<PreparePaymentResponseModel>(`${API_URL}/payment`, preparePaymentRequest);
  }

  getPaymentStatus(policyNumber: string): Observable<PaymentStatusModel> {
    return this.httpClient.get<PaymentStatusModel>(`${API_URL}/payment/${policyNumber}`);
  }

}
