import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {LAST_NAME_VALIDATION} from '@app/shared/constants/settings.constant';
import {TitleUpperCaseFormatterPipe} from '@app/shared/pipes/title-upper-case-formatter.pipe';
import {NgModel} from '@angular/forms';
import {LastNameModel} from '@app/shared/components/last-name/last-name.model';

@Component({
  selector: 'app-last-name',
  templateUrl: './last-name.component.html'
})
export class LastNameComponent {

  @Input() modelRef: LastNameModel;
  @Input() requiredField: boolean;
  @Input() redStar: boolean;
  @Input() id: string;
  @Output() focusEvent = new EventEmitter<NgModel>();
  @Output() focusoutEvent = new EventEmitter<NgModel>();
  @ViewChild('lastName', { static: true }) lastName: NgModel;

  lastNameValidation = LAST_NAME_VALIDATION;

  constructor(private titleUpperCaseFormatter: TitleUpperCaseFormatterPipe) {
  }

  public onBlur($event) {
    this.modelRef.lastName = this.titleUpperCaseFormatter.transform($event.target.value);
  }

  public onPaste($event) {
    this.modelRef.lastName = this.titleUpperCaseFormatter.transform($event.target.value);
  }

  public onFocus() {
    this.focusEvent.next(this.lastName);
  }

  public onFocusOut() {
    this.focusoutEvent.next(this.lastName);
  }

  public setLastName(value: string) {
    this.modelRef.lastName = this.titleUpperCaseFormatter.transform(value);
  }
}
