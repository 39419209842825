// ASSISTANCE
export const OPTION_ASS_OC_DEFAULT = 2000341;
export const OPTION_ASS_OC_PLUS = 4005710;
export const OPTION_ASS_AC_DEFAULT = 2000342;
export const OPTION_ASS_AC_PLUS = 4005709;
export const OPTION_ASS_AC_COMFORT = 2001553;

// AC
export const OPTION_AMORTIZATION_ABOLISHED = 2000016;
export const OPTION_AMORTIZATION_NOT_ABOLISHED = 2001836;
export const OPTION_REPAIR_SHOP_NOT_ESTIMATE = 2000053;
export const OPTION_REPAIR_SHOP_ESTIMATE = 2000019;
export const OPTION_REPAIR_SHOP_NOT_ESTIMATE_CONST_SUM = 2000049;
export const OPTION_REPAIR_SHOP_ESTIMATE_CONST_SUM = 2000052;
export const OPTION_STAFF_ORIGINAL = 2002676;
export const OPTION_STAFF_ALTERNATIVE = 2002678;
export const OPTION_NEW_REPAIR_SHOP_NOT_CONST = 4006042;
export const OPTION_NEW_REPAIR_SHOP_CONST = 4006043;

// SMART
export const OPTION_SMART_THEFT = 2001026;
export const OPTION_SMART_NATURE = 2002163;
export const OPTION_SMART_THEFT_NATURE = 2002160;
export const OPTION_SMART_DAMAGE_NATURE = 2002161;
export const OPTION_SMART_DAMAGE_THEFT_NATURE = 2002162;