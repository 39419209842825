<form id="callbackForm"
      name="callbackForm"
      (submit)="send(callbackForm)"
      #callbackForm="ngForm"
      [ngClass]="callbackForm.submitted ? 'callback-form-submitted' : ''"
      scrollToFirstInvalid>
    <div class="help-page">
        <mat-dialog-content class="container">
            <div class="col-md-12">
                <div class="row justify-content-end">
                    <div class="help-close-icon">
                        <mat-icon (click)="closeIconDialog()">close</mat-icon>
                    </div>
                </div>
                <div class="row d-lg-none"
                     [ngClass]="{'d-none' : !isCallCenterWorkTime}">
                    <h2 class="page-title text m-t-30">
                        <strong>Chcesz skontaktować się</strong> z doradcą telefonicznie? Zadzwoń
                    </h2>
                    <a class="pink-button" href="tel:+48224446768">
                      <img src="./assets/images/telefon_57x78.png" class="phone-icon" width="19" height="26" alt="telefon">
                      22 444 67 68
                    </a>
                    <h2 class="page-title text m-t-30" [ngClass]="{'d-none' : showFormMobile}">
                        Wolisz, żeby nasz <strong>doradca zadzwonił</strong> do Ciebie?
                    </h2>
                    <a class="pink-button" [ngClass]="{'d-none' : showFormMobile}" (click)="leavePhoneOnMobile($event)" href="#">
                      Zostaw numer
                    </a>
                </div>
                <div class="d-none d-lg-block"
                     [ngClass]="{'d-block' : showFormMobile || !isCallCenterWorkTime }">
                    <h2 [className]="callbackTitleClass"
                        [innerHTML]="callbackTitle"></h2>
                    <div class="justify-content-center">
                        <div class="col-md-12">
                            <div class="form-field">
                                <app-first-name id="callback-first-name"
                                                [modelRef]="callback"
                                                [requiredField]="true"
                                                [autoFocus]="false"
                                                (focusEvent)="callbackFieldFocusIn($event)"
                                                (focusoutEvent)="callbackFieldFocusOut($event)">
                                </app-first-name>
                            </div>
                            <div class="form-field">
                                <app-last-name id="callback-last-name"
                                               [modelRef]="callback"
                                               [requiredField]="true"
                                               (focusEvent)="callbackFieldFocusIn($event)"
                                               (focusoutEvent)="callbackFieldFocusOut($event)">
                                </app-last-name>
                            </div>
                            <div class="form-field">
                                <app-phone id="callback-phone"
                                           [modelRef]="callback"
                                           [requiredField]="true"
                                           (focusEvent)="callbackFieldFocusIn($event)"
                                           (focusoutEvent)="callbackFieldFocusOut($event)">
                                </app-phone>
                            </div>
                            <mat-checkbox id="agreement"
                                          name="agreement"
                                          class="m-b-15"
                                          #agreement="ngModel"
                                          [(ngModel)]="callback.agreement"
                                          (change)="onConsentChange($event)"
                                          [required]="true">
                                <span *ngIf="consent" [innerHTML]="consent.text"></span>
                            </mat-checkbox>
                        </div>
                    </div>
                    <div *ngIf="callbackForm.invalid && callbackForm.submitted"
                        class="row invalid-submitted-form-text invalid-help-page justify-content-center">
                        Wypełnij wymagane pola!
                    </div>
                </div>
            </div>
        </mat-dialog-content>
        <div class="d-none d-lg-block" [ngClass]="{'d-block' : showFormMobile || !isCallCenterWorkTime }">
            <mat-dialog-actions>
                <div class="help-next-button">
                    <button #submitButton class="next-button" mat-button [customAutoFocus]="isNextButtonAutoFocus()">
                        WYŚLIJ
                        <mat-icon svgIcon="arrow-right"></mat-icon>
                    </button>
                </div>
            </mat-dialog-actions>
        </div>
    </div>
</form>
