import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {NavigationEnd, Router} from '@angular/router';
import {OfferStep, Step} from '@app/shared/models/step/step.model';
import {StepResolver} from '@app/shared/services/url/step-resolver';
import {filter} from 'rxjs/operators';

@Component({
  selector: 'app-back',
  templateUrl: './back.component.html',
  styleUrls: ['back.component.scss']
})
export class BackComponent implements OnInit {

  show: boolean;
  customWidth: boolean;

  private static readonly mainPageUrl = [''];
  private readonly firstSteps: Set<string>;
  private readonly visibleForSteps: Set<string>;


  constructor(private location: Location,
              private router: Router) {
    this.firstSteps = new Set([Step.SHORT_PATH, Step.VEHICLE]);

    this.visibleForSteps = new Set<string>();
    this.visibleForSteps.add(Step.SHORT_PATH);
    this.visibleForSteps.add(Step.VEHICLE);
    this.visibleForSteps.add(Step.VEHICLE_ADDITIONAL_INFO);
    this.visibleForSteps.add(Step.VEHICLE_OWNER);
    this.visibleForSteps.add(Step.VEHICLE_OWNER_DETAILS);
    this.visibleForSteps.add(Step.VEHICLE_FIRST_CO_OWNER_DETAILS);
    this.visibleForSteps.add(Step.VEHICLE_SECOND_CO_OWNER_DETAILS);
    this.visibleForSteps.add(Step.INSURANCE_START_DATE);
    this.visibleForSteps.add(OfferStep.OMNI_OFFER);
    this.visibleForSteps.add(OfferStep.OMNI_ADDITIONAL_OPTION);
    this.visibleForSteps.add(OfferStep.INSURANCE_VARIANT);
    this.visibleForSteps.add(OfferStep.INSURANCE_ADDITIONAL_OPTION);
    this.visibleForSteps.add(OfferStep.YOUR_OFFER);
    this.visibleForSteps.add(OfferStep.INSURANCE_TOTAL_PRICE);
    this.visibleForSteps.add(OfferStep.VEHICLE_REGISTRATION_DATA);
    this.visibleForSteps.add(OfferStep.VEHICLE_OWNER_ADDITIONAL_DETAILS);
    this.visibleForSteps.add(OfferStep.VEHICLE_FIRST_CO_OWNER_ADDITIONAL_DETAILS);
    this.visibleForSteps.add(OfferStep.VEHICLE_SECOND_CO_OWNER_ADDITIONAL_DETAILS);
    this.visibleForSteps.add(OfferStep.VEHICLE_FIRST_CO_OWNER_ADDITIONAL_ADDRESS);
    this.visibleForSteps.add(OfferStep.VEHICLE_SECOND_CO_OWNER_ADDITIONAL_ADDRESS);
    this.visibleForSteps.add(OfferStep.SHORT_PATH_DETAILS);
    this.visibleForSteps.add(OfferStep.CONTACT_AND_CONSENT);
  }

  ngOnInit() {
    this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.refreshView();
    });

    this.refreshView();
  }

  refreshView() {
    this.show = this.resolveComponentVisibility();
    this.customWidth = this.resolveCustomWidth();
  }

  back() {
    if (this.isFirstStep()) {
      this.router.navigate(BackComponent.mainPageUrl);;
    } else {
      this.location.back();
    }
  }

  private isFirstStep() {
    const step = StepResolver.getCurrentStepName(this.router.url);
    return this.firstSteps.has(step);
  }

  private resolveComponentVisibility() {
    const step = StepResolver.getCurrentStepName(this.router.url);
    return this.visibleForSteps.has(step);
  }

  private resolveCustomWidth() {
    const currentStep = <OfferStep>StepResolver.getCurrentStepName(this.router.url);
    const steps = [
      OfferStep.OMNI_OFFER,
      OfferStep.OMNI_ADDITIONAL_OPTION,
      OfferStep.INSURANCE_ADDITIONAL_OPTION,
      OfferStep.INSURANCE_VARIANT,
    ];

    return steps.includes(currentStep);
  }

}
