<div class="text">
    <mat-icon class="video-consultant-icon" svgIcon="video-consultant"></mat-icon>
    <p class="fs-18 mb-3">
        <strong>Uzupełnij krótki formularz</strong>, aby<br /> połączyć się z Doradcą
    </p>
</div>
<form id="videoFormConsultant"
      name="videoFormConsultant"
      (submit)="send(videoFormConsultant)"
      #videoFormConsultant="ngForm"
      [ngClass]="videoFormConsultant.submitted ? 'submitted' : ''">
    <div class="col-md-12">
        <div>
            <div class="justify-content-center">
                <div class="col-md-12">
                    <div class="form-field">
                        <app-first-name id="video-consultant-first-name"
                                        [modelRef]="videoConsultantForm"
                                        [requiredField]="true"
                                        [redStar]="true"
                                        [autoFocus]="false">
                        </app-first-name>
                    </div>
                    <div class="form-field">
                        <mat-form-field hideRequiredMarker="true"
                                        appearance="fill">
                            <mat-label>Temat rozmowy: <span class="required-star">*</span></mat-label>
                            <mat-select id="subject-question-consultant"
                                        name="subject-question-consultant"
                                        [required]="true"
                                        [(ngModel)]="videoConsultantForm.queueName"
                                        disableOptionCentering>
                                <mat-option *ngFor="let campaign of availableCampaigns | filterByField:'availableConsultant':false"
                                            [value]="campaign.queueName">
                                    {{ campaign.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <mat-checkbox id="video-consultant-agreement"
                                  name="video-consultant-agreement"
                                  class="m-b-15"
                                  #agreement="ngModel"
                                  [(ngModel)]="videoConsultantForm.agreement"
                                  [required]="true">
                        <span *ngIf="consent" [innerHTML]="consent.text"></span>
                    </mat-checkbox>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-12">
        <div class="justify-content-center d-flex col-md-12">
            <button #submitButton class="next-button w-100 p-0" mat-button>
                Rozpocznij wideo
            </button>
        </div>
        <div class="justify-content-center d-flex col-md-12">
            <a class="video-back-button mt-4" (click)="onBack()">
                Wróć
            </a>
        </div>
    </div>
</form>
