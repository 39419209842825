import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {SessionExpirationService} from '@app/shared/components/session/session-expiration.service';
import {UrlParamsStoreService} from '@app/shared/interceptors/partner/url-params-store.service';
import {CalculationStoreService} from "@app/calculate/calculate/calculation-store.service";
import {RequestStoreService} from "@app/shared/services/request-store/request-store.service";
import {PersonalDataStoreService} from "@app/shared/services/personal-data/personal-data-store.service";

@Component({
  selector: 'app-session-expired',
  templateUrl: './session-expired.component.html'
})
export class SessionExpiredComponent implements OnInit, OnDestroy {

  constructor(private router: Router,
              private sessionExpirationService: SessionExpirationService,
              private urlParamsStoreService: UrlParamsStoreService,
              private calculationStoreService: CalculationStoreService,
              private requestStoreService: RequestStoreService,
              private personalDataStoreService: PersonalDataStoreService,) {
    this.sessionExpirationService.disable = true;
  }

  ngOnInit() {
    this.calculationStoreService.clear();
    this.requestStoreService.clear();
    this.personalDataStoreService.clear();
  }

  onBackToFormClick() {
    this.router.navigate([''], {
      queryParams: this.urlParamsStoreService.asParams(),
      queryParamsHandling: 'merge'
    });
  }

  ngOnDestroy(): void {
    this.sessionExpirationService.disable = false;
  }
}
