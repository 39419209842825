import {Injectable} from '@angular/core';

@Injectable()
export class GeneratorService {

  constructor() {
  }

  generateCalculationId(): string {
    const date = (new Date()).getTime();
    const random = Math.floor(Math.random() * 1000000000) + 1000000000;

    return `${date}${random}`;
  }

}
