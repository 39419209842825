export class ProductModel {
  id: string;
  name: string;
  price: string;
  brand: string;
  category: string;
  variant: string;
  list: string;
  position: number;
  quantity: number;
}
