import {Pipe, PipeTransform} from '@angular/core';
import {CoverService} from "@app/shared/services/cover/cover.service";
import {CoverModel} from "@app/calculate/calculate/models/insurance-variant.model";

@Pipe({
  name: 'hideCoversInAllProcess'
})
export class HideCoversInAllProcessPipe implements PipeTransform {

  constructor(
    private readonly coverService: CoverService
  ) {}

  transform(value?: CoverModel[]): CoverModel[] {
     return this.coverService.filterInAllProcess(value || []);
  }

}
