import { Directive } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

export function FormProviderFactory(form: NgForm) {
  return form;
}

@Directive({
  selector: '[provideParentForm]',
  providers: [{
    provide: ControlContainer,
    useFactory: FormProviderFactory,
    deps: [NgForm]
  }]
})
export class ProvideParentFormDirective {}
