<mat-card class="outer-card">
    <mat-card-content class="container">
        <div class="offset-md-1 col-md-10">
            <div class="row m-t-50"></div>
            <div class="row justify-content-center">
                <div class="text text-big text-bold">Twoja sesja wygasła.</div>
            </div>
            <div class="row justify-content-center">
                <div class="text text-medium">
                  Prosimy o ponowne wypełnienie formularza.
                </div>
            </div>
            <div class="row m-t-50"></div>
        </div>
    </mat-card-content>
</mat-card>
<div class="up-next-button pt-4 pb-4">
    <div class="flex-center-center">
        <button mat-button class="next-button" (click)="onBackToFormClick()">
            Powrót
            <mat-icon svgIcon="arrow-right"></mat-icon>
        </button>
    </div>
</div>
