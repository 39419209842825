export class FuelTypeMapper {

  private static readonly FUEL_TYPE_MAP: Map<number, string> = new Map([
    [1, 'Benzyna'],
    [2, 'Diesel'],
    [3, 'Pozostałe'],
    [4, 'Prąd elektryczny'],
    [999, 'Hybryda'],
    [1000, 'Hybryda-diesel'],
  ]);

  public static map(fuelType: number): string {
    return FuelTypeMapper.FUEL_TYPE_MAP.get(fuelType);
  }

}
