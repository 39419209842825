import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Consent} from "@app/shared/services/consent/consent.model";
import {NgForm} from "@angular/forms";
import {VideoCalendarFormModel} from "@app/shared/components/video/video-calendar-form/video-calendar-form.model";
import {VideoService} from "@app/shared/components/video/video.service";
import {catchError, switchMap, tap} from "rxjs/operators";
import * as dayjs from "dayjs";
import {of} from "rxjs";
import {ConfigService} from "@app/shared/services/config/config.service";
import {AlfavoxCalendarConfigModel} from "@app/shared/services/config/video-call.model";


@Component({
  selector: 'app-video-calendar-form',
  templateUrl: './video-calendar-form.component.html',
  styleUrls: ['./video-calendar-form.component.scss'],
})
export class VideoCalendarFormComponent implements OnInit {

  @Output() back: EventEmitter<boolean> = new EventEmitter<boolean>();
  videoCalendarForm: VideoCalendarFormModel;
  consent: Consent;
  minDate: Date;
  maxDate: Date;
  startAtDate: Date;
  successSend: boolean;
  errorSend: boolean;
  availableCampaigns: AlfavoxCalendarConfigModel[];

  constructor(private videoService: VideoService,
              private configService: ConfigService) {
    this.videoCalendarForm = new VideoCalendarFormModel();
    this.availableCampaigns = this.configService.alfavoxCalendarCampaigns;
  }

  ngOnInit() {
    const currentDate = new Date();

    this.startAtDate = dayjs(currentDate).toDate();
    this.minDate = dayjs(currentDate).toDate();
    this.maxDate = dayjs(currentDate).add(7, 'day').toDate();
    this.consent = {
      code: '-',
      additionalText: '',
      required: true,
      text: 'Zapoznałam/em się z "Informacją o przetwarzaniu danych osobowych". <a class="text-decoration-none" target="_blank" href="https://www.link4.pl/sites/default/files/link4_obowiazek_informacyjny_wideorozmowy.pdf"><strong>Pobierz informację o przetwarzaniu Twoich danych osobowych</strong></a>'
    };
  }

  send(form: NgForm) {
    if (form.invalid) {
      return;
    }

    const campaignId = this.videoCalendarForm.campaignId;
    const subject = this.availableCampaigns.filter(c => c.id === campaignId)[0].name;
    const firstName = this.videoCalendarForm.firstName;
    const lastName = this.videoCalendarForm.lastName;
    const email = this.videoCalendarForm.email;
    const date = this.videoCalendarForm.date;

    this.videoService.meetingBook(subject, date, campaignId).pipe(
      switchMap(r => this.videoService.meetingConfirm(r.meetingId, firstName, lastName, email)),
      tap(_ => this.successSend = true),
      catchError(err => {
        this.errorSend = true;
        return of(err);
      })
    ).subscribe();
  }

  onBack() {
    this.back.emit(true);
  }

}
