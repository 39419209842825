import {Component, Input, OnInit, ViewEncapsulation} from "@angular/core";
import { CalculationPlateDataModel } from "@app/shared/components/calculation-plate/calculation-plate-data.model";
import {OfferStep} from "@app/shared/models/step/step.model";
import {Router} from "@angular/router";
import { CalculationStoreService } from "@app/calculate/calculate/calculation-store.service";
import * as dayjs from 'dayjs';
import { StepResolver } from "@app/shared/services/url/step-resolver";
import {FeatureFlagService} from "@app/shared/services/feature-flag/feature-flag.service";

@Component({
  selector: 'calculation-plate',
  templateUrl: './calculation-plate.component.html',
  styleUrls: ['./calculation-plate.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CalculationPlateComponent implements OnInit {

  @Input() showAdditionalInfo: boolean;

  showComponent: boolean;

  private readonly visibleForSteps : Set<string>;

  data: CalculationPlateDataModel;

  constructor(private router: Router,
              private featureFlagService:FeatureFlagService,
              private storeService: CalculationStoreService) {
    this.visibleForSteps = new Set<string>();
    this.visibleForSteps.add(OfferStep.VEHICLE_REGISTRATION_DATA);
    this.visibleForSteps.add(OfferStep.VEHICLE_OWNER_ADDITIONAL_DETAILS);
    this.visibleForSteps.add(OfferStep.VEHICLE_FIRST_CO_OWNER_ADDITIONAL_DETAILS);
    this.visibleForSteps.add(OfferStep.VEHICLE_SECOND_CO_OWNER_ADDITIONAL_DETAILS);
    this.visibleForSteps.add(OfferStep.VEHICLE_OWNER_ADDITIONAL_ADDRESS);
    this.visibleForSteps.add(OfferStep.VEHICLE_FIRST_CO_OWNER_ADDITIONAL_ADDRESS);
    this.visibleForSteps.add(OfferStep.VEHICLE_SECOND_CO_OWNER_ADDITIONAL_ADDRESS);

    this.data = new CalculationPlateDataModel();
  }

  ngOnInit() {
    this.showComponent = this.resolveVisibility(this.visibleForSteps);

    if (this.showComponent || this.showAdditionalInfo) {
      this.updateData();
    }
  }

  private updateData() {
    const insuranceVariant = this.storeService.getInsuranceVariant();
    const additionalInsuranceVariant = this.storeService.getAdditionalInsuranceVariant();
    const additionalOptions = this.storeService.getAdditionalSelectedCovers();
    const insuranceStartDate = this.storeService.getInsuranceStartDate();

    if (insuranceVariant && additionalInsuranceVariant && additionalInsuranceVariant.installment) {
      const installment = additionalInsuranceVariant.installment;
      const { count, price } = installment;
      this.data.variant = insuranceVariant.name;
      this.data.price = this.getPrice(count, price);
      this.data.covers = insuranceVariant.covers;
      this.data.additionalCovers = additionalOptions;
      this.data.startDate = insuranceStartDate.startDate;
      this.data.endDate = dayjs(insuranceStartDate.startDate)
        .add(1, 'year')
        .add(-1, 'day')
        .toDate();
      this.data.installmentCount = this.getInstallmentCount(count);
    }
  }

  private getPrice(count: number, price: number): string {
    const currencyPL = 'zł';

    if(count < 2) {
      return `${price.toFixed(2).toString()} ${currencyPL}`;
    } else {
      return `${count} x ${price} ${currencyPL}`;
    }
  }

  private getInstallmentCount(installmentCount: number): string {
    if (installmentCount == 1) {
      return `${installmentCount} rata`;
    }

    if (installmentCount >= 2 && installmentCount <= 4) {
      return `${installmentCount} raty`;
    }

    return `${installmentCount} rat`;
  }

  private resolveVisibility(steps: Set<string>): boolean {
    const step = StepResolver.getCurrentStepName(this.router.url);
    return steps.has(step);
  }

}
