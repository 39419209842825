export const API_URL = './api';
export const OMNI_PARTNER_ID = '888';
export const DATE_FORMAT = 'DD-MM-YYYY';
export const FIRST_NAME_REGEXP = '^[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ\-]{0,20}$';
export const LAST_NAME_REGEXP = '^[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ\-\\s]{0,50}$';
export const PREVIOUS_LAST_NAME_REGEXP = LAST_NAME_REGEXP;
export const COMMON_BRANDS_GROUP_INDEX = 1;
export const ALPHANUMERIC_REGEXP = '^[A-Za-z0-9]+$';
export const FIRST_CHARACTER_LETTER = /^[a-zA-Z].*/;
export const EMAIL_REGEXP = /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
export const MAX_OC_VEHICLE_AGE = 30;
export const MAX_AC_PASSENGER_VEHICLE_AGE_NEW = 19;
export const MAX_AC_DELIVERY_VEHICLE_AGE = 8;
export const MAX_USER_AGE = 100;
export const PESEL_LENGTH = 11;
export const ELIGIBLE_VEHICLE_USAGES = ['1', '7'];
export const FUEL_TYPE_PETROL_KEY = 1;
export const FIRST_NAME_VALIDATION = {maxlength: '20', pattern: FIRST_NAME_REGEXP};
export const LAST_NAME_VALIDATION = {minlength: '3', maxlength: '50', pattern: LAST_NAME_REGEXP};
export const PREVIOUS_LAST_NAME_VALIDATION = {minlength: '3', maxlength: '50', pattern: PREVIOUS_LAST_NAME_REGEXP};
export const HOME_NUMBER_VALIDATION = {length: '10', pattern: '^[a-zA-Z0-9/\\\\-]{0,10}$'};
export const FLAT_NUMBER_VALIDATION = HOME_NUMBER_VALIDATION;
export const STREET_VALIDATION = {
  length: '255',
  pattern: '^[A-Za-z0-9À-ž\\-\\\\/\\\\\'\\\\"\\\\,\\\\.\\\\(\\\\)\\s]{0,255}$'
};
export const LARGE_FAMILY_CARD_NUMBER_VALIDATION = {maxlength: '17', pattern: '^[0-9]{0,17}$'};
export const VITAY_CARD_NUMBER_VALIDATION = {maxlength: '16', minlength: '16', pattern: '^[0-9]{0,16}$'};
export const PHONE_NUMBER_VALIDATION = {
  minlength: '9',
  maxlength: '15',
  prefix: '+48 ',
  mask: '100-000-000',
  patterns: {
    '0': {
      pattern: new RegExp('\\d'),
    },
    '1': {
      pattern: new RegExp('[1-9]')
    }
  }
};
export const DAY_VALIDATION = {
  minlength: '2',
  maxlength: '2',
  mask: '00',
  patterns: {
    '0': {
      pattern: /[0-9]/
    }
  }
}
export const MONTH_VALIDATION = {
  minlength: '2',
  maxlength: '2',
  mask: '00',
  patterns: {
    '0': {
      pattern: /[0-9]/
    }
  }
}
export const YEAR_VALIDATION = {
  minlength: '4',
  maxlength: '4',
  mask: '1200',
  patterns: {
    '0': {
      pattern: /[0-9]/
    },
    '1': {
      pattern: /[1-2]/
    },
    '2': {
      pattern: /[0,9]/
    }
  }
}
