import {Component, Input, ViewChild} from '@angular/core';
import {NgModel} from '@angular/forms';
import {LicensePlateNumberModel} from '@app/shared/components/license-plate-number/license-plate-number.model';

@Component({
  selector: 'app-license-plate-number',
  templateUrl: './license-plate-number.component.html'
})
export class LicensePlateNumberComponent {

  @Input() modelRef: LicensePlateNumberModel;
  @Input() required: boolean = true;
  @Input() disabled: boolean;
  @Input() showTooltip: boolean;
  @ViewChild('licensePlateNumber', { static: true }) licensePlateNumber: NgModel;

  public onBlur($event) {
    this.modelRef.licensePlateNumber = $event.target.value.toUpperCase();
  }

  public onPaste($event) {
    this.modelRef.licensePlateNumber = $event.target.value.toUpperCase();
  }
}
