<mat-sidenav-container>
    <mat-sidenav #slideNavEnd mode="over"
                 position="end"
                 fixedInViewport="true"
                 (closed)="onSlideNavClose($event)"
                 (openedStart)="onSlideNavOpen($event)">
        <mat-icon (click)="slideNavClose()"
                  svgIcon="close-purple"
                  class="slide-close-icon">
        </mat-icon>
        <div class="slide-nav-container">
            <app-callback-form *ngIf="widgetContainer === WidgetsModel.CALLBACK"></app-callback-form>
            <app-video *ngIf="widgetContainer === WidgetsModel.VIDEO" (close)="slideNavClose()"></app-video>
        </div>
    </mat-sidenav>
    <mat-sidenav-content>
        <app-header></app-header>
        <div id="ad-desktop" *ngIf="isLandingPage()">
            <!-- GTM add desktop banner -->
        </div>
        <div class="calculate-middle-container">
            <app-breadcrumb></app-breadcrumb>
            <app-back></app-back>
        </div>
        <div class="calculate-content">
            <router-outlet></router-outlet>
        </div>
        <app-widgets [slideNavEnd]="slideNavEnd"
                     (widgetClick)="widgetContainer = $event">
        </app-widgets>
    </mat-sidenav-content>
</mat-sidenav-container>
