<div provideParentForm>
  <mat-form-field hideRequiredMarker="true" appearance="fill">
    <mat-label>Adres e-mail <span class="required-star" *ngIf="redStar">*</span></mat-label>
    <input id="email-{{ id }}"
           name="email-{{ id }}"
           #email="ngModel"
           matInput
           mail
           trim
           [ngModel]="modelRef.email"
           [maxlength]="50"
           [required]="requiredField"
           (ngModelChange)="modelRef.email = $event">
    <mat-error>
      <validation-messages [for]="email">
        <validation-message name="mail">
          Nieprawidłowy email
        </validation-message>
      </validation-messages>
    </mat-error>
  </mat-form-field>
</div>
