// OC
export const AUTO_ASSISTANCE_OC = 3464;
export const SMART_CASCO_OC = 27061;
export const NNW_OC = 102797;
export const WINDOW_24 = 8086;
export const HELP_AFTER_ACCIDENT = 295267;
export const HELP_PROGRAM = 3466;

// AC
export const AC_THEFT = 3462;
export const AC_AMORTIZATION = 3463;
export const AC_REPAIR_SHOP = 3465;
export const AUTO_ASSISTANCE_AC = 9353;
export const AC_ORIGINAL_STAFF = 120633;
export const NNW_AC = 20218;
export const WINDOW_FOR_AC = 110599;

// OC+AC
export const OC = 3481;
export const PROMOTION_CASH_BACK = 264146;
export const BACK_TO_HEALTH = 282663;
export const HELP_AFTER_THEFT = 286998;
export const PROTECTED_DISCOUNT = 305802;
export const GREEN_CARD = 3467;
export const COVER_KEYS = 39976;
export const OC_ECOCYCLIST = 293041;
export const REPLACEMENT_CAR = 308246;

// zniżki
export const ONLINE_DISCOUNT = 20425;
export const NEW_ONLINE_DISCOUNTS = 308244;
export const SUM_DISCOUNTS = 299054;
export const LAST_DISCOUNTS = 51685;
export const PROMOTIONAL_CODE_DISCOUNT = 75280;
export const KDR_DISCOUNT = 264151;
export const PAYMENT_DISCOUNT = 6140;
export const SELLER_DISCOUNT = 130548;
export const FHH_DISCOUNT = 280831;
export const MARKETING_AUTOMATION_DISCOUNT = 308245;


export const ICON_COVERS = {
  [AUTO_ASSISTANCE_OC]: 'pictogram-assistance',
  [SMART_CASCO_OC]: 'pictogram-smart-casco',
  [NNW_OC]: 'pictogram-nnw',
  [WINDOW_24]: 'pictogram-window',
  [HELP_AFTER_ACCIDENT]: 'pictogram-help-after-accident',
  [AC_THEFT]: 'pictogram-theft',
  [AC_AMORTIZATION]: 'pictogram-ac-accident',
  [AC_REPAIR_SHOP]: 'pictogram-new-ac-repair-shop',
  [AUTO_ASSISTANCE_AC]: 'pictogram-assistance',
  [AC_ORIGINAL_STAFF]: 'pictogram-ac-orginal-staff',
  [NNW_AC]: 'pictogram-nnw',
  [WINDOW_FOR_AC]: 'pictogram-window',
  [OC]: 'pictogram-oc',
  [PROMOTION_CASH_BACK]: 'pictogram-navi-expert',
  [BACK_TO_HEALTH]: 'pictogram-back-to-health',
  [HELP_AFTER_THEFT]: 'pictogram-theft',
  [PROTECTED_DISCOUNT]: 'pictogram-protected-discount',
  [GREEN_CARD]: 'pictogram-green-card',
  [COVER_KEYS]: 'pictogram-keys',
  [OC_ECOCYCLIST]: 'pictogram-ecocyclist',
  [HELP_PROGRAM]: 'pictogram-ring-help',
  [REPLACEMENT_CAR]: 'pictogram-replacement-car',
};