export class DictionaryItem {
  key: string;
  translation: string;
  subItems: DictionaryItem[];
  data: any;

  constructor(key: string, translation: string, subItems: DictionaryItem[], data: any) {
    this.key = key;
    this.translation = translation;
    this.subItems = subItems;
    this.data = data;
  }

}
