import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {VideoConsultantFormModel} from "@app/shared/components/video/video-consultant-form/video-consultant-form.model";
import {NgForm} from "@angular/forms";
import {Consent} from "@app/shared/services/consent/consent.model";
import {ConfigService} from "@app/shared/services/config/config.service";
import {VideoService} from "@app/shared/components/video/video.service";
import {AlfavoxConsultantConfigModel} from "@app/shared/services/config/video-call.model";


@Component({
  selector: 'app-video-consultant-form',
  templateUrl: './video-consultant-form.component.html',
  styleUrls: ['./video-consultant-form.component.scss'],
})
export class VideoConsultantFormComponent implements OnInit {

  @Output() back: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() close: EventEmitter<void> = new EventEmitter<void>();
  videoConsultantForm: VideoConsultantFormModel;
  consent: Consent;
  availableCampaigns: AlfavoxConsultantConfigModel[];

  constructor(private configService: ConfigService,
              private videoService: VideoService,) {
    this.videoConsultantForm = new VideoConsultantFormModel();
    this.availableCampaigns = this.configService.alfavoxConsultantQueue;
  }

  ngOnInit() {
    this.consent = {
      code: '-',
      additionalText: '',
      required: true,
      text: 'Zapoznałam/em się z "Informacją o przetwarzaniu danych osobowych". <a class="text-decoration-none" target="_blank" href="https://www.link4.pl/sites/default/files/link4_obowiazek_informacyjny_wideorozmowy.pdf"><strong>Pobierz informację o przetwarzaniu Twoich danych osobowych</strong></a>'
    };
  }

  send(form: NgForm) {
    if (form.invalid) {
      return;
    }

    const url = new URL(this.configService.alfavoxVideoUrl);
          url.searchParams.append('username', this.videoConsultantForm.firstName);
          url.searchParams.append('queue', this.videoConsultantForm.queueName);

    this.close.emit();
    window.open(url.href, '_blank');
  }

  onBack() {
    this.back.emit(true);
  }

}
