import {Injectable} from '@angular/core';
import {CalculationStoreService} from "@app/calculate/calculate/calculation-store.service";
import {CepikResponse, MotoContactRole, Vehicle} from "@app/shared/models/cepik/cepik-response.model";
import {VehicleModel} from "@app/calculate/vehicle/vehicle.model";
import {VehicleModelModel} from "@app/calculate/vehicle/vehicle-model.model";
import {PersonalDataStoreService} from "@app/shared/services/personal-data/personal-data-store.service";
import {PersonalDataStoreModel, PersonalDataType} from "@app/shared/services/personal-data/personal-data-store.model";
import {VehicleOwnerModel} from "@app/calculate/vehicle-owner/vehicle-owner.model";
import {VehicleModelVersionModel} from "@app/calculate/vehicle/vehicle-model-version.model";
import {DictionaryItem} from "@app/shared/services/dictionary/dictionary-item.model";
import {OfferStep, Step} from "@app/shared/models/step/step.model";
import {InsuranceStartDateModel} from "@app/calculate/insurance-start-date/insurance-start-date.model";
import {DateUtilService} from "@app/shared/services/date-util/date-util.service";

@Injectable()
export class CepikFormDataService {

  constructor(
    private readonly calculationStoreService: CalculationStoreService,
    private readonly personalDataStore: PersonalDataStoreService,
    private readonly dateUtilService: DateUtilService
  ) {}

  storeData(proposalData: CepikResponse) {
    this.storeVehicle(proposalData.vehicle);
    this.storePersonalData(proposalData);
    this.storeVehicleOwner(proposalData);
    this.storeInsuranceStartDate(proposalData);
  }

  private storeInsuranceStartDate(proposalData: CepikResponse) {
    if (!proposalData.suggestedStartDate) return;

    const insuranceStartDate = new InsuranceStartDateModel();
          insuranceStartDate.startDate = this.dateUtilService.stringToUtc(proposalData.suggestedStartDate);

    this.calculationStoreService.storeInsuranceStartDate(insuranceStartDate);
  }

  private storeVehicle(vehicle: Vehicle) {
    if (!vehicle) { return }
    const vmodel = new VehicleModel();
    const uniqueModels = [...new Map(vehicle.matchingModels.map(item => [item.shortName, item])).values()];
    const uniqueBrands = [...new Map(vehicle.matchingModels.map(item => [item.makeId, item])).values()].map(vehicle => {
      return new DictionaryItem(
          vehicle.makeId.toString(),
          vehicle.makeName,
          null,
          null
      );
    });

    vmodel.productionYear = vehicle.productionYear;

    if (uniqueBrands.length === 1) {
      vmodel.brand = uniqueBrands[0];
    }

    if (uniqueModels.length === 1) {
      vmodel.model = {
        name: uniqueModels[0].shortName,
        versions: vehicle.matchingModels.map(vehicle => {
          return new VehicleModelVersionModel({
            id: vehicle.modelId,
            vehicleType: vehicle.vehicleTypeId,
            fuelType: null,
            cc: vehicle.cylinderCapacity,
            kw: vehicle.powerInKw,
            power: Math.round(vehicle.powerInKw * 1.36),
            doors: null,
            bodyType: vehicle.bodyTypeName,
            description: vehicle.shortName,
            passenger: true,
            delivery: false
          });
        })
      } as Partial<VehicleModelModel> as any;
    }

    if (vehicle.matchingModels.length === 1) {
      vmodel.version = {
        id: vehicle.matchingModels[0].modelId,
        vehicleType: vehicle.matchingModels[0].vehicleTypeId,
        bodyType: vehicle.matchingModels[0].bodyTypeName,
        description: vehicle.matchingModels[0].modelName,
        cc: vehicle.cylinderCapacity,
        kw: vehicle.powerInKw,
        fuelType: vehicle.fuelTypeId
      } as VehicleModelVersionModel;
    }

    this.calculationStoreService.storeVehicle(vmodel);
  }

  private storePersonalData(proposalData: CepikResponse) {
    if (!proposalData.policyContacts) { return }
    const owner = proposalData.policyContacts.filter(c => c.policyRoles.includes(MotoContactRole.MAIN_DRIVER))[0];
    const personalData = this.personalDataStore.getPersonalData(PersonalDataType.OWNER);

    if (owner) {
      this.personalDataStore.storePersonalData(PersonalDataType.OWNER, {
        ...personalData,
        address: {
          postalCode: owner.zipCode
        }
      } as Partial<PersonalDataStoreModel> as any);
    }
  }

  private storeVehicleOwner(proposalData: CepikResponse) {
    const vehicleOwner = new VehicleOwnerModel();
    vehicleOwner.ownersCount = proposalData.ownersCount;
    vehicleOwner.mainUser = PersonalDataType.OWNER;
    vehicleOwner.youngOwner = false;
    vehicleOwner.vehicleOwnerDetailsSteps = [
      Step.VEHICLE_OWNER_DETAILS,
      Step.VEHICLE_FIRST_CO_OWNER_DETAILS,
      Step.VEHICLE_SECOND_CO_OWNER_DETAILS
    ];
    vehicleOwner.vehicleOwnerAdditionalDetailsSteps = [
      OfferStep.VEHICLE_OWNER_ADDITIONAL_DETAILS,
      OfferStep.VEHICLE_FIRST_CO_OWNER_ADDITIONAL_DETAILS,
      OfferStep.VEHICLE_SECOND_CO_OWNER_ADDITIONAL_DETAILS
    ];

    this.calculationStoreService.storeVehicleOwner(vehicleOwner);
  }
}
