import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {ErrorCode, ErrorInfo, ErrorType} from '@app/shared/interceptors/server-error/error-info.model';
import {InfoDialogComponent} from "@app/shared/components/info-dialog/info-dialog.component";
import {MatDialog, MatDialogModule} from '@angular/material/dialog';
import {CallbackDialogComponent} from "@app/shared/components/callback/callback-dialog.component";
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {SmsAuthErrorCode} from "@app/offer/sms-authorization/sms-auth-error-code.model";
import {ExceptionMessageReplaceService} from "@app/shared/services/ui/exception-message-replace.service";

export const EXCLUDE_CATCH_ERROR_URL = [
  /omni\/offer-recalculated/,
  /omni\/offer\/.*/,
  /videocall\/.*/,
];

@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {

  constructor(
    private dialog: MatDialog,
    private readonly exceptionMessageReplaceService: ExceptionMessageReplaceService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
        this.handleError()
    ) as Observable<HttpEvent<any>>;
  }

  private handleError() {
    return catchError((errorResponse: HttpErrorResponse) => {
      if (EXCLUDE_CATCH_ERROR_URL.some(v => errorResponse.url.match(v)))  return;

      const errorData = errorResponse.error as ErrorInfo;
      let message = this.exceptionMessageReplaceService.getMessage(errorData);
      let title: string;
      let type: ErrorType = errorData.type;

      const handleAsBusiness = this.isBusinessError(errorResponse.error);
      if (!handleAsBusiness) {
        title = '<strong>Aplikacja</strong> jest w tym momencie niedostępna';
        message = 'Prosimy o kontakt z infolinią pod numerem (22) 444 44 44';
        type = ErrorType.SYSTEM;
      }

      const dialogType = this.getDialogType({errorResponse});

      if (dialogType == 'CALLBACK') {
        this.dialog.open(CallbackDialogComponent, {
          disableClose: true,
          autoFocus: false,
          panelClass: 'callback-dialog',
          data: {
            errorCode: errorResponse.error.code,
            closeDialogCallback: () => this.dialog.closeAll()
          },
        });
      } else if (dialogType == 'BASIC') {
        this.dialog.open(InfoDialogComponent, {
          data: {
            message: message,
            title: title,
            type: type,
            errorCode: errorResponse.error.code
          },
          panelClass: 'callback-dialog'
        });
      }

      return throwError(errorResponse);
    });
  }

  private getDialogType(args: { errorResponse: HttpErrorResponse}): 'CALLBACK' | 'BASIC' | 'NONE' {
    const {errorResponse} = args;

    const notSmsError = !Object.values(SmsAuthErrorCode).includes(errorResponse.error.code);
    const existsInErrorCode = Object.values(ErrorCode).includes(errorResponse.error.code);

    if (existsInErrorCode) {
      return 'CALLBACK';
    } else if (notSmsError) {
      return 'BASIC'
    } else {
      return 'NONE';
    }
  }

  private isBusinessError(error: any): boolean {
    if (error.hasOwnProperty('type') && error.type && error.type !== ErrorType[ErrorType.SYSTEM]) {
      return true;
    }
    return false;
  }

}
