<form id="dateCalendar" name="dateCalendar" #dateCalendar="ngForm">
  <div class="row clear-margin custom-header">
    <div class="col-2 p-0 d-flex justify-content-center">
      <button mat-icon-button type="button" class="mat-calendar-previous-button"
              (click)="prev()"
              [disabled]="!previousEnabled()"
              [attr.aria-label]="prevButtonLabel">
      </button>
    </div>
    <div class="col-4 p-0 pr-2">
      <div class="form-field">
        <mat-form-field>
          <mat-select id="datepickerSelectMonth"
                      name="datepickerSelectMonth"
                      disableOptionCentering
                      #datepickerSelectMonth="ngModel"
                      [(ngModel)]="activeMonth"
                      (selectionChange)="changeMonth($event)"
                      (closed)="onClosedSelect($event)">
            <mat-option *ngFor="let month of listMonth"
                        [value]="month.id">{{ month.shortName }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="col-4 p-0 pl-2">
      <div class="form-field">
        <mat-form-field>
          <mat-select id="datepickerSelectYear"
                      name="datepickerSelectYear"
                      disableOptionCentering
                      #datepickerSelectYear="ngModel"
                      [(ngModel)]="activeYear"
                      (selectionChange)="changeYear($event)"
                      (closed)="onClosedSelect($event)">
            <mat-option *ngFor="let year of listYear"
                    [value]="year">{{ year }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="col-2 p-0 d-flex justify-content-center">
      <button mat-icon-button type="button" class="mat-calendar-next-button"
              (click)="next()"
              [disabled]="!nextEnabled()"
              [attr.aria-label]="nextButtonLabel">
      </button>
    </div>
  </div>
</form>

