import { DateAdapter} from '@angular/material/core';
import * as dayjs from 'dayjs';
import {Injectable} from "@angular/core";
import {Dayjs} from "dayjs";

function range<T>(length: number, valueFunction: (n: number) => T): T[] {
  const valuesArray = Array(length);
  for (let i = 0; i < length; i++) {
    valuesArray[i] = valueFunction(i);
  }
  return valuesArray;
}

@Injectable()
export class CustomDateAdapter extends DateAdapter<Dayjs> {
  options: {
    useUtc: false
  }

  localeData: any;

  constructor() {
    super();
    this.setLocale('pl');
  }

  setLocale(locale) {
    super.setLocale(locale);
    const dayJsLocaleData = this.dayJs().localeData();

    this.localeData = {
      firstDayOfWeek: dayJsLocaleData.firstDayOfWeek(),
      longMonths: dayJsLocaleData.months(),
      shortMonths: dayJsLocaleData.monthsShort(),
      dates: range(31, (i) => this.createDate(2017, 0, i + 1).format('D')),
      longDaysOfWeek: range(7, (i) => this.dayJs().set('day', i).format('dddd')),
      shortDaysOfWeek: dayJsLocaleData.weekdaysShort(),
      narrowDaysOfWeek: dayJsLocaleData.weekdaysMin(),
    };
  }

  getYear(date) {
    return this.dayJs(date).year();
  }

  getMonth(date) {
    return this.dayJs(date).month();
  }

  getDate(date) {
    return this.dayJs(date).date();
  }

  getDayOfWeek(date) {
    return this.dayJs(date).day();
  }

  getMonthNames(style) {
    return style === 'long' ? this.localeData.longMonths : this.localeData.shortMonths;
  }

  getDateNames() {
    return this.localeData.dates;
  }

  getDayOfWeekNames(style) {
    if (style === 'long') {
      return this.localeData.longDaysOfWeek;
    }
    if (style === 'short') {
      return this.localeData.shortDaysOfWeek;
    }
    return this.localeData.narrowDaysOfWeek;
  }

  getYearName(date) {
    return this.dayJs(date).format('YYYY');
  }

  getFirstDayOfWeek() {
    return this.localeData.firstDayOfWeek;
  }

  getNumDaysInMonth(date) {
    return this.dayJs(date).daysInMonth();
  }

  clone(date) {
    return date.clone();
  }

  createDate(year, month, date) {
    const returnDayjs = this.dayJs()
        .set('year', year)
        .set('month', month)
        .set('date', date);
    return returnDayjs;
  }

  today() {
    return this.dayJs();
  }

  parse(value, parseFormat) {
    if (value && value.length === parseFormat.length && typeof value === 'string') {
      return dayjs(value, parseFormat);
    }
  }

  format(date, displayFormat) {
    if (!this.isValid(date)) {
      throw Error('DayjsDateAdapter: Cannot format invalid date.');
    }
    return date.locale(this.locale).format(displayFormat);
  }

  addCalendarYears(date, years) {
    return date.add(years, 'year');
  }

  addCalendarMonths(date, months) {
    return date.add(months, 'month');
  }

  addCalendarDays(date, days) {
    return date.add(days, 'day');
  }

  toIso8601(date) {
    return date.toISOString();
  }

  deserialize(value) {
    let date;
    if (value instanceof Date) {
      date = this.dayJs(value);
    }
    else if (this.isDateInstance(value)) {
      return this.clone(value);
    }
    if (typeof value === 'string') {
      if (!value) {
        return null;
      }
      date = this.dayJs(value).toISOString();
    }
    if (date && this.isValid(date)) {
      return this.dayJs(date);
    }
    return super.deserialize(value);
  }

  isDateInstance(obj) {
    return dayjs.isDayjs(obj);
  }

  isValid(date) {
    return this.dayJs(date).isValid();
  }

  invalid() {
    return this.dayJs(null);
  }

  dayJs(input?, format?, locale?): Dayjs {
    if (!this.shouldUseUtc) {
      return dayjs(input, { format, locale }, locale);
    }
    return dayjs(input, { format, locale, utc: this.shouldUseUtc }, locale).utc();
  }

  get shouldUseUtc() {
    const { useUtc } = this.options || {};
    return !!useUtc;
  }
}