import {
  OPTION_AMORTIZATION_ABOLISHED, OPTION_AMORTIZATION_NOT_ABOLISHED,
  OPTION_ASS_AC_COMFORT,
  OPTION_ASS_AC_DEFAULT,
  OPTION_ASS_AC_PLUS,
  OPTION_ASS_OC_DEFAULT,
  OPTION_ASS_OC_PLUS, OPTION_NEW_REPAIR_SHOP_CONST, OPTION_NEW_REPAIR_SHOP_NOT_CONST,
  OPTION_REPAIR_SHOP_ESTIMATE,
  OPTION_REPAIR_SHOP_ESTIMATE_CONST_SUM,
  OPTION_REPAIR_SHOP_NOT_ESTIMATE, OPTION_REPAIR_SHOP_NOT_ESTIMATE_CONST_SUM,
  OPTION_SMART_DAMAGE_NATURE,
  OPTION_SMART_DAMAGE_THEFT_NATURE,
  OPTION_SMART_NATURE,
  OPTION_SMART_THEFT,
  OPTION_SMART_THEFT_NATURE
} from "@app/shared/models/cover/cover-option.model";

export const GTM_FIELDS_NAME = {
  ['licensePlateNumber']: "numer-rejestracyjny",
  ['lp-consent']: "informacja-dane-osobowe",
  ['vehicleType']: "rodzaj-pojazdu",
  ['gasInstallation']: "instalacja-gazowa",
  ['steeringWheelOnRightSide']: "kierownica-prawa-strona",
  ['currentMileage']: "dotychczasowy-przebieg",
  ['usage']: "sposób-uzytkowania",
  ['mainUser']: "glowny-uzytkownik-pojazdu",
  ['youngOwner']: "mniej-niż-24-lata",
  ['youngestOwnerAge']: "wiek-najmlodszego-kierowcy",
  ['pesel-']: "pesel",
  ['peselNotKnow']: "nie-znam-pesel",
  ['gender']: "plec",
  ['ocInsuranceYear']: "liczba-lat-oc",
  ['ocDamagesInLastSixYears']: "liczba-szkod-oc",
  ['firstName-']: "imie",
  ['lastName-']: "nazwisko",
  ['previousLastName']: "poprzednie-nazwisko",
  ['phoneNumber-']: "numer-telefonu",
  ['email-']: "adres-email",
  ['driverLicenseObtainYear']: "rok-uzyskania-prawa-jazdy",
  ['postalCode']: "kod-pocztowy",
  ['city']: "miejscowosc",
  ['city-address']: "miejscowosc",
  ['street-address']: "ulica",
  ['autocomplete-street-address']: "ulica",
  ['houseNumber-address']: "numer-domu",
  ['flatNumber-address']: "numer-lokalu",
  ['allConsentsSelect']: "zaznaczenie-wszystkich-zgod",
  ['calculate_consent_INFO_OBLIGATION_WITH_PROFILING']: "zaznaczenie-info-dane-osobowe",
  ['calculate_consent_ANTI_GROUP']: "zaznaczenie-oswiadczenia-wykluczenia",
  ['calculate_consent_E_SERVICE_TERM']: "zaznaczenie-regulamin-droga-elektroniczna",
  ['calculate_consent_CONTACT_EMAIL_MRKT']: "'zaznaczenie-zgody-informacje-handlowe",
  ['calculate_consent_CONTACT_PHONE']: "zaznaczenie-zgody-ofert-automatow",
  ['calculate_consent_PZU_DATA_SHARING_MRKT']: "zaznaczenie-zgody-cele-marketingowe",
  ['kdr-checkbox']: "karta-duzej-rodziny",
  ['largeFamilyCardNumber']: "karta-duzej-rodziny",
  ['promotional-code-checkbox']: "kod-promocyjny",
  ['promotionalCode']: "kod-promocyjny",
  ['installments-checkbox']: "raty",
  ['vin']: "vin",
  ['isRegisteredAddressTheSameAsHomeAddress']: "adres-zameldowania-taki-sam",
  ['isCorrespondenceAddressTheSameAsHomeAddress']: "adres-korespodencyjny-taki-sam",
  ['insurer']: "kto-zawiera-umowe",
  ['sell_consent_BUNDLE_CAR_AND_FORM']: "zaznaczenie-prawdziwe-dane",
  ['sell_consent_IMPORTANT_INFO']: "zaznaczenie-wazne-informacje",
  ['sell_consent_INSPECTION']: "zaznaczenie-info-ogledziny-pojazdu",
  ['sell_consent_BUNDLE_IDD']: "zaznaczenie-info-odstapienie-od-umowy",
  ['sell_consent_RENOUNCEMENT']: "zaznaczenie-info-odstapienie-od-umowy",
  ['sell_consent_SELLER_INTRODUCTION']: "zaznaczenie-info-umowa-z-link4",
  ['sell_consent_DATA_UPDATE']: "zaznaczenie-akceptacja-aktualizacji-formularza",
  ['cover_3464']: "auto-assistance",
  ['cover_8086']: "szyby",
  ['cover_286998']: "pomoc-kradziez",
  ['cover_282663']: "wracaj-do-zdrowia",
  ['cover_308246']: "auto-zastepcze-na-czas-naprawy",
  ['cover_27061']: "smart-casco",
  ['cover_102797']: "nnw",
  ['cover_295267']: "pomoc-po-wypadku",
  ['cover_9353']: "auto-assistance",
  ['cover_20218']: "nnw",
  ['cover_110599']: "szyby",
  ['cover_39976']: "kluczyki",
  ['cover_293041']: "zaznaczenie-ekocyklista",
  ['authCode']: "wypelnienie-kod-sms",
  ['vehicleProductionYear']: "rok-produkcji",
  ['vehicleBrand']: "marka",
  ['vehicleModel']: "model",
  ['vehicleVersion']: "wersja",
  ['birthDate']: "data-urodzenia",
  ['groupInputDate']: "data-urodzenia",
  ['day']: "data-urodzenia",
  ['startDate']: "data-rozpoczecia-ubezpieczenia",
  ['flatNumber-insurer-address']: "umowa-inna-osoba-nrlokalu",
  ['houseNumber-insurer-address']: "umowa-inna-osoba-nrdomu",
  ['city-insurer-address']: "umowa-inna-osoba-miejscowosc",
  ['postalCode-insurer-address']: "'umowa-inna-osoba-kod-pocztowy",
  ['email-insurer-email']: "umowa-inna-osoba-mail",
  ['phoneNumber-insurer-phone']: "umowa-inna-osoba-telefon",
  ['lastName-insurer-last-name']: "umowa-inna-osoba-nazwisko",
  ['firstName-insurer-first-name']: "umowa-inna-osoba-imie",
  ['pesel-insurer-pesel']: "umowa-inna-osoba-pesel",
  ['autocomplete-street-insurer-address']: "umowa-inna-osoba-ulica",
  ['street-insurer-address']: "umowa-inna-osoba-ulica",
  ['ocDamagesInLastThreeYears']: "liczba-szkod-oc-3-lata",
  ['ocDamagesInLastYear']: "liczba-szkod-oc-6-lat",
  ['coverOptionCheckbox-3464']: "zaznaczenie-auto-assistance",
  ['coverOptionCheckbox-102797']: "zaznaczenie-nnw",
  ['ownersCount']: "ilosc-wlascicieli",
  ['option-10000']: "wariant-nnw-10000",
  ['option-20000']: "wariant-nnw-20000",
  ['option-50000']: "wariant-nnw-50000",
  ['option-100000']: "wariant-nnw-100000",
  ['option-' + OPTION_ASS_OC_DEFAULT]: "wariant-autoassistance-default",
  ['option-' + OPTION_ASS_OC_PLUS]: "wariant-autoassistance-plus",
  ['option-' + OPTION_ASS_AC_DEFAULT]: "wariant-autoassistance-default",
  ['option-' + OPTION_ASS_AC_PLUS]: "wariant-autoassistance-plus",
  ['option-' + OPTION_ASS_AC_COMFORT]: "wariant-autoassistance-komfort",
  ['option-' + OPTION_SMART_THEFT]: "wariant-smartcasco-kradziez",
  ['option-' + OPTION_SMART_NATURE]: "wariant-smartcasco-natura",
  ['option-' + OPTION_SMART_THEFT_NATURE]: "wariant-smartcasco-kradziez-natura",
  ['option-' + OPTION_SMART_DAMAGE_NATURE]: "wariant-smartcasco-zniszczenia-natura",
  ['option-' + OPTION_SMART_DAMAGE_THEFT_NATURE]: "wariant-smartcasco-zniszczenia-kradziez-natura",
  ['option-' + OPTION_REPAIR_SHOP_ESTIMATE]: "wariant-ac-kosztorys",
  ['option-' + OPTION_REPAIR_SHOP_ESTIMATE_CONST_SUM]: "wariant-ac-kosztorys",
  ['option-' + OPTION_REPAIR_SHOP_NOT_ESTIMATE]: "wariant-ac-warsztat",
  ['option-' + OPTION_REPAIR_SHOP_NOT_ESTIMATE_CONST_SUM]: "wariant-ac-warsztat",
  ['option-' + OPTION_NEW_REPAIR_SHOP_NOT_CONST]: "wariant-zmienna-suma",
  ['option-' + OPTION_NEW_REPAIR_SHOP_CONST]: "wariant-stala-suma",
  ['option-' + OPTION_AMORTIZATION_ABOLISHED]: "wariant-amortyzacja-zniesiona",
  ['option-' + OPTION_AMORTIZATION_NOT_ABOLISHED]: "wariant-amortyzacja-niezniesiona",
}
