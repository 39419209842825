import { Step } from '@app/shared/models/step/step.model';

export class StepResolver {

  public static getCurrentStepName(url: string): string {
    if (!url) { return null; }
    return url.split(/[/?]/)[1];
  }

  public static isLandingPage(url: string): boolean {
    return StepResolver.getCurrentStepName(url) === '';
  }

  public static isSessionExpiredStep(url: string): boolean {
    const step = StepResolver.getCurrentStepName(url);
    return step === Step.SESSION_EXPIRED;
  }

}
