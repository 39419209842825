import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'dateFormatter'
})
export class DateFormatterPipe extends DatePipe implements PipeTransform {

  transform(value: any): any {
    try {
      return super.transform(value, 'yyyy-MM-dd');
    } catch (e) {
      return null;
    }
  }

}
