import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {OmniVehicleModel} from "@app/offer/omni-offer/omni-vehicle.model";
import {API_URL} from "@app/shared/constants/settings.constant";

@Injectable()
export class VehicleService {

  constructor(private httpClient: HttpClient) {
  }

  private readonly _urlPrefix = `${API_URL}/dictionary`;

  getVehicles(productionYear: number, modelId: number): Observable<OmniVehicleModel> {
    return this.httpClient.get<OmniVehicleModel>(this._urlPrefix + `/vehicles/${modelId}/${productionYear}`);
  }

}
