import {ProductECommerceModel} from "@app/shared/services/gtm/model/product-ecommerce.model";
import {CalculationResponseModel} from "@app/calculate/calculate/models/calculation-response.model";
import {ECommerceDataModel} from "@app/shared/services/gtm/model/ecommerce-data.model";
import {ProductMapper} from "@app/shared/services/gtm/mapper/product.mapper";
import {CalculationStoreService} from "@app/calculate/calculate/calculation-store.service";
import {ProductTagBuilderType} from "@app/shared/services/gtm/model/product-tag-builder-type.model";
import {ProductModel} from "@app/shared/services/gtm/model/product.model";
import {ActionFieldMapper} from "@app/shared/services/gtm/mapper/action-field.mapper";
import {InsuranceType} from "@app/calculate/vehicle/vehicle.model";
import {OfferRecalculatedResponse} from "@app/shared/services/omni/omni.model";

export class ProductECommerceMapper {

  private static readonly CURRENCY_CODE_PL = 'PLN';

  public static map(calculationStoreService: CalculationStoreService,
                    productTagBuilderType: ProductTagBuilderType): ProductECommerceModel {
    const productECommerce = new ProductECommerceModel();
    productECommerce.currencyCode = ProductECommerceMapper.CURRENCY_CODE_PL;

    if (ProductTagBuilderType.IMPRESSION === productTagBuilderType) {
      productECommerce.impressions = ProductECommerceMapper.getImpressionData(calculationStoreService, productTagBuilderType);
    }

    if (ProductTagBuilderType.CLICK === productTagBuilderType) {
      productECommerce.click = ProductECommerceMapper.getECommerceData(calculationStoreService, productTagBuilderType);
    }

    if (ProductTagBuilderType.DETAIL === productTagBuilderType) {
      productECommerce.detail = ProductECommerceMapper.getECommerceData(calculationStoreService, productTagBuilderType);
    }

    if (ProductTagBuilderType.PURCHASE === productTagBuilderType) {
      productECommerce.purchase = ProductECommerceMapper.getECommerceData(calculationStoreService, productTagBuilderType);
    }

    if (ProductTagBuilderType.CHECKOUT_STEP_1 === productTagBuilderType ||
        ProductTagBuilderType.CHECKOUT_STEP_2 === productTagBuilderType ||
        ProductTagBuilderType.CHECKOUT_STEP_3 === productTagBuilderType) {
      productECommerce.checkout = ProductECommerceMapper.getECommerceData(calculationStoreService, productTagBuilderType);
    }

    return productECommerce;
  }

  private static getImpressionData(calculationStoreService: CalculationStoreService,
                                   productTagBuilderType: ProductTagBuilderType): ProductModel[] {
    const calculationResponse = ProductECommerceMapper.getCalculationResponse(calculationStoreService);

    return ProductMapper.map(calculationStoreService, calculationResponse, productTagBuilderType);
  }

  private static getECommerceData(calculationStoreService: CalculationStoreService,
                                  productTagBuilderType: ProductTagBuilderType): ECommerceDataModel {
    let calculationResponse = ProductECommerceMapper.getCalculationResponse(calculationStoreService);
    const insuranceTotalPrice = calculationStoreService.getInsuranceTotalPrice();
    const insuranceStartDate = calculationStoreService.getInsuranceStartDate();
    const additionalSelectedCovers = calculationStoreService.getAdditionalSelectedCovers();
    const productECommerceData = new ECommerceDataModel();
    const omniOfferData = calculationStoreService.getOmniOfferData();

    if(!calculationResponse && !!omniOfferData) {
      calculationResponse = ProductECommerceMapper.getOMNICalculationResponse(omniOfferData);
    }

    productECommerceData.actionField = ActionFieldMapper
      .map(calculationStoreService, calculationResponse, productTagBuilderType, insuranceTotalPrice, insuranceStartDate);
    productECommerceData.products = ProductMapper
      .map(calculationStoreService, calculationResponse, productTagBuilderType, insuranceTotalPrice, additionalSelectedCovers);

    return productECommerceData;
  }

  private static getOMNICalculationResponse(omniOfferData: OfferRecalculatedResponse): CalculationResponseModel {
    return  {
      offerNumber: omniOfferData.omniDetails.offerNumber,
      insuranceType: omniOfferData.variant.name === InsuranceType.OC ? InsuranceType.OC : InsuranceType.OC_AND_AC,
      message: null,
      ufgDown: false,
      variants: [omniOfferData.variant]
    };
  }

  private static getCalculationResponse(calculationStoreService: CalculationStoreService): CalculationResponseModel {
    const calculationResponse = calculationStoreService.getCalculationResponse();
    const selectedVariant = calculationStoreService.getInsuranceVariant();

    if (calculationResponse && selectedVariant) {
      calculationResponse.variants
        .filter(insuranceVariant => {
          return insuranceVariant.name === selectedVariant.name;
        })
        .forEach(insuranceVariant => {
          insuranceVariant.selected = true;
        });
    }

    return calculationResponse;
  }
}
