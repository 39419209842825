import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CallbackComponent} from '@app/shared/components/callback/callback.component';
import {ValidationMessageComponent} from '@app/shared/components/validation/validation-message.component';
import {ValidationMessagesComponent} from '@app/shared/components/validation/validation-messages.component';
import {CallbackDialogComponent} from '@app/shared/components/callback/callback-dialog.component';
import {CallbackService} from '@app/shared/components/callback/callback.service';
import {RangeUtil} from '@app/shared/services/range-util/range-util.service';
import {ProvideParentFormDirective} from '@app/shared/directives/provide-parent-form/provide-parent-form.directive';
import {NgxMaskModule} from 'ngx-mask';
import {InsuranceTypeComponent} from '@app/shared/components/insurance-type/insurance-type.component';
import {APatternRestrict} from './directives/a-pattern-restrict/a-pattern-restrict';
import {BodyStylerService} from '@app/shared/services/body-styler/body-styler.service';
import {DateFormatterPipe} from '@app/shared/pipes/date-formatter.pipe';
import {ScrollToFirstInvalidDirective} from './directives/scroll-to-invalid/scroll-to-invalid.directive';
import {BreadcrumbComponent} from '@app/shared/components/breadcrumb/breadcrumb.component';
import {ComparatorService} from '@app/shared/services/comparator/comparator.service';
import {BackComponent} from '@app/shared/components/back/back.component';
import {InfoDialogComponent} from '@app/shared/components/info-dialog/info-dialog.component';
import {CalculationPlateComponent} from '@app/shared/components/calculation-plate/calculation-plate.component';
import {
  MobileCalculationPlateComponent
} from '@app/shared/components/mobile-calculation-plate/mobile-calculation-plate.component';
import {OfferNumberComponent} from '@app/shared/components/offer-number/offer-number.component';
import {EligibleOptionsValidator} from '@app/shared/directives/eligible-options/eligible-options.validator';
import {UrlParamsStoreService} from '@app/shared/interceptors/partner/url-params-store.service';
import {RequestStoreService} from '@app/shared/services/request-store/request-store.service';
import {PricePipe} from '@app/shared/pipes/price.pipe';
import {SearchByFieldPipe} from "@app/shared/pipes/search.pipe";
import {GtmService} from '@app/shared/services/gtm/gtm.service';
import {BreadcrumbService} from '@app/shared/components/breadcrumb/breadcrumb.service';
import {TitleUpperCaseFormatterPipe} from '@app/shared/pipes/title-upper-case-formatter.pipe';
import {LastNameComponent} from '@app/shared/components/last-name/last-name.component';
import {DocumentsComponent} from '@app/shared/components/documents/documents.component';
import {PersonalDataStoreService} from '@app/shared/services/personal-data/personal-data-store.service';
import {GeneratorService} from '@app/shared/services/generator/generator.service';
import {FirstNameComponent} from '@app/shared/components/first-name/first-name.component';
import {PhoneComponent} from '@app/shared/components/phone/phone.component';
import {CalculationStoreService} from '@app/calculate/calculate/calculation-store.service';
import {CalculateGuard} from '@app/calculate/calculate.guard';
import {CalculationService} from '@app/calculate/calculate/calculation.service';
import {SessionExpirationService} from '@app/shared/components/session/session-expiration.service';
import {NgxTrimDirectiveModule} from 'ngx-trim-directive';
import {ExitPopupService} from "@app/shared/services/exit-popup/exit-popup.service";
import {MatCardModule} from '@angular/material/card';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatIconModule} from "@angular/material/icon";
import {MatCheckboxModule} from '@angular/material/checkbox';
import {CustomAutoFocusDirective} from "@app/shared/directives/auto-focus/auto-focus";
import {CustomApmService} from "@app/shared/services/apm/custom-apm.service";
import {ScrollToFirstInvalidService} from "@app/shared/directives/scroll-to-invalid/scroll-to-invalid.service";
import {CalendarService} from "@app/shared/services/calendar/calendar.service";
import {CoverService} from "@app/shared/services/cover/cover.service";
import {LicensePlateNumberComponent} from "@app/shared/components/license-plate-number/license-plate-number.component";
import {LicensePlateNumberValidator} from "@app/shared/directives/license-plate-number/license-plate-number.validator";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {
  DatepickerHeaderComponent
} from "@app/shared/components/datepicker/datepicker-header/datepicker-header.component";
import {AppDatepickerMaterialsComponent} from "@app/shared/components/datepicker/datepicker.component";
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule} from "@angular/material/core";
import {MatSelectModule} from "@angular/material/select";
import {CepikService} from "@app/shared/services/cepik/cepik.service";
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {ConsentListComponent} from "@app/shared/components/consent-list/consent-list.component";
import {ConsentContentComponent} from "@app/shared/components/consent-content/consent-content.component";
import {CepikFormDataService} from "@app/calculate/calculate/cepik-form-data.service";
import {HeaderComponent} from "@app/shared/components/header/header.component";
import {RadioYesNoQuestionComponent} from './components/radio-yes-no-question/radio-yes-no-question.component';
import {MatRadioModule} from '@angular/material/radio';
import {CustomDateAdapter} from "@app/shared/components/datepicker/dayjs-date-adapter";
import {DatePickerValidator} from "@app/shared/components/datepicker/datepicker.validator";
import {DateInputGroup} from "@app/shared/components/datepicker/datepicker-input-group/date-input-group.component";
import {ExceptionMessageReplaceService} from "@app/shared/services/ui/exception-message-replace.service";
import {ConsentService} from "@app/shared/services/consent/consent.service";
import {DateUtilService} from "@app/shared/services/date-util/date-util.service";
import {PolicyService} from "@app/offer/policy/policy-service";
import {PaymentService} from "@app/sell/payment/payment.service";
import {AppInitializerService} from "@app/shared/services/app/app-initializer.service";
import {OmniService} from "@app/shared/services/omni/omni.service";
import {DatePickerService} from "@app/shared/components/datepicker/datepicker.service";
import {VehicleService} from "@app/shared/services/vehicle/vehicle.service";
import {ExistCoverPipe} from "@app/shared/pipes/cover/exist-cover.pipe";
import {OmniOfferPrimaryCoversPipe} from "@app/shared/pipes/cover/omni-offer-primary-covers.pipe";
import {OmniOfferWithoutPrimaryCoversPipe} from "@app/shared/pipes/cover/omni-offer-without-primary-covers.pipe";
import {GetCoverPipe} from "@app/shared/pipes/cover/get-cover.pipe";
import {FilterAcCoverPipe} from "@app/shared/pipes/cover/filter-ac-covers.pipe";
import {FilterDiscountCoversPipe} from "@app/shared/pipes/cover/filter-discount-covers.pipe";
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {HideCoversInAllProcessPipe} from "@app/shared/pipes/cover/hide-covers-in-all-process.pipe";
import {FilterGreenCardCoverPipe} from "@app/shared/pipes/cover/filter-green-card-cover.pipe";
import {LiveChatComponent} from "@app/shared/components/livechat/livechat.component";
import {LiveChatWidgetModule} from "@livechat/widget-angular";
import {OneTrustService} from "@app/shared/services/one-trust/one-trust.service";
import {MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions, MatTooltipModule} from '@angular/material/tooltip';
import {SalesManagoService} from "@app/shared/services/sales-manago/sales-manago.service";
import {CallbackFormComponent} from "@app/shared/components/callback-form/callback-form.component";
import {WidgetsComponent} from "@app/shared/components/widgets/widgets.component";
import {VideoComponent} from "@app/shared/components/video/video.component";
import {
  VideoCalendarFormComponent
} from "@app/shared/components/video/video-calendar-form/video-calendar-form.component";
import {
  VideoConsultantFormComponent
} from "@app/shared/components/video/video-consultant-form/video-consultant-form.component";
import {EmailComponent} from "@app/shared/components/email/email.component";
import {MailValidator} from "@app/shared/directives/mail/mail.validator";
import {MatMenuModule} from '@angular/material/menu';
import {MatSidenavModule} from "@angular/material/sidenav";
import {VideoService} from "@app/shared/components/video/video.service";
import {DateTimePickerComponent} from "@app/shared/components/date-time-picker/date-time-picker.component";
import {
  DateTimePickerHeaderComponent
} from "@app/shared/components/date-time-picker/date-time-picker-header/date-time-picker-header.component";
import {FilterByFieldPipe} from "@app/shared/pipes/filter.pipe";
import {LoadingSpinnerComponent} from "@app/shared/components/loading-spinner/loading-spinner.component";
import {GtmChangeDirective} from "@app/shared/directives/gtm-form-event/gtm-change.directive";
import {GtmSubmitDirective} from "@app/shared/directives/gtm-form-event/gtm-submit.directive";
import {GtmClickDirective} from "@app/shared/directives/gtm-form-event/gtm-click.directive";
import {GtmDateChangeDirective} from "@app/shared/directives/gtm-form-event/gtm-date-change.directive";
import {PreventDoubleClickDirective} from "@app/shared/directives/prevent-double-click/prevent-double-click";


const MODULES = [
  CommonModule,
  FormsModule,
  NgxMaskModule,
  NgxTrimDirectiveModule,
  MatTooltipModule,
  MatCardModule,
  MatRadioModule,
  MatButtonModule,
  MatMenuModule,
  MatButtonToggleModule,
  MatProgressSpinnerModule,
  MatDialogModule,
  MatFormFieldModule,
  MatInputModule,
  MatIconModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatSelectModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSidenavModule,
  ReactiveFormsModule,
  LiveChatWidgetModule,
];

const COMPONENTS = [
  LoadingSpinnerComponent,
  CallbackDialogComponent,
  InfoDialogComponent,
  RadioYesNoQuestionComponent,
  DocumentsComponent,
  CallbackComponent,
  CallbackFormComponent,
  WidgetsComponent,
  VideoComponent,
  VideoCalendarFormComponent,
  VideoConsultantFormComponent,
  ValidationMessageComponent,
  ValidationMessagesComponent,
  InsuranceTypeComponent,
  BreadcrumbComponent,
  BackComponent,
  CalculationPlateComponent,
  MobileCalculationPlateComponent,
  OfferNumberComponent,
  PhoneComponent,
  FirstNameComponent,
  LastNameComponent,
  EmailComponent,
  LicensePlateNumberComponent,
  DatepickerHeaderComponent,
  DateTimePickerHeaderComponent,
  AppDatepickerMaterialsComponent,
  DateTimePickerComponent,
  ConsentListComponent,
  ConsentContentComponent,
  HeaderComponent,
  DateInputGroup,
  LiveChatComponent
];

const VALIDATORS = [
  APatternRestrict,
  EligibleOptionsValidator,
  LicensePlateNumberValidator,
  DatePickerValidator,
  MailValidator,
];

const PIPES = [
  PricePipe,
  DateFormatterPipe,
  TitleUpperCaseFormatterPipe,
  FilterAcCoverPipe,
  OmniOfferPrimaryCoversPipe,
  OmniOfferWithoutPrimaryCoversPipe,
  FilterDiscountCoversPipe,
  FilterGreenCardCoverPipe,
  GetCoverPipe,
  ExistCoverPipe,
  HideCoversInAllProcessPipe,
  FilterByFieldPipe,
  SearchByFieldPipe,
];

const DIRECTIVES = [
  ProvideParentFormDirective,
  GtmChangeDirective,
  GtmSubmitDirective,
  GtmClickDirective,
  GtmDateChangeDirective,
  ScrollToFirstInvalidDirective,
  CustomAutoFocusDirective,
  PreventDoubleClickDirective,
];

const SERVICES = [
  BreadcrumbService,
  ExitPopupService,
  GtmService,
  CallbackService,
  RangeUtil,
  DateUtilService,
  BodyStylerService,
  ComparatorService,
  GeneratorService,
  PersonalDataStoreService,
  UrlParamsStoreService,
  RequestStoreService,
  CalculationStoreService,
  CalculateGuard,
  CalculationService,
  SessionExpirationService,
  CustomApmService,
  ScrollToFirstInvalidService,
  CalendarService,
  CoverService,
  CepikService,
  CepikFormDataService,
  ExceptionMessageReplaceService,
  ConsentService,
  OneTrustService,
  SalesManagoService,
  PolicyService,
  PaymentService,
  AppInitializerService,
  OmniService,
  VideoService,
  VehicleService,
  DatePickerService,
];

export const DATE_FORMAT = {
  parse: {
    dateInput: 'DD-MM-YYYY',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

export const customTooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 0,
  hideDelay: 0,
  touchendHideDelay: 1000,
  position: 'before'
};


@NgModule({
    imports: [
        ...MODULES
    ],
    declarations: [
        ...COMPONENTS,
        ...VALIDATORS,
        ...PIPES,
        ...DIRECTIVES,
    ],
    exports: [
        ...MODULES,
        ...COMPONENTS,
        ...VALIDATORS,
        ...PIPES,
        ...DIRECTIVES,
    ],
    providers: [
        ...SERVICES,
        ...PIPES,
        { provide: MAT_DATE_LOCALE, useValue: 'pl' },
        { provide: MAT_DATE_FORMATS, useValue: DATE_FORMAT },
        { provide: DateAdapter, useClass: CustomDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: customTooltipDefaults }
    ]
})
export class SharedMainModule {
}
