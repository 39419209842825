import {AfterViewInit, Component, OnInit, ViewEncapsulation} from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import {
  BreadcrumbService,
} from '@app/shared/components/breadcrumb/breadcrumb.service';
import { BreadcrumbStepData } from "@app/shared/components/breadcrumb/breadcrumb-step-data";
import { BreadcrumbStep } from "@app/shared/components/breadcrumb/breadcrumb-step.enum";
import { BreadcrumbContext } from "@app/shared/components/breadcrumb/breadcrumb-context.enum";

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BreadcrumbComponent implements AfterViewInit, OnInit {
  breadcrumbs: BreadcrumbStepData[] = [];
  currentStepNum: BreadcrumbStep;
  currentStepContext: BreadcrumbContext;

  constructor(
    private router: Router,
    private breadcrumbService: BreadcrumbService
  ) {}

  ngOnInit() {
    const url = this.router.url;
    this.resolveCurrentStep(new NavigationEnd(Math.random() * 100, url, url));
  }

  ngAfterViewInit() {
    this.router.events.subscribe((routerEvent: Event) => {
      if (!(routerEvent instanceof NavigationEnd)) return;
      this.resolveCurrentStep(routerEvent);
    });
  }

  private resolveCurrentStep(routerEvent: NavigationEnd): void {
    if (!routerEvent) {
      return;
    }

    this.currentStepNum = this.breadcrumbService.getStep(routerEvent);
    this.currentStepContext = this.breadcrumbService.getCurrentContext(routerEvent);
    this.breadcrumbs = this.breadcrumbService.getBreadcrumbsFor(this.currentStepContext);
  }
}
