import {FormFieldTagModel} from "@app/shared/services/gtm/tag/form-field-tag.model";

export class FormFieldErrorTagBuilder {

  private readonly tag: FormFieldTagModel;

  constructor() {
    this.tag = new FormFieldTagModel();
    this.tag.event = 'formFieldError';
  }

  public static builder(): FormFieldErrorTagBuilder {
    return new FormFieldErrorTagBuilder();
  }

  public formFieldName(formFieldName: string): FormFieldErrorTagBuilder {
    this.tag.formFieldName = formFieldName;
    return this;
  }

  public build(): FormFieldTagModel {
    return this.tag;
  }

}
