export const DELIVERY_VEHICLE_ID = 20014;
export const PASSENGER_DELIVERY_VEHICLE_ID = 20023;
export const PASSENGER_VEHICLE_ID = 20018;
export const OFFROAD_VEHICLE = 20022;

export const SUPPORTED_VEHICLE_TYPE = [
  DELIVERY_VEHICLE_ID,
  PASSENGER_DELIVERY_VEHICLE_ID,
  PASSENGER_VEHICLE_ID,
  OFFROAD_VEHICLE
];
