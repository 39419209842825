import {Directive, HostListener} from '@angular/core';
import {NgForm, ValidationErrors} from "@angular/forms";
import {GtmService} from "@app/shared/services/gtm/gtm.service";
import {GTM_FIELDS_NAME} from "@app/shared/directives/gtm-form-event/gtm-field.model";

@Directive({
  selector: 'input:not(#day):not(#month):not(#year), ' +
            'mat-radio-group, ' +
            'mat-checkbox, ' +
            'mat-button-toggle-group, ' +
            'mat-select:not(#datepickerSelectMonth):not(#datepickerSelectYear), ' +
            'mat-slide-toggle, ' +
            'mat-chip-option'
})
export class GtmChangeDirective {

  constructor(private ngForm: NgForm,
              private gtmService: GtmService,) {
  }

  @HostListener('change', ['$event'])
  onChange(event) {
    const controlName = event.source ? event.source.name || event.source.buttonToggleGroup._name : event.target.name;
    const errors = this.getFieldValidationErrors(controlName);
    const gtmFieldName = this.getGtmFieldName(controlName);

    if (!errors) {
      this.gtmService.emitFormFieldClick(gtmFieldName);
    }
  }

  @HostListener('selectionChange', ['$event'])
  onSelectionChange(event) {
    const controlName = event.source.ngControl.name;
    const errors = this.getFieldValidationErrors(controlName);
    let gtmFieldName = this.getGtmFieldName(controlName);

    if (!errors) {
      switch(controlName?.split('-')[0]) {
        case 'insuranceVariantOption': {
          gtmFieldName = this.getGtmFieldName('option-' + event.value.id);
          break;
        }
        case 'excessAmount': {
          gtmFieldName = this.getGtmFieldName('option-price-' + event.value);
          break;
        }
        case 'insuranceAmount': {
          gtmFieldName = this.getGtmFieldName('option-' + event.value);
          break;
        }
      }

      this.gtmService.emitFormFieldClick(gtmFieldName);
    }
  }

  @HostListener('optionSelected', ['$event'])
  onOptionSelected(event) {
    const controlName = event.source._elementRef.nativeElement.id;
    const errors = this.getFieldValidationErrors(controlName);
    const gtmFieldName = this.getGtmFieldName(controlName);

    if (!errors) {
      this.gtmService.emitFormFieldClick(gtmFieldName);
    }
  }

  getFieldValidationErrors(controlName: string): ValidationErrors {
    const control = this.ngForm.form.get(controlName);
    return control?.errors;
  }

  getGtmFieldName(controlName: string): string {
    const name = GTM_FIELDS_NAME[controlName];
    return name ? name : controlName;
  }

}
