import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  NgControl, NgForm,
  Validators
} from "@angular/forms";
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  Optional,
  Self,
  ViewChild
} from "@angular/core";
import {MAT_FORM_FIELD, MatFormField, MatFormFieldControl} from "@angular/material/form-field";
import {FocusMonitor} from "@angular/cdk/a11y";
import {BooleanInput, coerceBooleanProperty} from "@angular/cdk/coercion";
import {Subject} from "rxjs";
import {DAY_VALIDATION, MONTH_VALIDATION, YEAR_VALIDATION} from "@app/shared/constants/settings.constant";
import * as dayjs from "dayjs";

export class DateGroup {
  constructor(public day: string,
              public month: string,
              public year: string) {}
}

@Component({
  selector: 'date-input-group',
  templateUrl: 'date-input-group.component.html',
  styleUrls: ['date-input-group.component.scss'],
  providers: [{
    provide: MatFormFieldControl,
    useExisting: DateInputGroup
  }],
  host: {
    '[class.mat-form-field-should-float]': 'shouldLabelFloat',
    '[id]': 'id',
  },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateInputGroup implements ControlValueAccessor, MatFormFieldControl<Date>, OnDestroy {
  static nextId = 0;
  @ViewChild('day') dayInput: HTMLInputElement;
  @ViewChild('month') monthInput: HTMLInputElement;
  @ViewChild('year') yearInput: HTMLInputElement;

  DAY_VALIDATION = DAY_VALIDATION;
  MONTH_VALIDATION = MONTH_VALIDATION;
  YEAR_VALIDATION = YEAR_VALIDATION;

  parts = this._formBuilder.group({
    day: ['', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(2),
        Validators.max(31),
        Validators.min(1)
    ]],
    month: ['', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(2),
        Validators.max(12),
        Validators.min(1)
    ]],
    year: ['', [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(4)
    ]],
  });
  stateChanges = new Subject<void>();
  focused = false;
  touched = false;
  controlType = 'date-input';
  id = `date-input-${DateInputGroup.nextId++}`;
  onChange = (_: any) => {};
  onTouched = () => {};

  get empty() {
    const {
      value: {day, month, year},
    } = this.parts;

    return !day && !month && !year;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input('aria-describedby') userAriaDescribedBy: string;
  @Input('max') max: Date;
  @Input('min') min: Date;

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.parts.disable() : this.parts.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): Date | null {
    if (this.parts.valid) {
      const {
        value: {day, month, year},
      } = this.parts;

      return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
    }
    return null;
  }
  set value(date: Date | null) {
    const {
      day,
      month,
      year
    } = date ? new DateGroup(("0" + date.getDate()).slice(-2), ("0" + (date.getMonth() + 1)).slice(-2), date.getFullYear().toString()) :
               new DateGroup('', '', '');

    this.parts.setValue({day, month, year});
    this.stateChanges.next();
  }

  get errorState(): boolean {
    const errorName = this.getErrorName();

    if (errorName) {
      this.ngControl.control.setErrors({[errorName]: true});
    }

    return !!errorName;
  }

  constructor(
      private _formBuilder: FormBuilder,
      private _focusMonitor: FocusMonitor,
      private _elementRef: ElementRef<HTMLElement>,
      private _ngForm: NgForm,
      @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
      @Optional() @Self() public ngControl: NgControl,
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  autofilled?: boolean;

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  onFocusIn(event: FocusEvent) {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent) {
    if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  autoFocusNext($event: KeyboardEvent, control: AbstractControl, nextElement?: HTMLInputElement): void {
    const code = ['Tab', 'ArrowLeft', 'ArrowRight', 'Home', 'End', 'Backspace', 'Delete'];

    if (control.value.length === 2 && !code.includes($event.code)) {
      this._focusMonitor.focusVia(nextElement, 'program');
    }
  }

  autoFocusPrev(control: AbstractControl, prevElement: HTMLInputElement): void {
    if (control.value.length < 1) {
      this._focusMonitor.focusVia(prevElement, 'program');
    }
  }

  autoComplete(control: AbstractControl): void {
    const actualValue = control.value;

    if (actualValue.length === 1) {
      control.setValue('0' + actualValue);
    }
  }

  setDescribedByIds(ids: string[]) {
    const controlElement = this._elementRef.nativeElement.querySelector(
        '.date-input-container',
    )!;
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick($event) {
    const notValue = !this.parts.controls.day.value && !this.parts.controls.month.value && !this.parts.controls.year.value;

    if ($event.target.classList.contains('mat-mdc-form-field-infix') ||
        $event.target.closest('.mat-mdc-floating-label') ||
        $event.target.classList.contains('date-input-spacer') && notValue ||
        $event.target.classList.contains('date-input-container')) {
      this._focusMonitor.focusVia(this.dayInput, 'program');
    }
  }

  writeValue(date: Date | null): void {
    this.value = date;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  _handleInput(): void {
    this.onChange(this.value);
  }

  private getErrorName(): string | null {
    const dayValue = this.parts.controls.day.value;
    const monthValue = this.parts.controls.month.value;
    const yearValue = this.parts.controls.year.value;

    if (dayValue.length === 2 && this.parts.controls.day.errors?.max) {
      return 'dayMax';
    }

    if (monthValue.length === 2 && this.parts.controls.month.errors?.max) {
      return 'monthMax';
    }

    if (dayValue.length === 2 && this.parts.controls.day.errors?.min) {
      return 'dayMin';
    }

    if (monthValue.length === 2 && this.parts.controls.month.errors?.min) {
      return 'monthMin';
    }

    if ((this.touched || this._ngForm.submitted) &&
        (!dayValue && !monthValue && !yearValue)) {
      return 'required';
    }

    if (this.parts.invalid && this._ngForm.submitted ||
        this.parts.invalid && this.touched) {
      return 'datepicker-format';
    }

    const currentDate = yearValue + '-' + monthValue + '-' + dayValue;
    const maxDate = dayjs(this.max);
    const minDate = dayjs(this.min);

    if (this.parts.valid && maxDate.diff(currentDate, 'day', true) < 0) {
      return 'matDatepickerMax';
    }

    if (this.parts.valid && minDate.diff(currentDate, 'day', true) > 1) {
      return 'matDatepickerMin';
    }

    return null;
  }

}