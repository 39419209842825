import {Injectable} from '@angular/core';
import {OneTrustService} from "@app/shared/services/one-trust/one-trust.service";
import {SalesManagoService} from "@app/shared/services/sales-manago/sales-manago.service";
import {ConfigService} from "@app/shared/services/config/config.service";
import {FeatureFlagService} from "@app/shared/services/feature-flag/feature-flag.service";

@Injectable()
export class AppInitializerService {

  constructor(private featureFlagService: FeatureFlagService,
              private configService: ConfigService,
              private oneTrustService: OneTrustService,
              private salesManagoService: SalesManagoService) {
  }

  async init() {
    await this.featureFlagService.load();
    await this.configService.load();
    await this.salesManagoService.init();
    await this.oneTrustService.init();
  }

}
