<div class="widgets-desktop" *ngIf="FLAG_2022_WIDGET_LIVECHAT_ALFAVOX_CALLBACK">
    <p class="p-0 m-0 mb-2">
        <app-livechat type="widget-desktop"></app-livechat>
    </p>
    <p class="p-0 m-0 mb-2">
        <button type="button"
                class="item"
                mat-stroked-button
                (click)="openSlideNav(WIDGETS.CALLBACK)">
            <mat-icon svgIcon="widget-phone"></mat-icon>
            <span class="desc">Zadzwoń lub<br /> zamów rozmowę</span>
        </button>
    </p>
    <p class="p-0 m-0 mb-2" *ngIf="FLAG_2039_VIDEO">
        <button type="button"
                class="item"
                mat-stroked-button
                (click)="openSlideNav(WIDGETS.VIDEO)">
            <mat-icon svgIcon="widget-video"></mat-icon>
            <span class="desc">Wideorozmowa</span>
        </button>
    </p>
</div>
<div class="widgets-mobile" *ngIf="FLAG_2022_WIDGET_LIVECHAT_ALFAVOX_CALLBACK">
    <button mat-icon-button
            [matMenuTriggerFor]="menu"
            class="widgets-mobile-start">
        <mat-icon svgIcon="widget-mobile-start"></mat-icon>
    </button>
    <mat-menu #menu="matMenu"
              [overlapTrigger]="true"
              (closed)="onCloseMatMenu()"
              backdropClass="cdk-overlay-backdrop-widgets">
        <app-livechat type="widget-mobile"></app-livechat>
        <button mat-menu-item
                (click)="openMobileCallbackDialog($event)">
            <mat-icon svgIcon="widget-phone"></mat-icon>
        </button>
        <button mat-menu-item
                *ngIf="FLAG_2039_VIDEO"
                (click)="openMobileVideoDialog($event)">
            <mat-icon svgIcon="widget-video"></mat-icon>
        </button>
        <button mat-menu-item
                class="close-item">
            <mat-icon svgIcon="widget-close"></mat-icon>
        </button>
    </mat-menu>
</div>
