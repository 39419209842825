import { NavigationEnd } from "@angular/router";
import { OfferStep, Step } from "@app/shared/models/step/step.model";
import { StepResolver } from "@app/shared/services/url/step-resolver";

export class ECommPageTypeMapper {

  private static readonly METHOD_CHOICE = 'Wybór metody';
  private static readonly PROVIDING_DATA = 'Przekazanie danych';
  private static readonly PROPOSAL_PAGES = 'Strony wniosku';
  private static readonly CALCULATION = 'Kalkulacja oferty';
  private static readonly OFFER_CONFIGURATION = 'Konfiguracja oferty';
  private static readonly PURCHASE_FINALISATION = 'Finalizacja zakupu';
  private static readonly PURCHASE = 'Zakup';
  private static readonly SUCCESSFUL_PAYMENT = 'Udana płatność';

  private static readonly PAGE_TYPE_MAP: Map<string, string> = new Map([
    ['', ECommPageTypeMapper.METHOD_CHOICE],
    [Step.VEHICLE, ECommPageTypeMapper.PROPOSAL_PAGES],
    [Step.VEHICLE_ADDITIONAL_INFO, ECommPageTypeMapper.PROPOSAL_PAGES],
    [Step.VEHICLE_OWNER, ECommPageTypeMapper.PROPOSAL_PAGES],
    [Step.VEHICLE_OWNER_DETAILS, ECommPageTypeMapper.PROPOSAL_PAGES],
    [Step.VEHICLE_FIRST_CO_OWNER_DETAILS, ECommPageTypeMapper.PROPOSAL_PAGES],
    [Step.VEHICLE_SECOND_CO_OWNER_DETAILS, ECommPageTypeMapper.PROPOSAL_PAGES],
    [Step.INSURANCE_START_DATE, ECommPageTypeMapper.PROPOSAL_PAGES],
    [OfferStep.INSURANCE_VARIANT, ECommPageTypeMapper.CALCULATION],
    [OfferStep.INSURANCE_ADDITIONAL_OPTION, ECommPageTypeMapper.OFFER_CONFIGURATION],
    [OfferStep.INSURANCE_TOTAL_PRICE, ECommPageTypeMapper.OFFER_CONFIGURATION],
    [OfferStep.VEHICLE_REGISTRATION_DATA, ECommPageTypeMapper.PURCHASE_FINALISATION],
    [OfferStep.VEHICLE_OWNER_ADDITIONAL_DETAILS, ECommPageTypeMapper.PURCHASE_FINALISATION],
    [OfferStep.VEHICLE_FIRST_CO_OWNER_ADDITIONAL_DETAILS, ECommPageTypeMapper.PURCHASE_FINALISATION],
    [OfferStep.VEHICLE_SECOND_CO_OWNER_ADDITIONAL_DETAILS, ECommPageTypeMapper.PURCHASE_FINALISATION],
    [OfferStep.VEHICLE_OWNER_ADDITIONAL_ADDRESS, ECommPageTypeMapper.PURCHASE_FINALISATION],
    [OfferStep.VEHICLE_FIRST_CO_OWNER_ADDITIONAL_ADDRESS, ECommPageTypeMapper.PURCHASE_FINALISATION],
    [OfferStep.VEHICLE_SECOND_CO_OWNER_ADDITIONAL_ADDRESS, ECommPageTypeMapper.PURCHASE_FINALISATION],
    [OfferStep.CONTACT_AND_CONSENT, ECommPageTypeMapper.PURCHASE_FINALISATION],
    [OfferStep.THANK_YOU, ECommPageTypeMapper.PURCHASE],
    [OfferStep.THANK_YOU_FOR_PAYMENT, ECommPageTypeMapper.SUCCESSFUL_PAYMENT]
  ]);

  public static map(navigation: NavigationEnd): string {
    let step = StepResolver.getCurrentStepName(navigation.url);
    return ECommPageTypeMapper.PAGE_TYPE_MAP.get(step);
  }

}
