<div class="text text-small">
    <div>
        Zapoznaj się z okolicznościami, które wyłączają albo istotnie ograniczają ochronę ubezpieczeniową oraz z dokumentem zawierającym informacje o produkcie ubezpieczeniowym.
        <a href="https://www.link4.pl/kalkulator/moto/wazne-dokumenty?partner_id=563"
           target="_blank"
           rel="noopener">Przejdź</a> do dokumentów.
    </div>
    <div *ngIf="showPrivacyPolicy">
        Zapoznaj się z
        <a href="https://www.link4.pl/sites/default/files/polityka_prywatnosci_link4_tu_sa_2023.pdf"
           target="_blank"
           rel="noopener">polityką prywatności</a>.
    </div>
</div>
