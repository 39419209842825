import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {CalculationStoreService} from "@app/calculate/calculate/calculation-store.service";
import {API_URL} from "@app/shared/constants/settings.constant";
import {tap} from "rxjs/operators";
import {Observable, of} from "rxjs";
import {PrepareSellResponseModel} from "@app/offer/policy/prepare-sell-response.model";
import {PrepareSellRequestModel} from "@app/offer/policy/prepare-sell-request.model";
import {InputMode} from '@app/landing-page/landing-page.model';
import {OfferStep} from "@app/shared/models/step/step.model";
import {CepikService} from "@app/shared/services/cepik/cepik.service";
import {AcceptPolicyRequest, AcceptPolicyResponse} from "@app/shared/models/cepik/cepik-register-policy.model";
import {OmniService} from "@app/shared/services/omni/omni.service";
import {PersonalDataStoreService} from "@app/shared/services/personal-data/personal-data-store.service";
import {PersonalDataStoreModel, PersonalDataType} from "@app/shared/services/personal-data/personal-data-store.model";

@Injectable()
export class PolicyService {

  constructor(private httpClient: HttpClient,
              private storeService: CalculationStoreService,
              private cepikService: CepikService,
              private omniService: OmniService,
              private personalDataStoreService: PersonalDataStoreService) {
  }

  prepareSell(nextStep: OfferStep): Observable<any> {
    if (OfferStep.CONTACT_AND_CONSENT === nextStep) {
      const calculationId = this.storeService.getCalculationId();
      const inputMode = this.storeService.getLandingPage().inputMode;
      const prepareSellRequest = new PrepareSellRequestModel();
            prepareSellRequest.formData = this.storeService.getCalculationForm();
            prepareSellRequest.covers = this.storeService.getAdditionalInsuranceVariant().covers;
            prepareSellRequest.packageName = this.storeService.getInsuranceVariant().packageName;
            prepareSellRequest.insurerSendEmail = this.getInsurerSendEmail();
            prepareSellRequest.ownerSendEmail = this.getOwnerSendEmail();
            prepareSellRequest.sendingSellDocuments = this.checkSendingSellDocuments();

      if (inputMode === InputMode.CEPIK) {
        return this.cepikService.prepareSell(calculationId, prepareSellRequest);
      } else if (inputMode === InputMode.OMNI) {
        return this.omniService.prepareSell(prepareSellRequest);
      } else {
        return this.doDeclarationPathPrepareSell(calculationId, prepareSellRequest);
      }
    } else {
      return of(nextStep);
    }
  }

  accept(calculationId: string, acceptRequest: AcceptPolicyRequest): Observable<AcceptPolicyResponse> {
    return this.httpClient.post<AcceptPolicyResponse>(`${API_URL}/policy/accept/${calculationId}`, acceptRequest);
  }

  private checkSendingSellDocuments() {
    const storeOwnerEmail = this.storeService.getSellDocumentsOwnerEmail();
    const storeInsurerEmail = this.storeService.getSellDocumentsInsurerEmail();
    const localOwnerEmail = this.getOwnerSendEmail();
    const localInsurerEmail = this.getInsurerSendEmail();

    const ownerEmailChange = storeOwnerEmail !== localOwnerEmail;
    const insurerEmailChange = localOwnerEmail !== localInsurerEmail  && storeInsurerEmail !== localInsurerEmail;
    return  (!storeOwnerEmail && !storeInsurerEmail) || (ownerEmailChange || insurerEmailChange);
  }

  getInsurerSendEmail() {
    const owner = this.personalDataStoreService.getPersonalData(PersonalDataType.OWNER) as PersonalDataStoreModel;
    const firstCoOwner =  this.personalDataStoreService.getPersonalData(PersonalDataType.FIRST_CO_OWNER) as PersonalDataStoreModel;
    const secondCoOwner =  this.personalDataStoreService.getPersonalData(PersonalDataType.SECOND_CO_OWNER) as PersonalDataStoreModel;
    const insurer =  this.personalDataStoreService.getPersonalData(PersonalDataType.INSURER) as PersonalDataStoreModel;

    const persons = [owner, firstCoOwner, secondCoOwner, insurer];
    return persons.find(p=> p?.isInsurer)?.email;
  }

  private getOwnerSendEmail() {
    return (this.personalDataStoreService.getPersonalData(PersonalDataType.OWNER) as PersonalDataStoreModel)?.email;
  }

  private doDeclarationPathPrepareSell(calculationId: string, prepareSellRequest: PrepareSellRequestModel): Observable<PrepareSellResponseModel> {
    return this.httpClient.post<PrepareSellResponseModel>(`${API_URL}/policy/prepare/${calculationId}`, prepareSellRequest).pipe(
      tap(prepareSellResponse => {
        this.storeService.storePrepareSellResponse(prepareSellResponse);
        this.storeService.storePolicyOfferToken(prepareSellResponse.policyOfferToken);
        this.storeService.storeSellDocumentsInsurerEmail(prepareSellRequest.insurerSendEmail);
        this.storeService.storeSellDocumentsOwnerEmail(prepareSellRequest.ownerSendEmail);
      })
    );
  }
}
