import {AfterViewInit, Component, Inject, Renderer2, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {BodyStylerService} from '@app/shared/services/body-styler/body-styler.service';
import {SessionExpirationService} from '@app/shared/components/session/session-expiration.service';
import {StepResolver} from '@app/shared/services/url/step-resolver';
import {MatDrawer} from "@angular/material/sidenav";
import {WidgetsModel} from "@app/shared/components/widgets/widgets.model";
import {DOCUMENT} from "@angular/common";

@Component({
  selector: 'app-calculate',
  templateUrl: './calculate.component.html',
  styleUrls: ['./calculate.component.scss']
})
export class CalculateComponent implements AfterViewInit {
  @ViewChild('slideNavEnd') slideNavEnd: MatDrawer;
  widgetContainer: WidgetsModel;

  constructor(private router: Router,
              private bodyStylerService: BodyStylerService,
              private renderer: Renderer2,
              @Inject(DOCUMENT) private document: Document,
              private sessionExpirationService: SessionExpirationService) {
    this.sessionExpirationService.startCheck();
  }

  ngAfterViewInit(): void {
    this.bodyStylerService.setBodyClass('calculation-module', this.renderer);
  }

  isLandingPage() {
    return StepResolver.isLandingPage(this.router.url);
  }

  slideNavClose() {
    this.slideNavEnd.close();
    this.renderer.removeStyle(this.document.body, 'overflow-y');
  }

  onSlideNavClose(event) {
    this.widgetContainer = null;
    this.renderer.removeStyle(this.document.body, 'overflow-y');
  }

  onSlideNavOpen(event) {
    this.renderer.setStyle(this.document.body, 'overflow-y', 'hidden');
  }

  protected readonly WidgetsModel = WidgetsModel;
}
