import {Component, Input} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Consent} from "@app/shared/services/consent/consent.model";

@Component({
  selector: 'app-consent-content',
  templateUrl: './consent-content.component.html',
  styleUrls: ['./consent-content.component.scss'],
  animations: [
    trigger('collapse', [
      state('false', style({ height: '*' })),
      state('true', style({ height: '0' })),
      transition('false => true', [
        style({display: 'block'}),
        animate('250ms ease-in')
      ]),
      transition('true => false', [
        animate('250ms ease-out'),
        style({display: 'none'})
      ])
    ])
  ]
})
export class ConsentContentComponent {

  @Input()
  consent: Consent;
  isCollapsed: Boolean = true;

  toggleAdditionalContent(event: Event) {
    this.isCollapsed = !this.isCollapsed;
    event.stopPropagation();
    event.preventDefault();
  }

}
