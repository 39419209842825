import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchByField'
})
export class SearchByFieldPipe implements PipeTransform {

  transform(data: any[], field: string, value: string): any[] {
    const filterValue = value.toLowerCase();
    return filterValue
        ? data.filter(item => item[field].toLowerCase().includes(filterValue))
        : data;
  }

}