import {Inject, Injectable} from '@angular/core';
import {FeatureFlagService} from "@app/shared/services/feature-flag/feature-flag.service";
import {DOCUMENT} from "@angular/common";
import {Flags} from "@app/shared/models/flag/flag.model";
import {ConfigService} from "@app/shared/services/config/config.service";
import {HttpClient} from "@angular/common/http";
import {API_URL} from "@app/shared/constants/settings.constant";

@Injectable({
  providedIn: 'root'
})
export class SalesManagoService {

  constructor(private featureFlagService: FeatureFlagService,
              private configService: ConfigService,
              private http: HttpClient,
              @Inject(DOCUMENT) private document: Document) {
  }

  loadJsScript() {
    const config = this.configService.getConfigInstant();
    const head = window.document.getElementsByTagName('head')[0];
    const smScript = window.document.createElement('script');
          smScript.src = ('https:' == document.location.protocol ? 'https://' : 'http://') + 'app2.salesmanago.com/static/sm.js';
          smScript.setAttribute('type', 'text/javascript');
          smScript.setAttribute('async', 'true');

    window['SalesmanagoObject'] = 'sm';
    window['sm'] = window['sm'] || function () {( window['sm'].q = window['sm'].q || [] ).push(arguments)};
    window['_smid'] = config.client.smId;

    if (config.client.smApp) {
      window['_smapp'] = config.client.smApp;
    }

    head.insertBefore(smScript, head.firstChild);
    return smScript;
  }

  createContactCookie(email: string) {
    const config = this.configService.getConfigInstant();
    const apiUrl = `${API_URL}/sales-manago-contacts?email=${email}`;
    const domain = 'kalkulatory.link4.pl';

    if (window.location.host === domain || config.client.smAlwaysCreateCookie) {
      this.http.get<any>(apiUrl).subscribe(
        response => {
          if(!!response?.id) {
            const cookieScript = window.document.createElement('script');
            cookieScript.setAttribute('type', 'text/javascript');
            cookieScript.text = `var _smclientid = "${response?.id}";`
            window.document.body.appendChild(cookieScript);
          } else {
            console.warn(response?.error);
          }
        }
      );
    }
  }

  async init() {
    if (this.featureFlagService.isActive(Flags.FLAG_1874_SALESMANAGO)) {
      return this.loadJsScript();
    }
  }

}
