import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'price'
})
export class PricePipe implements PipeTransform {

  transform(value: string | number): string {
    const val: string = value.toString();

    if (val) {
      const parts = val.split(".");

      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");

      if (parts[1]?.length === 1) {
        parts[1] = parts[1] + '0';
      }

      return parts.join(",");
    }

    return '';
  }

}
