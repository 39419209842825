import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html'
})
export class DocumentsComponent {
  @Input()
  showPrivacyPolicy: boolean;
}
