import {AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {Dayjs} from "dayjs";
import {
  DateTimePickerHeaderComponent
} from "@app/shared/components/date-time-picker/date-time-picker-header/date-time-picker-header.component";
import {SlotModel} from "@app/shared/components/date-time-picker/slot.model";
import {VideoService} from "@app/shared/components/video/video.service";
import {DateTimePickerService} from "@app/shared/components/date-time-picker/date-time-picker.service";


@Component({
  selector: 'app-date-time-picker-materials',
  templateUrl: 'date-time-picker.component.html',
  styleUrls: ['date-time-picker.component.scss'],
  exportAs: 'dateTimePickerMaterials',
  providers: [DateTimePickerService]
})
export class DateTimePickerComponent implements AfterViewInit {
  headerComponent = DateTimePickerHeaderComponent;

  @Input() modelRef: any;
  @Input() modelRefField: string;
  @Input() maxDate: Date;
  @Input() minDate: Date;
  @Input() startAt: Date;
  @Input() required: boolean;
  @Input() redStar: boolean;
  @Input() disabled: boolean;
  @Input() customClass: string;
  @Input() matLabelValue: string;
  @Output() emitDateChange: EventEmitter<any> = new EventEmitter();
  @ViewChild('picker', { static: true }) picker;

  hours: number;
  minutes: number;
  currentHour: number;

  constructor(public videoService: VideoService,
              public elementRef: ElementRef<HTMLElement>,
              public dateTimePickerService: DateTimePickerService) {
  }

  ngAfterViewInit() {
    const self = this;

    this.picker.closedStream.subscribe(function () {
      self.emitDateChange.emit(self.elementRef);
    });
  }

  pickerDateChange($event: Dayjs) {
    const currentDate = new Date();
    let date = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), this.hours, this.minutes, 0);

    if ($event) {
      const localTimeZoneDate = $event.toDate();
      date = new Date(localTimeZoneDate.getFullYear(), localTimeZoneDate.getMonth(), localTimeZoneDate.getDate(), this.hours, this.minutes, 0);
    }

    this.modelRef[this.modelRefField] = date;
  }

  open() {
    this.picker.open();
  }

  opened() {
    const currentDate = new Date();
    const campaignId = this.modelRef.campaignId;

    this.videoService.getFreeSlots(currentDate, campaignId).subscribe();
    this.currentHour = currentDate.getHours();
    this.dateTimePickerService.selectedCampaignId = campaignId;
  }

  setTime(event: MouseEvent, slot: SlotModel) {
    const time = slot.start.slice(11, 16);

    this.hours = parseInt(time.split(':')[0]);
    this.minutes = parseInt(time.split(':')[1]);
    this.videoService.freeSlots.map(s => s.selected = false);
    slot.selected = true;
  }

}
