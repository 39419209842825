import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {FIRST_NAME_VALIDATION} from '@app/shared/constants/settings.constant';
import {NgModel} from '@angular/forms';
import {FirstNameModel} from '@app/shared/components/first-name/first-name.model';
import {TitleCasePipe} from "@angular/common";

@Component({
  selector: 'app-first-name',
  templateUrl: './first-name.component.html',
  providers: [TitleCasePipe]
})
export class FirstNameComponent {

  @Input() modelRef: FirstNameModel;
  @Input() requiredField: boolean;
  @Input() redStar: boolean;
  @Input() autoFocus: boolean = false;
  @Input() id: string;
  @Output() focusEvent = new EventEmitter<NgModel>();
  @Output() focusoutEvent = new EventEmitter<NgModel>();
  @ViewChild('firstName', { static: true }) firstName: NgModel;

  firstNameValidation = FIRST_NAME_VALIDATION;

  constructor(private titleCasePipe: TitleCasePipe) {
  }

  public onFocus() {
    this.focusEvent.next(this.firstName);
  }

  public onFocusOut() {
    this.focusoutEvent.next(this.firstName);
  }

  public setFirstName(value: string) {
    this.modelRef.firstName = this.titleCasePipe.transform(value);
  }

  public onPaste($event) {
    this.modelRef.firstName = this.titleCasePipe.transform($event.target.value);
  }

}
