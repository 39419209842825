import { Injectable } from '@angular/core';
import { ConfigModel } from '@app/shared/services/config/config.model';
import { API_URL } from '@app/shared/constants/settings.constant';
import {HttpClient} from "@angular/common/http";
import {tap} from "rxjs/operators";
import {AlfavoxCalendarConfigModel, AlfavoxConsultantConfigModel} from "@app/shared/services/config/video-call.model";

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private _config: ConfigModel;

  constructor(private httpClient: HttpClient) {
  }

  public getConfigInstant(): ConfigModel {
    return this._config;
  }

  get apmRumUrl(): string {
    return this._config.client.apmRumUrl
  }

  get gtmId(): string {
    return this._config.client.gtm.id;
  }

  get alfavoxVideoUrl(): string {
    return this._config.client.alfavoxVideoUrl;
  }

  get alfavoxCalendarCampaigns(): AlfavoxCalendarConfigModel[] {
    return this._config.client.alfavoxCampaignIds.calendar;
  }

  get alfavoxConsultantQueue(): AlfavoxConsultantConfigModel[] {
    return this._config.client.alfavoxCampaignIds.consultant;
  }

  async load(): Promise<ConfigModel> {
    return this.httpClient.get(`${API_URL}/config`).pipe(
      tap((response: ConfigModel) => {
        this._config = response;
      })
    ).toPromise();
  }

}
