import {Directive, HostListener} from '@angular/core';
import {GtmService} from "@app/shared/services/gtm/gtm.service";

@Directive({
    selector: '[gtm-emit-click]'
})
export class GtmClickDirective {

  constructor(private gtmService: GtmService,) {
  }

  @HostListener('click', ['$event'])
  onChange(event) {
    const id = event.target.closest('[gtm-emit-click]').getAttribute('gtm-emit-click');
    this.gtmService.emitFormFieldClick(id);
  }

}
