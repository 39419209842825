<ng-container *ngIf="currentStepNum">
  <div class="breadcrumbs-wrapper">
    <div
      *ngFor="let breadcrumb of breadcrumbs"
      [ngClass]="{
        selected: currentStepNum === breadcrumb.step,
        completed: breadcrumb.step < currentStepNum
      }"
      class="breadcrumbs-wrapper__breadcrumb-item"
    >
      <mat-icon
        *ngIf="breadcrumb.step < currentStepNum"
        svgIcon="check-circle"
      >
      </mat-icon>
      {{ breadcrumb.label }}
    </div>
  </div>
</ng-container>
