import {NavigationEnd} from '@angular/router';
import {CalculationStoreService} from '@app/calculate/calculate/calculation-store.service';
import {OfferStep, Step} from '@app/shared/models/step/step.model';
import {InputMode} from '@app/landing-page/landing-page.model';
import {StepResolver} from '@app/shared/services/url/step-resolver';
import {PersonalDataStoreModel, PersonalDataType} from '@app/shared/services/personal-data/personal-data-store.model';

export class StepOrderResolver {

  private static STEP_ORDER_MAP = new Map<string, Function>([
    ['', StepOrderResolver.landingPage],
    [Step.SHORT_PATH, StepOrderResolver.shortPath],
    [Step.VEHICLE, StepOrderResolver.vehicle],
    [Step.VEHICLE_ADDITIONAL_INFO, StepOrderResolver.vehicleAdditionalInfo],
    [Step.VEHICLE_OWNER, StepOrderResolver.vehicleOwner],
    [Step.VEHICLE_OWNER_DETAILS, StepOrderResolver.vehicleOwnerDetails],
    [Step.VEHICLE_FIRST_CO_OWNER_DETAILS, StepOrderResolver.vehicleFirstCoOwnerDetails],
    [Step.VEHICLE_SECOND_CO_OWNER_DETAILS, StepOrderResolver.vehicleSecondCoOwnerDetails],
    [Step.INSURANCE_START_DATE, StepOrderResolver.insuranceStartDate],
    [OfferStep.INSURANCE_VARIANT, StepOrderResolver.insuranceVariant],
    [OfferStep.INSURANCE_ADDITIONAL_OPTION, StepOrderResolver.insuranceAdditionalOption],
    [OfferStep.INSURANCE_TOTAL_PRICE, StepOrderResolver.insuranceTotalPrice],
    [OfferStep.VEHICLE_REGISTRATION_DATA, StepOrderResolver.vehicleRegistrationData],
    [OfferStep.VEHICLE_OWNER_ADDITIONAL_DETAILS, StepOrderResolver.vehicleOwnerAdditionalDetails],
    [OfferStep.VEHICLE_FIRST_CO_OWNER_ADDITIONAL_DETAILS, StepOrderResolver.vehicleFirstCoOwnerAdditionalDetails],
    [OfferStep.VEHICLE_SECOND_CO_OWNER_ADDITIONAL_DETAILS, StepOrderResolver.vehicleSecondCoOwnerAdditionalDetails],
    [OfferStep.CONTACT_AND_CONSENT, StepOrderResolver.contactAndConsent],
    [OfferStep.THANK_YOU, StepOrderResolver.thankYou],
    [OfferStep.THANK_YOU_FOR_PAYMENT, StepOrderResolver.thankYouForPayment]
  ]);

  public static resolveFromNavigation(navigation: NavigationEnd, calculationStoreService: CalculationStoreService): number {
    const step = StepResolver.getCurrentStepName(navigation.url);
    return StepOrderResolver.resolveFromStep(<Step>step, calculationStoreService);
  }

  public static resolveFromStep(step: Step | OfferStep, calculationStoreService: CalculationStoreService): number {
    const calcFunc = StepOrderResolver.STEP_ORDER_MAP.get(step);
    return calcFunc ? calcFunc(calculationStoreService) : undefined;
  }

  private static landingPage(calculationStoreService: CalculationStoreService): number {
    return 1;
  }

  private static shortPath(calculationStoreService: CalculationStoreService): number {
    return 2;
  }

  private static vehicle(calculationStoreService: CalculationStoreService): number {
    return 2 + StepOrderResolver.pathWeight(calculationStoreService);
  }

  private static vehicleAdditionalInfo(calculationStoreService: CalculationStoreService): number {
    return 3 + StepOrderResolver.pathWeight(calculationStoreService);
  }

  private static vehicleOwner(calculationStoreService: CalculationStoreService): number {
    return 4 + StepOrderResolver.pathWeight(calculationStoreService);
  }

  private static vehicleOwnerDetails(calculationStoreService: CalculationStoreService): number {
    const vehicleOwner = calculationStoreService.getVehicleOwner();
    if (!vehicleOwner) { return undefined; }

    return 5 +
           StepOrderResolver.pathWeight(calculationStoreService) +
           vehicleOwner.vehicleOwnerDetailsSteps?.findIndex(step => step === Step.VEHICLE_OWNER_DETAILS);
  }

  private static vehicleFirstCoOwnerDetails(calculationStoreService: CalculationStoreService): number {
    const vehicleOwner = calculationStoreService.getVehicleOwner();
    if (!vehicleOwner) { return undefined; }

    return 5 +
           StepOrderResolver.pathWeight(calculationStoreService) +
           vehicleOwner.vehicleOwnerDetailsSteps?.findIndex(step => step === Step.VEHICLE_FIRST_CO_OWNER_DETAILS);
  }

  private static vehicleSecondCoOwnerDetails(calculationStoreService: CalculationStoreService): number {
    const vehicleOwner = calculationStoreService.getVehicleOwner();
    if (!vehicleOwner) { return undefined; }

    return 5 +
           StepOrderResolver.pathWeight(calculationStoreService) +
           vehicleOwner.vehicleOwnerDetailsSteps?.findIndex(step => step === Step.VEHICLE_SECOND_CO_OWNER_DETAILS);
  }

  private static insuranceStartDate(calculationStoreService: CalculationStoreService): number {
    return 6 +
           StepOrderResolver.pathWeight(calculationStoreService) +
           StepOrderResolver.ownersCountWeight(calculationStoreService);
  }

  private static insuranceVariant(calculationStoreService: CalculationStoreService): number {
    return 7 +
           StepOrderResolver.pathWeight(calculationStoreService) +
           StepOrderResolver.ownersCountWeight(calculationStoreService);
  }

  private static insuranceAdditionalOption(calculationStoreService: CalculationStoreService): number {
    return 8 +
           StepOrderResolver.pathWeight(calculationStoreService) +
           StepOrderResolver.ownersCountWeight(calculationStoreService);
  }

  private static insuranceTotalPrice(calculationStoreService: CalculationStoreService): number {
    return 9 +
           StepOrderResolver.pathWeight(calculationStoreService) +
           StepOrderResolver.ownersCountWeight(calculationStoreService);
  }

  private static vehicleRegistrationData(calculationStoreService: CalculationStoreService): number {
    return 10 +
           StepOrderResolver.pathWeight(calculationStoreService) +
           StepOrderResolver.ownersCountWeight(calculationStoreService);
  }

  private static vehicleOwnerAdditionalDetails(calculationStoreService: CalculationStoreService): number {
    const vehicleOwner = calculationStoreService.getVehicleOwner();
    if (!vehicleOwner) { return undefined; }

    const additionalDetailsStepOrder = vehicleOwner.vehicleOwnerAdditionalDetailsSteps
      .findIndex(step => step === OfferStep.VEHICLE_OWNER_ADDITIONAL_DETAILS);
    const weight = StepOrderResolver.getVehicleOwnerAdditionalDetailsWeight(additionalDetailsStepOrder, calculationStoreService);
    return 11 + StepOrderResolver.pathWeight(calculationStoreService) +
      StepOrderResolver.ownersCountWeight(calculationStoreService) +
      additionalDetailsStepOrder +
      weight;
  }

  private static vehicleFirstCoOwnerAdditionalDetails(calculationStoreService: CalculationStoreService): number {
    const vehicleOwner = calculationStoreService.getVehicleOwner();
    if (!vehicleOwner) { return undefined; }

    const additionalDetailsStepOrder = vehicleOwner.vehicleOwnerAdditionalDetailsSteps
      .findIndex(step => step === OfferStep.VEHICLE_FIRST_CO_OWNER_ADDITIONAL_DETAILS);
    const weight = StepOrderResolver.getVehicleOwnerAdditionalDetailsWeight(additionalDetailsStepOrder, calculationStoreService);
    return 11 + StepOrderResolver.pathWeight(calculationStoreService) +
      StepOrderResolver.ownersCountWeight(calculationStoreService) +
      additionalDetailsStepOrder +
      weight;
  }

  private static vehicleSecondCoOwnerAdditionalDetails(calculationStoreService: CalculationStoreService): number {
    const vehicleOwner = calculationStoreService.getVehicleOwner();
    if (!vehicleOwner) { return undefined; }

    const additionalDetailsStepOrder = vehicleOwner.vehicleOwnerAdditionalDetailsSteps
      .findIndex(step => step === OfferStep.VEHICLE_SECOND_CO_OWNER_ADDITIONAL_DETAILS);
    const weight = StepOrderResolver.getVehicleOwnerAdditionalDetailsWeight(additionalDetailsStepOrder, calculationStoreService);
    return 11 + StepOrderResolver.pathWeight(calculationStoreService) +
      StepOrderResolver.ownersCountWeight(calculationStoreService) +
      additionalDetailsStepOrder +
      weight;
  }

  private static contactAndConsent(calculationStoreService: CalculationStoreService): number {
    return 12 + StepOrderResolver.pathWeight(calculationStoreService) +
      (StepOrderResolver.ownersCountWeight(calculationStoreService) * 2) +
      StepOrderResolver.vehicleOwnerAdditionalAddressWeight(calculationStoreService);
  }

  private static inconsistentUfg(calculationStoreService: CalculationStoreService): number {
    return 13 + StepOrderResolver.pathWeight(calculationStoreService) +
      (StepOrderResolver.ownersCountWeight(calculationStoreService) * 2) +
      StepOrderResolver.vehicleOwnerAdditionalAddressWeight(calculationStoreService);
  }

  private static thankYou(calculationStoreService: CalculationStoreService): number {
    return 13 + StepOrderResolver.pathWeight(calculationStoreService) +
      (StepOrderResolver.ownersCountWeight(calculationStoreService) * 2) +
      StepOrderResolver.vehicleOwnerAdditionalAddressWeight(calculationStoreService);
  }

  private static thankYouForPayment(calculationStoreService: CalculationStoreService): number {
    return 14 + StepOrderResolver.pathWeight(calculationStoreService) +
      (StepOrderResolver.ownersCountWeight(calculationStoreService) * 2) +
      StepOrderResolver.vehicleOwnerAdditionalAddressWeight(calculationStoreService);
  }

  private static pathWeight(calculationStoreService: CalculationStoreService): number {
    const landingPage = calculationStoreService.getLandingPage();
    if (!landingPage) { return 0; }
    return InputMode.DECLARATION === calculationStoreService.getLandingPage().inputMode ? 0 : 1;
  }

  private static ownersCountWeight(calculationStoreService: CalculationStoreService): number {
    const vehicleOwner = calculationStoreService.getVehicleOwner();
    if (!vehicleOwner) { return 0; }
    return vehicleOwner.ownersCount - 1;
  }

  private static vehicleOwnerAdditionalAddressWeight(calculationStoreService: CalculationStoreService): number {
    const vehicleOwnerDetails = calculationStoreService.getPersonalData(PersonalDataType.OWNER);
    const vehicleFirstCoOwnerDetails = calculationStoreService.getPersonalData(PersonalDataType.FIRST_CO_OWNER);
    const vehicleSecondCoOwnerDetails = calculationStoreService.getPersonalData(PersonalDataType.SECOND_CO_OWNER);
    const owner = StepOrderResolver.getVehicleOwnerAdditionalAddressStepWeight(vehicleOwnerDetails);
    const firstOwner = StepOrderResolver.getVehicleOwnerAdditionalAddressStepWeight(vehicleFirstCoOwnerDetails);
    const secondOwner = StepOrderResolver.getVehicleOwnerAdditionalAddressStepWeight(vehicleSecondCoOwnerDetails);
    return owner + firstOwner + secondOwner;
  }

  private static getVehicleOwnerAdditionalAddressStepWeight(personalData: PersonalDataStoreModel): number {
    if (personalData &&
      (personalData.isCorrespondenceAddressTheSameAsHomeAddress === false ||
        personalData.isRegisteredAddressTheSameAsHomeAddress === false)) {
      return 1;
    }

    return 0;
  }

  private static getVehicleOwnerAdditionalDetailsWeight(stepIndex: number, calculationStoreService: CalculationStoreService): number {
    let weight = 0;

    if (stepIndex === 1) {
      weight += StepOrderResolver.getVehicleOwnerAdditionalDetailsStepWeight(stepIndex - 1, calculationStoreService);
    } else if (stepIndex === 2) {
      weight += StepOrderResolver.getVehicleOwnerAdditionalDetailsStepWeight(stepIndex - 1, calculationStoreService);
      weight += StepOrderResolver.getVehicleOwnerAdditionalDetailsStepWeight(stepIndex - 2, calculationStoreService);
    }

    return weight;
  }

  private static getVehicleOwnerAdditionalDetailsStepWeight(stepIndex: number, calculationStoreService: CalculationStoreService): number {
    const vehicleOwner = calculationStoreService.getVehicleOwner();
    if (!vehicleOwner) { return undefined; }

    const step = vehicleOwner.vehicleOwnerDetailsSteps[stepIndex];
    const stepData = calculationStoreService.getPersonalDataByStep(step);
    return StepOrderResolver.getVehicleOwnerAdditionalAddressStepWeight(stepData);
  }


}
