import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {UrlParamsStoreService} from '@app/shared/interceptors/partner/url-params-store.service';

@Injectable()
export class UrlParamsInterceptor implements HttpInterceptor {

  constructor(private storeService: UrlParamsStoreService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const partnerId = this.storeService.getPartnerId();
    const sourceId = this.storeService.getSourceId();

    const params: [any, string][] = [
      [partnerId, 'partner-id'],
      [sourceId, 'source-id']
    ].filter(arr => arr[0] != undefined) as any;

    const paramsNotEmpty = params.length > 0;

    if (paramsNotEmpty) {
      const clonedRequest = this.getRequest(request, params);
      return next.handle(clonedRequest);
    }

    return next.handle(request);
  }

  private getRequest(request: HttpRequest<any>, params: [any, string][]) {
    const headers = this.getHeaders(request, params);

    return request.clone({
      headers
    });
  }

  private getHeaders(request: HttpRequest<any>, params: [any, string][]) {
    let headers = request.headers;
    params.forEach(p => {
      headers = headers.set(p[1], p[0]);
    });
    return headers;
  }
}
