import { ECommerceDataModel } from "@app/shared/services/gtm/model/ecommerce-data.model";
import { ProductModel } from "@app/shared/services/gtm/model/product.model";

export class ProductECommerceModel {
  currencyCode: string;
  impressions: ProductModel[];
  click: ECommerceDataModel;
  detail: ECommerceDataModel;
  checkout: ECommerceDataModel;
  purchase: ECommerceDataModel;
}
