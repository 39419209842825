<div provideParentForm>
  <mat-form-field hideRequiredMarker="true" appearance="fill">
    <mat-label>Numer rejestracyjny</mat-label>
    <input id="licensePlateNumber"
           name="licensePlateNumber"
           #licensePlateNumber="ngModel"
           mask="A*"
           [ngModel]="modelRef.licensePlateNumber | uppercase"
           [maxlength]="8"
           [required]="required"
           [disabled]="disabled"
           (ngModelChange)="modelRef.licensePlateNumber = $event"
           (blur)="onBlur($event)"
           (paste)="onPaste($event)"
           matInput
           licensePlateNumber>
    <mat-icon
            *ngIf="showTooltip"
            matSuffix
            matTooltip="Niektóre informacje uzupełnimy automatycznie na podstawie danych z zewnętrznych źródeł. Sprawdź ich poprawność. Jeżeli dane się nie zgadzają - popraw je."
            #tooltip="matTooltip"
            (click)="tooltip.toggle()"
            [matTooltipPosition]="'below'"
            [innerHTML]="'help'">
    </mat-icon>
    <mat-error>
      <validation-messages [for]="licensePlateNumber">
        <validation-message name="license-plate-number-length">
          Numer rejestracyjny może mieć od 4 do 8 znaków.
        </validation-message>
        <validation-message name="license-plate-number-alphanumeric">
          Numer rejestracyjny może zawierać tylko cyfry i litery.
        </validation-message>
        <validation-message name="license-plate-number-first-character">
          Numer rejestracyjny musi zaczynać się od litery.
        </validation-message>
      </validation-messages>
    </mat-error>
  </mat-form-field>
</div>
