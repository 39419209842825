<div class="form-field form-field-radio">
    <mat-label class="row d-block"><span [innerHTML]="question"></span></mat-label>
    <mat-radio-group class="row"
                     [id]="name"
                     [name]="name"
                     [(ngModel)]="modelRef[name]"
                     (change)="valueChange.emit($event)">
        <div class="col-6 p-0 pr-3">
            <mat-radio-button class="radio-button" [value]="true">Tak</mat-radio-button>
        </div>
        <div class="col-6 p-0">
            <mat-radio-button class="radio-button" [value]="false">Nie</mat-radio-button>
        </div>
    </mat-radio-group>
</div>
