<div>
  <span [innerHTML]="consent.text"></span>
  <ng-container>
    <span class="required-star" *ngIf="consent.required">*</span>
    <ng-container *ngIf="consent.additionalText">
      <span>&nbsp;</span>
      <a (click)="toggleAdditionalContent($event)">Więcej</a>
      <div class="collapsible" [@collapse]="isCollapsed" [innerHTML]="consent.additionalText"></div>
    </ng-container>
  </ng-container>
</div>
