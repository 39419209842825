import { Component } from '@angular/core';
import {MatDialog, MatDialogModule} from '@angular/material/dialog';
import { CallbackDialogComponent } from '@app/shared/components/callback/callback-dialog.component';

@Component({
  selector: 'callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss']
})
export class CallbackComponent {

  constructor(private dialog: MatDialog) {
  }

  openDialog() {
    this.dialog.open(CallbackDialogComponent, {
      disableClose: true,
      autoFocus: false,
      panelClass: 'callback-dialog',
      data: {
        closeDialogCallback: () => this.closeDialog()
      },
    });
  }

  closeDialog() {
    this.dialog.closeAll();
  }

}

