import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {OfferStep, Step} from '@app/shared/models/step/step.model';
import {CalculationStoreService} from '@app/calculate/calculate/calculation-store.service';

const nonProtectedRoutes = ['', Step.SESSION_EXPIRED, Step.SHORT_PATH, OfferStep.THANK_YOU_FOR_PAYMENT];
const policySoldSteps = [OfferStep.THANK_YOU];

@Injectable()
export class CalculateGuard  {

  constructor(private router: Router,
              private calculationStoreService: CalculationStoreService) {
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const path = childRoute.routeConfig.path;

    if (nonProtectedRoutes.includes(path)) {
      return true;
    }

    if (!this.calculationStoreService.hasCompletedFirstStep()) {
      return this.router.navigate([Step.SESSION_EXPIRED], { replaceUrl: true });
    }

    if (this.calculationStoreService.isPolicySold() && !policySoldSteps.includes(path as OfferStep)) {
      return this.router.navigate([Step.SESSION_EXPIRED], { replaceUrl: true });
    }

    return true;
  }

}
