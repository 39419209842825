<div *ngIf="showComponent || showAdditionalInfo" class="calculation-plate-container">
    <div *ngIf="!showAdditionalInfo" class="calculation-plate ie11-center">
        <div class="variant">{{ data.variant }}</div>
        <div class="price">{{ data.price | price }}</div>
    </div>
    <div *ngIf="showAdditionalInfo" class="calculation-plate additional-info">
        <div class="container">
            <div class="col-md-12">
                <div class="row justify-content-center">
                    <div class="variant">
                        CENA CAŁKOWITA
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="price">
                        {{ data.price | price }}
                    </div>
                </div>
                <div class="row">
                    <div class="horizontal-line"></div>
                </div>
                <div class="row justify-content-center">
                    <div [ngClass]="(data.additionalCovers && data.additionalCovers.length > 0) ? 'col-md-8' : 'col-md-auto'"
                         class="m-t-25 m-b-30">
                        <div class="row justify-content-center text text-medium text-bold">
                            {{ data.variant }}
                        </div>
                        <div class="row d-flex justify-content-center">
                            <div class="checked-item" *ngFor="let cover of data.covers | hideCoversInAllProcess">
                                <mat-icon svgIcon="check-circle-purple"></mat-icon>
                                {{ cover.name }}
                            </div>
                        </div>
                    </div>
                    <div *ngIf="data.additionalCovers && data.additionalCovers.length > 0"
                         class="col-md-3 m-t-25 m-b-30">
                        <div class="row justify-content-center">
                            <div class="variant">
                              Opcje dodatkowe
                            </div>
                        </div>
                        <div class="row">
                            <div class="checked-item" *ngFor="let additionalOption of data.additionalCovers | hideCoversInAllProcess">
                                <mat-icon svgIcon="check-circle-purple"></mat-icon>
                                {{ additionalOption.selectedOption.name }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="horizontal-line"></div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="row m-t-10">
                    <div class="col-md-6">
                        <div class="row m-t-10">
                            <span class="text text-small text-bold black">
                                Data początku:&nbsp;
                            </span>
                            <span class="text text-small black">
                                {{ data.startDate | dateFormatter }}
                            </span>
                        </div>
                        <div class="row m-t-10">
                            <span class="text text-small text-bold black">
                                 Data zakończenia:&nbsp;
                            </span>
                            <span class="text text-small black">
                                {{ data.endDate | dateFormatter }}
                            </span>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="row m-t-10 justify-content-md-end">
                            <span class=" text text-small text-bold black">
                                Harmonogram płatności:&nbsp;
                            </span>
                            <span class="text text-small black">
                                {{ data.installmentCount }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
