import { Component, EventEmitter, Input, Output } from "@angular/core";
import { PersonalDataStoreModel } from "@app/shared/services/personal-data/personal-data-store.model";
import {MatRadioChange} from '@angular/material/radio';

@Component({
  selector: 'app-radio-yes-no-question',
  templateUrl: './radio-yes-no-question.component.html',
  styleUrls: ['./radio-yes-no-question.component.scss']
})
export class RadioYesNoQuestionComponent {
  @Input()
  question: string = '';

  @Input()
  name: string = '';

  @Input()
  modelRef: PersonalDataStoreModel;

  @Output()
  valueChange = new EventEmitter<MatRadioChange>();
}
