export class VehicleModelVersionModel {
  constructor(vehicle: VehicleModelVersionModel) {
    this.id = vehicle.id;
    this.vehicleType = vehicle.vehicleType;
    this.fuelType = vehicle.fuelType;
    this.cc = vehicle.cc;
    this.kw = vehicle.kw;
    this.power = vehicle.power;
    this.doors = vehicle.doors;
    this.bodyType = vehicle.bodyType;
    this.description = vehicle.description;
    this.passenger = vehicle.passenger;
    this.delivery = vehicle.delivery;
  }

  id: number;
  vehicleType: number;
  fuelType: number;
  cc: number;
  kw: number;
  power: number;
  doors: number;
  bodyType: string;
  description: string;
  passenger: boolean;
  delivery: boolean;
}
