import {ActionField} from "@app/shared/services/gtm/model/action-field.model";
import {CalculationResponseModel} from "@app/calculate/calculate/models/calculation-response.model";
import {ProductTagBuilderType} from "@app/shared/services/gtm/model/product-tag-builder-type.model";
import {InsuranceType} from "@app/calculate/vehicle/vehicle.model";
import {InsuranceTotalPriceModel} from "@app/shared/models/insurance-total-price/insurance-total-price.model";
import {PaymentMethod} from "@app/shared/services/gtm/model/payment-method.model";
import {InsuranceStartDateModel} from "@app/calculate/insurance-start-date/insurance-start-date.model";
import {CalculationStoreService} from "@app/calculate/calculate/calculation-store.service";

export class ActionFieldMapper {

  private static readonly OC_OFFER_LIST = 'Lista ofert OC';
  private static readonly OC_AC_OFFER_LIST = 'Lista ofert OC AC';

  public static map(calculationStoreService: CalculationStoreService,
                    calculationResponse: CalculationResponseModel,
                    productTagBuilderType: ProductTagBuilderType,
                    insuranceTotalPrice: InsuranceTotalPriceModel,
                    insuranceStartDate: InsuranceStartDateModel): ActionField {
    if (calculationResponse && productTagBuilderType) {
      if (ProductTagBuilderType.CHECKOUT_STEP_1 === productTagBuilderType) {
        return ActionFieldMapper.buildCheckoutActionField(1, insuranceTotalPrice);
      } else if (ProductTagBuilderType.CHECKOUT_STEP_2 === productTagBuilderType) {
        return ActionFieldMapper.buildCheckoutActionField(2, insuranceTotalPrice);
      } else if (ProductTagBuilderType.CHECKOUT_STEP_3 === productTagBuilderType) {
        return ActionFieldMapper.buildCheckoutActionField(3, insuranceTotalPrice);
      } else if (ProductTagBuilderType.PURCHASE === productTagBuilderType) {
        return ActionFieldMapper.buildPurchaseActionField(calculationStoreService, calculationResponse, insuranceStartDate);
      } else {
        return ActionFieldMapper.buildInsuranceActionField(calculationResponse.insuranceType);
      }
    }

    return undefined;
  }

  private static buildInsuranceActionField(insuranceType: InsuranceType): ActionField {
    const actionField = new ActionField();
    if (InsuranceType.OC === insuranceType) actionField.list = ActionFieldMapper.OC_OFFER_LIST;
    if (InsuranceType.OC_AND_AC === insuranceType) actionField.list = ActionFieldMapper.OC_AC_OFFER_LIST;

    return actionField;
  }

  private static buildCheckoutActionField(stepNum: number,
                                          insuranceTotalPrice: InsuranceTotalPriceModel): ActionField {
    const actionField = new ActionField();
    actionField.step = stepNum;
    actionField.option = ActionFieldMapper.getPaymentType(insuranceTotalPrice);

    return actionField;
  }

  private static buildPurchaseActionField(calculationStoreService: CalculationStoreService,
                                          calculationResponse: CalculationResponseModel,
                                          insuranceStartDate: InsuranceStartDateModel): ActionField {
    const additionalInsuranceVariant = calculationStoreService.getAdditionalInsuranceVariant();
    const priceGross = additionalInsuranceVariant.installment.price * additionalInsuranceVariant.installment.count;
    const priceNet = (priceGross * 100) / 123;
    const actionField = new ActionField();

    actionField.id = calculationResponse.offerNumber;
    actionField.affiliation = '';
    actionField.revenue = priceGross.toString();
    actionField.tax = (priceGross - priceNet).toFixed(2).toString();
    actionField.shipping = '';
    actionField.coupon = insuranceStartDate.promotionalCode;

    return actionField;
  }

  private static getPaymentType(insuranceTotalPrice: InsuranceTotalPriceModel): string {
    if (insuranceTotalPrice && insuranceTotalPrice.installmentCount) {
      const paymentMethod = insuranceTotalPrice.installmentCount.translation.toLowerCase();
      if (paymentMethod.includes(PaymentMethod.BLIK)) return PaymentMethod.BLIK;
      if (paymentMethod.includes(PaymentMethod.BANK_TRANSFER)) return PaymentMethod.BANK_TRANSFER;
      return PaymentMethod.CREDIT_CARD;
    }

    return undefined;
  }

}
