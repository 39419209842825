import { Component, Input } from '@angular/core';

@Component({
  selector: 'validation-message',
  templateUrl: './validation-message.html'
})
export class ValidationMessageComponent {

  @Input() name: string;
  show: boolean = false;

  showsErrorIncludedIn(errors: string[]): boolean {
    return errors.some(error => error === this.name);
  }

}
