<div *ngIf="!successSend && !errorSend">
    <div class="text mb-1">
        <mat-icon class="video-calendar-icon" svgIcon="video-calendar"></mat-icon>
        <p class="fs-18 mb-3">
            <strong>Uzupełnij krótki formularz</strong>, aby<br /> umówić wideo rozmowę
        </p>
    </div>
    <form id="videoFormCalendar"
          name="videoFormCalendar"
          class="video-calendar-container"
          (submit)="send(videoFormCalendar)"
          #videoFormCalendar="ngForm"
          [ngClass]="videoFormCalendar.submitted ? 'submitted' : ''">
        <div class="col-md-12">
            <div>
                <div class="justify-content-center">
                    <div class="col-md-12">
                        <div class="form-field">
                            <app-first-name id="video-calendar-first-name"
                                            [modelRef]="videoCalendarForm"
                                            [requiredField]="true"
                                            [redStar]="true"
                                            [autoFocus]="false">
                            </app-first-name>
                        </div>
                        <div class="form-field">
                            <app-last-name id="callback-form-last-name"
                                           [modelRef]="videoCalendarForm"
                                           [requiredField]="true"
                                           [redStar]="true">
                            </app-last-name>
                        </div>
                        <div class="form-field">
                            <app-email [modelRef]="videoCalendarForm"
                                       [requiredField]="true"
                                       [redStar]="true">
                            </app-email>
                        </div>
                        <div class="form-field">
                            <mat-form-field hideRequiredMarker="true"
                                            appearance="fill">
                                <mat-label>Temat rozmowy: <span class="required-star">*</span></mat-label>
                                <mat-select id="subject-question-calendar"
                                            name="subject-question-calendar"
                                            [(ngModel)]="videoCalendarForm.campaignId"
                                            [required]="true"
                                            disableOptionCentering>
                                    <mat-option *ngFor="let campaign of availableCampaigns" [value]="campaign.id">
                                        {{ campaign.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="form-field">
                            <app-date-time-picker-materials #dateTimePickerMaterials
                                                            [startAt]="startAtDate"
                                                            [minDate]="minDate"
                                                            [maxDate]="maxDate"
                                                            [disabled]="!videoCalendarForm.campaignId"
                                                            [modelRef]="videoCalendarForm"
                                                            [modelRefField]="'date'"
                                                            [matLabelValue]="'Data i godzina'"
                                                            [customClass]="'mat-form-field mat-primary mat-form-field-type-mat-input'"
                                                            [required]="true"
                                                            [redStar]="true">
                            </app-date-time-picker-materials>
                        </div>
                        <mat-checkbox id="video-calendar-agreement"
                                      name="video-calendar-agreement"
                                      class="m-b-15"
                                      #agreement="ngModel"
                                      [(ngModel)]="videoCalendarForm.agreement"
                                      [required]="true">
                            <span *ngIf="consent" [innerHTML]="consent.text"></span>
                        </mat-checkbox>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-12">
            <div class="justify-content-center d-flex col-md-12">
                <button #submitButton class="next-button w-100 p-0" mat-button>
                    Zamów wideo rozmowę
                </button>
            </div>
            <div class="justify-content-center d-flex col-md-12">
                <a class="video-back-button mt-4" (click)="onBack()">
                    Wróć
                </a>
            </div>
        </div>
    </form>
</div>
<div *ngIf="successSend" class="text w-100 mb-4">
    <mat-icon class="video-calendar-icon" svgIcon="video-calendar"></mat-icon>
    <p class="fs-18">
        <strong>Twoje zgłoszenie</strong> zostało <strong>przyjęte</strong>.
    </p>
    <p>
        Wysłaliśmy do Ciebie e-mail z potwierdzeniem oraz linkiem do rozmowy z Doradcą.<br /><br />
        Do zobaczenia!
    </p>
</div>
<div *ngIf="errorSend" class="text w-100 mb-4">
    <mat-icon class="video-calendar-icon" svgIcon="widget-video-error"></mat-icon>
    <p class="fs-18">
        <strong>Nie udało się</strong> umówić spotkania.<br />
        <a class="video-back-button" (click)="errorSend = false"><strong>Spróbuj ponownie.</strong></a>
    </p>
    <p class="fs-18">
        <span class="d-block mb-4 mt-4">Możesz też:</span>
        <span class="d-block mb-2"><strong>Zadzwonić</strong> pod numer</span>
        <strong class="fs-24">22 444 67 68</strong>
    </p>
</div>
