import { TagModel } from "@app/shared/services/gtm/model/tag.model";
import { CallbackCategory } from "@app/shared/services/gtm/model/callback-category.model";
import { CallbackAction } from "@app/shared/services/gtm/model/callback-action.model";

export class CallbackTagModel extends TagModel {
  category: CallbackCategory;
  action: CallbackAction;
  label: string;
  value: number;
  nointeract: number;
}
